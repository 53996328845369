import { PA_BASE_API } from 'src/services';

export const DATA_ROOM_STORE_API_KEY = 'dataRoomApi';
export const DATA_ROOM_GET_LIST = '/:group/:id/dataRoom';
export const DATA_ROOM_FOLDERS = '/:group/:loanApplicationId/dataRoom/folders';
export const DATA_ROOM_UPLOAD_FILES = '/:group/:loanApplicationId/documents';
export const DATA_ROOM_DELETE_FILE =
  '/:group/:loanApplicationId/documents/:documentId';

export const DATA_ROOM_ARCH_INIT = '/:group/:id/dataRoom/archive';
export const DATA_ROOM_ARCH_STATUS = '/:group/:id/dataRoom/archive/:archiveId';
export const DATA_ROOM_ARCH_CONTENT = `${
  import.meta.env.REACT_APP_API_PATH
}${PA_BASE_API}/:group/:id/dataRoom/archive/:archiveId/content`;

export const DATA_ROOM_TAG = 'dataRoom';
