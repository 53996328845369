import { createSlice, isAnyOf } from '@reduxjs/toolkit';
import { createEntityStateReducers } from 'src/helpers';
import { useObjSelector } from 'src/store/hooks';
import { loadDataFromStorage } from 'src/services';
import { PartyType } from '../customer';
import { resetLoanAppAction, saveLoanAppAction } from './actions';
import { loanAppsApi } from './api';
import { LoanAppState } from './types';
import { LOAN_APP_STORE_KEY } from './constants';

const defaultState: LoanAppState = { partyType: PartyType.LegalEntity };
const initialState: LoanAppState =
  loadDataFromStorage(LOAN_APP_STORE_KEY) || defaultState;
const reducers = createEntityStateReducers<LoanAppState>(defaultState);

export const loanAppSlice = createSlice({
  name: LOAN_APP_STORE_KEY,
  reducers: {},
  initialState,
  extraReducers: (builder) => {
    builder.addCase(resetLoanAppAction, reducers.reset);
    builder.addMatcher(
      loanAppsApi.endpoints.getLoanAppDraft.matchFulfilled,
      reducers.get,
    );
    builder.addMatcher(
      isAnyOf(
        loanAppsApi.endpoints.saveLoanAppDraft.matchFulfilled,
        saveLoanAppAction,
      ),
      reducers.set,
    );
  },
});

export const useLoanAppState = (watchKeys: string[] | null = []) =>
  useObjSelector(({ loanApp }) => loanApp, watchKeys);
