import React from 'react';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import FieldsSet from 'src/components/FieldsSet';
import {
  FundingOppBorrower,
  FundingOppBorrowerInfoField,
} from 'src/entities/fundingOpps';

const businessInfoFields = [
  {
    id: FundingOppBorrowerInfoField.FullCompanyName,
    label: 'Full company name',
  },
  {
    id: FundingOppBorrowerInfoField.RegistrationNumber,
    label: 'Registration Number',
  },
  {
    id: FundingOppBorrowerInfoField.Website,
    label: 'Website',
  },
  {
    id: FundingOppBorrowerInfoField.Description,
    label: 'Short description of the business',
  },
];

const addressFields = [
  {
    id: FundingOppBorrowerInfoField.AddressLine1,
    label: 'Address line 1',
  },
  {
    id: FundingOppBorrowerInfoField.AddressLine2,
    label: 'Address line 2',
  },
  {
    id: FundingOppBorrowerInfoField.City,
    label: 'City',
  },
  {
    id: FundingOppBorrowerInfoField.StateProvince,
    label: 'State/Province',
  },
  {
    id: FundingOppBorrowerInfoField.Country,
    label: 'Country',
  },
  {
    id: FundingOppBorrowerInfoField.PostalCode,
    label: 'Zip/Postal Code',
  },
];

function FundingOppDetailsLoanApp({
  primaryBusinessAddress,
  registeredOfficeAddress,
  ...rest
}: FundingOppBorrower) {
  return (
    <Stack gap={2}>
      <Divider>General Business Information</Divider>
      <FieldsSet fields={businessInfoFields} data={rest} />
      <Typography variant="overline">Registered Office Address</Typography>
      <FieldsSet fields={addressFields} data={registeredOfficeAddress} />
      {primaryBusinessAddress && (
        <>
          <Typography variant="overline">Primary Business Address</Typography>
          <FieldsSet fields={addressFields} data={primaryBusinessAddress} />
        </>
      )}
    </Stack>
  );
}

export default FundingOppDetailsLoanApp;
