import baseTheme from '../baseTheme';

const { typography } = baseTheme;

export default {
  defaultProps: {
    textAlign: 'left',
  },
  styleOverrides: {
    textAlignLeft: {
      '&:after': {
        top: 0,
      },
      '&:before': {
        width: 0,
      },
      '.MuiDivider-wrapper': {
        paddingLeft: 0,
      },
    },
    wrapper: {
      ...typography.overline,
    },
  },
};
