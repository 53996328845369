import baseTheme from '../baseTheme';

const { breakpoints, spacing } = baseTheme;

export default {
  MuiDrawer: {
    styleOverrides: {
      root: {
        maxHeight: '100vh',
        maxWidth: '22%',
        [breakpoints.down('sm')]: {
          width: '100%',
        },
      },
      paper: {
        border: 'none',
        borderRadius: 0,
        overflowX: 'hidden',
        padding: spacing(2.5, 2),
      },
    },
  },
};
