import { formatDate, formatDateTime, joinValues } from 'src/utils';
import { formatCurrValue } from 'src/entities/currency';

export const createCurrFormatter =
  <C extends string, V>(currency?: C) =>
  (value: V) =>
    formatCurrValue(Number(value), currency);

export const createLabelFormatter =
  <T extends object, V>(labels?: T) =>
  (value: V) => {
    const values = Array.isArray(value) ? value : [value];
    return joinValues(
      values.map((item) => labels && labels[item as keyof T]),
      ', ',
    );
  };

export const dateFormatter = <V>(value: V) => formatDate(value as string);

export const dateTimeFormatter = <V>(value: V) =>
  formatDateTime(value as string);

export const percentFormatter = <V>(value: V) => `${value}%`;
