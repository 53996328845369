import React from 'react';
import Chip from '@mui/material/Chip';
import { DataListCell } from 'src/components/DataList';
import { LoanAppStatus } from 'src/entities/loanApps';
import { statusMap } from './data';

type Props = {
  status: LoanAppStatus;
};

function LoanAppsListItemStatus({ status }: Props) {
  const { color, label, description } = statusMap[status] || {};

  return (
    <DataListCell
      primary={
        <Chip
          sx={{ minWidth: { xs: '100%', sm: '10rem' } }}
          size="xs"
          color={color}
          label={label}
        />
      }
      secondary={description}
    />
  );
}

export default LoanAppsListItemStatus;
