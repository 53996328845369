import React, { ReactNode } from 'react';
import { ModalCloseHandler } from '../ModalDialog/types';
import ModalDialog from '../ModalDialog';
import {
  DataRoomGroup,
  useDeleteDataRoomDocMutation,
} from '../../entities/dataRoom';
import { AlertType, useAlert } from '../AlertsProvider';

type Props = {
  group: DataRoomGroup;
  loanApplicationId: number;
  documentId: string;
  documentName: ReactNode | undefined;
  open: boolean;
  onClose: ModalCloseHandler;
};

function DeleteFileModal({
  group,
  loanApplicationId,
  documentId,
  documentName,
  open,
  onClose,
}: Props) {
  const [deleteFileRequest, { isLoading: isSaving }] =
    useDeleteDataRoomDocMutation();
  const { showAlert } = useAlert();
  const onConfirm = () => {
    deleteFileRequest({
      group,
      loanApplicationId,
      documentId,
    })
      .unwrap()
      .then(() => {
        showAlert({
          type: AlertType.Success,
          text: 'File was deleted',
        });
        onClose();
      });
  };
  return (
    <ModalDialog
      open={open}
      onClose={onClose}
      title="Delete Document"
      cancelBtnText="Cancel"
      confirmBtnText="Confirm"
      showConfirmBtn
      onConfirm={onConfirm}
      confirmBtnLoading={isSaving}
    >
      Are you sure you want to delete the file &quot;{documentName}&quot;?
    </ModalDialog>
  );
}

export default DeleteFileModal;
