import React, { ForwardedRef, forwardRef, ReactNode, useEffect } from 'react';
import { useAuthState } from 'src/entities/auth';
import {
  DocFormat,
  DocTypeCode,
  useDeleteDocMutation,
  useGetDocMetaQuery,
  useUploadDocsMutation,
} from 'src/entities/documents';
import DocUploadInputListItem from './DocUploadInputListItem';
import { DEFAULT_DOC_ITEMS_LIMIT, DEFAULT_DOC_SIZE } from './constants';
import { getChangeHandlerValue, getDeleteHandlerValue } from './utils';
import { useCurrentFiles, useInitialDocsIds } from './hooks';
import { DocInputValue } from './types';
import FileInput from '../FileInput';

type Props<TMultiple extends boolean> = {
  name?: string;
  value?: DocInputValue<TMultiple>;
  label?: ReactNode;
  description?: string;
  docType: DocTypeCode;
  error?: boolean;
  helperText?: ReactNode;
  multiple?: TMultiple;
  required?: boolean;
  onChange?: (value: DocInputValue<TMultiple> | null) => void;
  allowedFormats?: DocFormat[];
  maxItems?: number;
  maxSize?: number;
};

function DocUploadInput<TMultiple extends boolean>(
  {
    value,
    docType,
    multiple,
    onChange,
    allowedFormats = Object.values(DocFormat),
    maxItems = DEFAULT_DOC_ITEMS_LIMIT,
    maxSize = DEFAULT_DOC_SIZE,
    ...rest
  }: Props<TMultiple>,
  ref: ForwardedRef<HTMLInputElement>,
) {
  const { user } = useAuthState();
  const { id: userId } = user || {};
  const documentIds = useInitialDocsIds(value);
  const [remove, { isLoading: isDeleting }] = useDeleteDocMutation();
  const [upload, { isLoading: isUploading }] = useUploadDocsMutation();
  const { data, isFetching, isError } = useGetDocMetaQuery(
    { userId, documentIds },
    { skip: !userId || !documentIds || !documentIds?.length },
  );
  const currentItems = useCurrentFiles(documentIds, data);
  const itemsLimit = multiple ? maxItems : 1;
  const canUpload = currentItems.length < itemsLimit;
  const loading = isFetching || isDeleting || isUploading;

  const handleChange = async ({
    target,
  }: React.ChangeEvent<HTMLInputElement>) => {
    const { files } = target;
    upload({ userId, docType, files })
      .unwrap()
      .then(
        (res) =>
          onChange &&
          onChange(getChangeHandlerValue(currentItems, res, multiple)),
      );
  };

  const handleDelete = (documentId?: number) =>
    remove({ userId, documentId })
      .unwrap()
      .then(
        () =>
          onChange &&
          onChange(getDeleteHandlerValue(currentItems, documentId, multiple)),
      );

  useEffect(() => {
    if (isError && onChange) {
      onChange(null);
    }
  }, [isError, onChange]);

  return (
    <FileInput
      ref={ref}
      value={currentItems}
      canUpload={canUpload}
      loading={loading}
      allowedFormats={allowedFormats}
      maxSize={maxSize}
      maxItems={itemsLimit}
      multiple={multiple}
      onChange={handleChange}
      onDelete={handleDelete}
      listItemComponent={DocUploadInputListItem}
      listItemProps={{ userId }}
      {...rest}
    />
  );
}

export default forwardRef(DocUploadInput);
