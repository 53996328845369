import { useMemo } from 'react';
import { arrayFromNonEmpty } from 'src/utils';
import { DocFileMeta } from 'src/entities/documents';

export const useCurrentFiles = (ids?: number[], items?: DocFileMeta[]) =>
  useMemo(
    () => items?.filter(({ id }) => ids?.includes(id)) || [],
    [items, ids],
  );

export const useInitialDocsIds = (value?: number | number[]) =>
  useMemo(() => arrayFromNonEmpty(value), [value]);
