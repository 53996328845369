export const LOAN_APP_STORE_KEY = 'loanApp';
export const LOAN_APP_STORE_API_KEY = 'loanAppApi';
export const LOAN_APP_DRAFT_TAG = 'draft';
export const LOAN_APP_LIST_TAG = 'list';
export const LOAN_APP_CREDITORS_TAG = 'creditors';
export const LOAN_APP_SAVE = 'loanAppSave';
export const LOAN_APP_RESET = 'loanAppReset';
export const LOAN_APP_ROOT_API = '/loanApplications';
export const LOAN_APP_DRAFT_API = '/draft';
export const LOAN_APP_SEND_API = '/draft/finalize';
export const LOAN_APP_DETAILS_API = '/:id';
export const LOAN_APP_CREDITORS_LIST_API = '/:id/creditors';
export const LOAN_APP_CONFIRM_CREDITORS_API = '/:id/creditors/confirm';
