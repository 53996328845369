import { useMemo } from 'react';
import { useRoutes } from 'react-router-dom';
import { routes } from './routes';
import { mapRouteElement } from './utils';

function AppRoutes() {
  const appRoutes = useMemo(() => routes.map(mapRouteElement), []);

  return useRoutes(appRoutes);
}

export default AppRoutes;
