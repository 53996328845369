import { PA_BASE_API } from 'src/services';

export const FUNDING_OPPS_STORE_API_KEY = 'fundingOppsApi';
export const FUNDING_OPPS_TAG = 'fundingOpps';
export const FUNDING_OPPS_ROOT_API = '/fundingOpportunities';
export const FUNDING_OPPS_DETAILS_API = '/:id';
export const FUNDING_OPPS_REQUEST_CALL = '/:id/requestCall';
export const FUNDING_OPPS_UPLOAD_NDA = ':id/actualNDA';
export const FUNDING_OPPS_DOWNLOAD_NDA = `${
  import.meta.env.REACT_APP_API_PATH
}${PA_BASE_API}${FUNDING_OPPS_ROOT_API}/:id/actualNDA`;
