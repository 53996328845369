import React from 'react';
import Skeleton from '@mui/material/Skeleton';
import Stack, { StackProps } from '@mui/material/Stack';
import { arrayFromNumber, elementsArrFromNumber } from 'src/utils';

type Props = {
  rows: number;
} & StackProps;

function FieldsSetSkeleton({ rows, ...rest }: Props) {
  return (
    <Stack mt={0.5} gap={1.1} {...rest}>
      {arrayFromNumber(rows).map((row) => (
        <Stack
          data-testid="fields-set-skeleton-row"
          key={row}
          direction="row"
          justifyContent="space-between"
        >
          {elementsArrFromNumber(<Skeleton variant="text" width="40%" />, 2)}
        </Stack>
      ))}
    </Stack>
  );
}

export default FieldsSetSkeleton;
