import React, {
  AriaAttributes,
  ChangeEvent,
  ComponentType,
  DOMAttributes,
  ForwardedRef,
  forwardRef,
  ReactNode,
} from 'react';
import MuiFormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormHelperText from '@mui/material/FormHelperText';
import LinearProgress from '@mui/material/LinearProgress';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import SvgIcon from '@mui/material/SvgIcon';
import Typography from '@mui/material/Typography';
import CloudUploadIcon from 'src/assets/icons/cloud-upload-icon.svg?react';
import { arrayFromNonEmpty } from 'src/utils';
import { useLayoutElementProps } from 'src/hooks';
import { AlertType, useAlert } from 'src/components/AlertsProvider';
import { ListItemProps } from './types';
import { getListItemKey, getValidationMessage, validateFiles } from './utils';

declare module 'react' {
  interface HTMLAttributes<T> extends AriaAttributes, DOMAttributes<T> {
    // extends React's HTMLAttributes
    directory?: string;
    webkitdirectory?: string;
  }
}

type Props<TFile, TId, TItemProps> = {
  // TODO: fix ref for generics
  ref?: ForwardedRef<HTMLInputElement>;
  name?: string;
  value?: TFile[] | TFile;
  label?: ReactNode;
  description?: string;
  error?: boolean;
  helperText?: ReactNode;
  multiple?: boolean;
  loading?: boolean;
  required?: boolean;
  canUpload?: boolean;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  onDelete: (id?: TId) => void;
  allowedFormats?: string[];
  maxItems?: number;
  maxSize?: number;
  listItemComponent?: ComponentType<ListItemProps<TFile, TId, TItemProps>>;
  listItemProps?: TItemProps;
  webkitdirectory?: string;
};

function FileInput<
  TFile extends File | Record<string, unknown>,
  TId,
  TItemProps,
>(
  {
    value,
    label,
    description,
    error,
    helperText,
    loading,
    multiple,
    required,
    canUpload,
    onChange,
    onDelete,
    allowedFormats = [],
    maxItems = 1,
    maxSize = 0,
    listItemComponent: ListItem,
    listItemProps,
    webkitdirectory,
    ...props
  }: Props<TFile, TId, TItemProps>,
  ref: ForwardedRef<HTMLInputElement>,
) {
  const [elementProps, rest] = useLayoutElementProps(props);
  const { showAlert } = useAlert();
  const items = arrayFromNonEmpty(value);
  const itemsLength = items?.length ?? 0;
  const itemsLimit = multiple ? maxItems : 1;
  const handleChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const { files } = event.target;
    const itemsLeft = maxItems - itemsLength;
    const error = await validateFiles({
      files,
      itemsLeft,
      itemsLimit,
      allowedFormats,
      maxSize,
    });
    if (error) {
      showAlert({ type: AlertType.Error, text: error });
    } else {
      onChange(event);
    }
    event.target.value = '';
  };

  return (
    <MuiFormControl sx={{ pl: 1.5 }}>
      <SvgIcon
        component={CloudUploadIcon}
        sx={{ position: 'absolute', left: 0 }}
      />
      <FormControlLabel
        sx={{ alignItems: 'flex-start' }}
        labelPlacement="top"
        control={
          <>
            <input
              ref={ref}
              type="file"
              onChange={handleChange}
              style={{ height: 0, visibility: 'hidden' }}
              webkitdirectory={webkitdirectory}
              // directory
              disabled={!canUpload}
              multiple={multiple}
              {...rest}
            />
            {loading && <LinearProgress sx={{ mt: 1, width: '60%' }} />}
            {!loading && canUpload && (
              <Typography>
                <Link mr={1}>Choose File</Link>
                {getValidationMessage(allowedFormats, maxSize, itemsLimit)}
              </Typography>
            )}
            <Stack
              direction="row"
              alignItems="center"
              flexWrap="wrap"
              gap={0.5}
            >
              {ListItem &&
                items?.map((item) => (
                  <ListItem
                    key={getListItemKey(item)}
                    onDelete={onDelete}
                    item={item}
                    {...(listItemProps as TItemProps)}
                  />
                ))}
            </Stack>
          </>
        }
        label={
          <Stack>
            {label && <Typography variant="subtitle1">{label}</Typography>}
            {description}
          </Stack>
        }
        {...elementProps}
      />
      <FormHelperText error={Boolean(error)} required={required}>
        {helperText}
      </FormHelperText>
    </MuiFormControl>
  );
}

export default forwardRef(FileInput) as typeof FileInput;
