import React, { ChangeEvent, ForwardedRef, forwardRef, ReactNode } from 'react';
import FormControlLabel from '@mui/material/FormControlLabel';
import MuiCheckbox from '@mui/material/Checkbox';
import FormControl, { FormControlProps } from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';

type Props = Omit<FormControlProps, 'onChange'> & {
  label?: ReactNode;
  type?: string;
  value?: boolean;
  onChange?: (value: boolean) => void;
  helperText?: ReactNode;
};

function Checkbox(
  { value, label, onChange, helperText, ...rest }: Props,
  ref: ForwardedRef<HTMLButtonElement>,
) {
  const handleChange = ({ target }: ChangeEvent<HTMLInputElement>) =>
    onChange && onChange(target.checked);

  return (
    <FormControl {...rest}>
      <FormControlLabel
        control={
          <MuiCheckbox
            data-testid="checkbox"
            checked={value}
            onChange={handleChange}
            ref={ref}
          />
        }
        label={label}
      />
      <FormHelperText>{helperText}</FormHelperText>
    </FormControl>
  );
}

export default forwardRef(Checkbox);
