import React from 'react';
import {
  FundingOppStatus,
  useGetFundingOppsQuery,
} from 'src/entities/fundingOpps';
import {
  DataListNoContent,
  DataListSkeleton,
  DataListWrapper,
} from 'src/components/DataList';
import { PagingParams, usePagingParams } from 'src/components/Paging';
import FundingOppsListItem from './FundingOppsListItem';

type Props = PagingParams & {
  status?: FundingOppStatus[];
};

function FundingOppsList({ status, pageSize: initialPageSize }: Props) {
  const pagingParams = usePagingParams(initialPageSize);
  const { page, pageSize } = pagingParams;
  const { data, isLoading } = useGetFundingOppsQuery({
    status,
    page,
    pageSize,
  });
  const { data: items, total } = data || {};
  const noData = !isLoading && !items?.length;

  if (isLoading || noData) {
    return (
      <DataListSkeleton loading={isLoading}>
        <DataListNoContent
          title="You don’t have funding opportunities yet"
          description="Please try to check it later"
        />
      </DataListSkeleton>
    );
  }
  return (
    <DataListWrapper pagingProps={{ total, ...pagingParams }}>
      {items?.map((item) => <FundingOppsListItem key={item.id} {...item} />)}
    </DataListWrapper>
  );
}

export default FundingOppsList;
