import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import MonivoltLogo from 'src/assets/images/monivolt-logo.svg?react';
import MonivoltIconLogo from 'src/assets/images/monivolt-icon-logo.svg?react';
import { ROOT as ROUTE_ROOT } from 'src/router';
import { useSidebarContext } from './SidebarContext';

function SidebarLogo() {
  const { fullWidth } = useSidebarContext();

  return (
    <Link
      component={RouterLink}
      to={ROUTE_ROOT}
      marginBottom="3rem"
      marginX="auto"
    >
      <Box
        component={fullWidth ? MonivoltLogo : MonivoltIconLogo}
        color="text.primary"
      />
    </Link>
  );
}

export default SidebarLogo;
