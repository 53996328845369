export const AUTH_STORE_API_KEY = 'authApi';
export const AUTH_STORE_KEY = 'auth';
export const AUTH_STORE_LOGOUT = 'authLogout';
export const AUTH_STORE_SET_REDIRECT = 'authSetRedirectPath';
export const AUTH_ROOT_API = '/auth';
export const AUTH_CHECK_API = '/check';
export const AUTH_LOGIN_API = '/login';
export const AUTH_LOGOUT_API = '/logout';
export const AUTH_CHANGE_PASS_API = '/changePassword';
export const AUTH_FORGOT_PASS_API = '/forgotPassword';
export const AUTH_SET_NEW_PASS_API = '/setNewPassword';
export const AUTH_REGISTER_API = '/register';
export const AUTH_RESEND_CONF_CODE_API = '/resendConfirmationCode';
export const AUTH_CONFIRM_ACCOUNT_API = '/confirmUserAccount';
