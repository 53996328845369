import { createSlice, isAnyOf } from '@reduxjs/toolkit';
import { createEntityStateReducers } from 'src/helpers';
import { useObjSelector } from 'src/store/hooks';
import { loadDataFromStorage } from 'src/services';
import { resetCreditorAppAction, saveCreditorAppAction } from './actions';
import { creditorAppApi } from './api';
import { CreditorAppState } from './types';
import { CREDITOR_APP_STORE_KEY } from './constants';

const initialState: CreditorAppState =
  loadDataFromStorage(CREDITOR_APP_STORE_KEY) || {};
const reducers = createEntityStateReducers<CreditorAppState>({});

export const creditorAppSlice = createSlice({
  name: CREDITOR_APP_STORE_KEY,
  reducers: {},
  initialState,
  extraReducers: (builder) => {
    builder.addCase(resetCreditorAppAction, reducers.reset);
    builder.addMatcher(
      creditorAppApi.endpoints.getCreditorAppDraft.matchFulfilled,
      reducers.get,
    );
    builder.addMatcher(
      isAnyOf(
        creditorAppApi.endpoints.saveCreditorAppDraft.matchFulfilled,
        saveCreditorAppAction,
      ),
      reducers.set,
    );
  },
});

export const useCreditorAppState = (watchKeys: string[] | null = []) =>
  useObjSelector(({ creditorApp }) => creditorApp, watchKeys);
