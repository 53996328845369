import React from 'react';
import FundingOppsList from './FundingOppsList';
import FundingOppDetailsContextProvider from './FundingOppDetailsContext';

function FundingOpps() {
  return (
    <FundingOppDetailsContextProvider>
      <FundingOppsList />
    </FundingOppDetailsContextProvider>
  );
}

export default FundingOpps;
