import React, { ForwardedRef, forwardRef } from 'react';
import Tabs, { TabsProps } from '@mui/material/Tabs';
import { useTabContext } from './TabContext';

function TabList(
  { children, ...rest }: TabsProps,
  ref: ForwardedRef<HTMLDivElement>,
) {
  const { value } = useTabContext();

  return (
    <Tabs ref={ref} value={value} {...rest}>
      {children}
    </Tabs>
  );
}

export default forwardRef(TabList);
