import React, { ReactNode, useContext, useMemo } from 'react';

type TabContextProps = {
  value: number;
};

type Props = TabContextProps & {
  children?: ReactNode;
};

const TabContext = React.createContext<TabContextProps>({
  value: 0,
});

export const useTabContext = () => useContext(TabContext);

function TabContextProvider({ value, children }: Props) {
  const contextValue = useMemo(() => ({ value }), [value]);

  return (
    <TabContext.Provider value={contextValue}>{children}</TabContext.Provider>
  );
}

export default TabContextProvider;
