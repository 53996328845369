import baseTheme from '../baseTheme';

const { spacing } = baseTheme;

export default {
  MuiPagination: {
    defaultProps: {
      size: 'large',
    },
    styleOverrides: {
      root: {
        margin: spacing(1),
      },
      ul: {
        justifyContent: 'center',
      },
    },
  },
};
