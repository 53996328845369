import React from 'react';
import { useForm } from 'src/helpers';
import { DocTypeCode } from 'src/entities/documents';
import {
  LoanAppFormField,
  LoanAppFormSupportingDocs,
  useLoanAppState,
} from 'src/entities/loanApps';
import DocUploadInput from 'src/components/DocUploadInput';
import FormControl from 'src/components/FormControl';
import { StepComponentProps } from 'src/components/Stepper';
import LoanAppFormWrapper from './LoanAppFormWrapper';
import { supportingDocsValidation } from './formData';

function LoanAppSupportingDocs(props: StepComponentProps) {
  const defaultValues = useLoanAppState();
  const formProps = useForm<LoanAppFormSupportingDocs>({
    validation: supportingDocsValidation,
    defaultValues,
  });

  return (
    <LoanAppFormWrapper stepId="supporting-docs" {...props} {...formProps}>
      <FormControl
        data-testid="supporting-docs-input"
        docType={DocTypeCode.Uncategorized}
        name={LoanAppFormField.Docs}
        label="Supporting documents"
        description="Documents to support your loan application."
        component={DocUploadInput}
        multiple
      />
    </LoanAppFormWrapper>
  );
}

export default LoanAppSupportingDocs;
