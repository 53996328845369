import React, { createContext, ReactNode, useMemo } from 'react';
import { useSingleQueryStateValue } from 'src/hooks';
import SideModal from 'src/components/SideModal';
import FundingOppDetails from './FundingOppDetails';

const DEFAULT_QUERY_PARAM_KEY = 'id';

type Props = {
  children: ReactNode;
  queryParamKey?: string;
};

type ContextProps = {
  showFundingOppDetails: (id: number) => void;
};

export const FundingOppDetailsContext = createContext<ContextProps>({
  showFundingOppDetails: () => null,
});

function FundingOppDetailsContextProvider({
  children,
  queryParamKey = DEFAULT_QUERY_PARAM_KEY,
}: Props) {
  const [selectedId, setSelectedId] = useSingleQueryStateValue<number | null>(
    queryParamKey,
  );
  const handleClose = () => setSelectedId(null);
  const value = useMemo(
    () => ({ showFundingOppDetails: setSelectedId }),
    [setSelectedId],
  );

  return (
    <FundingOppDetailsContext.Provider value={value}>
      {children}
      <SideModal open={!!selectedId} onClose={handleClose}>
        {selectedId && <FundingOppDetails id={selectedId} />}
      </SideModal>
    </FundingOppDetailsContext.Provider>
  );
}

export default FundingOppDetailsContextProvider;
