import { matchPath, useLocation } from 'react-router-dom';
import { useAuthState } from 'src/entities/auth';
import {
  getRoute,
  getRouteMeta,
  getRoutes,
  isRouteAllowedForUserRoles,
  mapAccessFlagToBool,
} from './utils';
import { RouteProps } from './types';
import { ROOT } from './constants';

export const useMatchRoute = () => {
  const { pathname } = useLocation();
  return getRoute(pathname);
};

export const useMatchRouteMeta = () => {
  const { pathname } = useLocation();
  return getRouteMeta(pathname);
};

export const useNavRoutes = (routes?: RouteProps[]) => {
  const { pathname } = useLocation();
  const { isAuthenticated, user } = useAuthState();

  return getRoutes(routes)
    .filter(({ allowedForStatus, allowedForRoles, meta }) => {
      const { allowedForAuthorized, allowedForUnauthorized, allowedForAll } =
        mapAccessFlagToBool(allowedForStatus);
      return (
        meta?.navLink &&
        isRouteAllowedForUserRoles(allowedForRoles, user?.roles) &&
        ((isAuthenticated && allowedForAuthorized) ||
          (!isAuthenticated && allowedForUnauthorized) ||
          allowedForAll)
      );
    })
    .map(({ path = ROOT, meta }) => {
      const { title, icon, activeIcon } = meta || {};
      const selected = !!matchPath({ path, end: false }, pathname);
      return {
        icon: selected && activeIcon ? activeIcon : icon,
        path,
        title,
        selected,
      };
    });
};
