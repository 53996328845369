import React, { ReactNode } from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { useLayoutBreakpoints } from 'src/hooks';
import { SxProps } from 'src/theme/types';
import { InfoCardColor } from './types';
import InfoCardSkeleton from './InfoCardSkeleton';

type Props = {
  children: ReactNode;
  color: InfoCardColor;
  image: string;
  loading?: boolean;
};

const getStyle = (color: InfoCardColor, image: string | boolean): SxProps => ({
  color:
    color === InfoCardColor.Primary ? 'primary.contrastText' : 'text.primary',
  background: ({ palette }) =>
    `url(${image}) right center no-repeat, ${
      color === InfoCardColor.Primary
        ? palette.gradient.primary
        : palette.gradient.secondary
    }`,
  bgcolor: color,
  minWidth: '30rem',
  maxWidth: '50rem',
  width: '100%',
  typography: 'h4',
  '.MuiButton-root': {
    alignSelf: 'flex-start',
  },
  '.MuiListItem-root': {
    pl: 0,
  },
  '.MuiListItemText-secondary': {
    color: 'inherit',
    opacity: 0.6,
  },
  '.MuiListItemIcon-root': {
    alignSelf: 'flex-start',
    pt: 0.5,
    minWidth: '4rem',
  },
});

const contentStyle = {
  maxWidth: { sm: '75%' },
  gap: 1,
  px: 4,
};

function InfoCard({ children, color, image, loading }: Props) {
  const { isMobile } = useLayoutBreakpoints();

  return (
    <Card
      data-testid="info-card"
      elevation={color === InfoCardColor.Paper ? 3 : 0}
      sx={getStyle(color, !isMobile && image)}
    >
      <CardContent sx={contentStyle}>
        {loading ? <InfoCardSkeleton /> : children}
      </CardContent>
    </Card>
  );
}

export default InfoCard;
