import React, { ComponentType } from 'react';
import MenuItem, { MenuItemProps } from '@mui/material/MenuItem';
import { GroupOption } from './types';

const style = {
  py: 0.8,
  typography: 'caption',
  '&.Mui-disabled': {
    opacity: 1,
  },
};

type Props = MenuItemProps & {
  option: GroupOption;
  renderer?: ComponentType<GroupOption>;
};

function SelectGroupOption({ option, renderer: Renderer, ...rest }: Props) {
  const { id, labels = {} } = option;
  return (
    <MenuItem data-testid="select-group-option" sx={style} disabled {...rest}>
      {Renderer ? <Renderer {...option} /> : labels[id] ?? id}
    </MenuItem>
  );
}

export default SelectGroupOption;
