import React from 'react';
import { FormProvider, FormProviderProps } from 'react-hook-form';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import { useUpdateEffect } from 'src/hooks';
import { scrollToError } from 'src/helpers';
import { cloneObject } from 'src/utils';
import ResponsiveStack from 'src/components/ResponsiveStack';
import { StepperFormValues } from './types';

type Props<TFieldValues extends StepperFormValues> =
  FormProviderProps<TFieldValues> & {
    onStepBack?: () => void;
    onStepNext: (values?: TFieldValues) => void;
    onUpdate: (values: TFieldValues) => void;
    activeStep: number;
    step: number;
    stepId?: string;
  };

const btnStyle = { alignSelf: { sm: 'flex-start' } };

function StepperForm<TFieldValues extends StepperFormValues>({
  children,
  onStepBack,
  onStepNext,
  onUpdate,
  activeStep,
  step,
  stepId,
  ...formProps
}: Props<TFieldValues>) {
  formProps.watch((values) => onUpdate(cloneObject(values as TFieldValues)));

  useUpdateEffect(() => {
    onUpdate({ step: activeStep } as TFieldValues);
  }, [activeStep, onUpdate]);

  return (
    <FormProvider {...formProps}>
      <Stack
        data-testid={`step-${stepId}`}
        component="form"
        gap={2}
        onSubmit={formProps.handleSubmit(onStepNext, scrollToError)}
      >
        {children}
        <ResponsiveStack>
          <Button data-testid="step-next-btn" type="submit" sx={btnStyle}>
            Continue
          </Button>
          {onStepBack && (
            <Button
              data-testid="step-back-btn"
              color="secondary"
              onClick={onStepBack}
              sx={btnStyle}
            >
              Back
            </Button>
          )}
        </ResponsiveStack>
      </Stack>
    </FormProvider>
  );
}

export default StepperForm;
