import React from 'react';
import { Link } from 'react-router-dom';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import MonivoltLogo from 'src/assets/images/monivolt-logo.svg?react';
import { ROOT } from 'src/router';

function NotFound() {
  return (
    <Box mt="20%" textAlign="center">
      <Box color="text.disabled" mb="5%" component={MonivoltLogo} />
      <Typography variant="h1">404</Typography>
      <Typography variant="h8" paragraph>
        The page does not exist
      </Typography>
      <Button component={Link} to={ROOT}>
        Back home
      </Button>
    </Box>
  );
}

export default NotFound;
