import React, { ReactNode, useCallback } from 'react';
import { FormProviderProps } from 'react-hook-form';
import { useAppDispatch } from 'src/store';
import { LoanAppPayload, saveLoanAppAction } from 'src/entities/loanApps';
import StepperForm from 'src/components/StepperForm';

type Props<TFieldValues extends LoanAppPayload> =
  FormProviderProps<TFieldValues> & {
    children: ReactNode;
    onStepBack?: () => void;
    onStepNext: (values?: TFieldValues) => void;
    activeStep: number;
    step: number;
    stepId?: string;
  };

function LoanAppFormWrapper<TFieldValues extends LoanAppPayload>({
  children,
  ...props
}: Props<TFieldValues>) {
  const dispatch = useAppDispatch();
  const saveLoanApp = useCallback(
    (values: TFieldValues) => dispatch(saveLoanAppAction(values)),
    [dispatch],
  );

  return (
    <StepperForm onUpdate={saveLoanApp} {...props}>
      {children}
    </StepperForm>
  );
}

export default LoanAppFormWrapper;
