import React, { SyntheticEvent } from 'react';
import Chip from '@mui/material/Chip';
import { FileMeta } from './types';

type Props<TId> = FileMeta<TId> & {
  onDelete: (id?: TId) => void;
};

function FilesInputListItem<TId>({ onDelete, id, ...rest }: Props<TId>) {
  const handleDelete = (event: SyntheticEvent, id?: TId) => {
    event.preventDefault();
    onDelete(id);
  };

  return (
    <Chip
      component="a"
      onDelete={(event) => handleDelete(event, id)}
      sx={{ alignSelf: 'flex-start', cursor: 'pointer' }}
      {...rest}
    />
  );
}

export default FilesInputListItem;
