import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Link from '@mui/material/Link';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { useForm } from 'src/helpers';
import { LOGIN as ROUTE_LOGIN } from 'src/router';
import {
  AuthFormField,
  AuthConfirmAccountReq,
  useResendConfCodeMutation,
  useConfirmAccountMutation,
} from 'src/entities/auth';
import { AlertType, useAlert } from 'src/components/AlertsProvider';
import FormControl from 'src/components/FormControl';
import LoadingButton from 'src/components/LoadingButton';
import AuthPageWrapper from './AuthPageWrapper';
import { confirmAccountValidation } from './formData';

function ConfirmAccount() {
  const { state: email } = useLocation();
  const { showAlert } = useAlert();
  const navigate = useNavigate();
  const [confirm, { isLoading }] = useConfirmAccountMutation();
  const [resend] = useResendConfCodeMutation();
  const { control, handleSubmit } = useForm<Partial<AuthConfirmAccountReq>>({
    validation: confirmAccountValidation,
  });

  const onConfirm = (values: Partial<AuthConfirmAccountReq>) =>
    confirm({ email, ...values } as AuthConfirmAccountReq)
      .unwrap()
      .then(() => {
        showAlert({
          type: AlertType.Success,
          text: `User account for ${email} has been activated successfully`,
        });
        navigate(ROUTE_LOGIN);
      });

  const onResend = () =>
    resend({ email })
      .unwrap()
      .then(() =>
        showAlert({
          type: AlertType.Info,
          text: 'Confirmation code has been resent to your email.',
        }),
      );

  return (
    <AuthPageWrapper
      description={
        <>
          We have sent a code by email to <b>{email}</b>. Enter it below to
          confirm your account.
        </>
      }
      onSubmit={handleSubmit(onConfirm)}
    >
      <FormControl
        data-testid="confirmation-code-input"
        name={AuthFormField.ConfirmationCode}
        label="Confirmation code"
        control={control}
        component={TextField}
      />
      <LoadingButton
        data-testid="confirm-acc-btn"
        loading={isLoading}
        type="submit"
      >
        Confirm account
      </LoadingButton>
      <Typography>
        Didn`t receive a code?
        <Link data-testid="resend-code-btn" onClick={onResend} ml={1}>
          Send a new code
        </Link>
      </Typography>
    </AuthPageWrapper>
  );
}

export default ConfirmAccount;
