import { Components } from '@mui/material/styles';
import MuiCssBaseline from './global';
import MuiAppBar from './header';
import MuiAvatar from './avatar';
import MuiChip from './chip';
import MuiContainer from './container';
import MuiDatePicker from './datepicker';
import MuiDivider from './divider';
import MuiLink from './link';
import MuiPaper from './paper';
import MuiSkeleton from './skeleton';
import MuiSnackbar from './snackbar';
import MuiSvgIcon from './icon';
import MuiTypography from './text';
import MuiAccordion from './accordion';
import MuiAlert from './alert';
import MuiButton from './button';
import MuiCard from './card';
import MuiDialog from './dialog';
import MuiDrawer from './drawer';
import MuiForm from './form';
import MuiInput from './input';
import MuiList from './list';
import MuiLoader from './loader';
import MuiPagination from './pagination';
import MuiStepper from './stepper';
import MuiTabs from './tabs';
import MuiSimpleTreeView from './tree';

export default {
  MuiCssBaseline,
  MuiAppBar,
  MuiAvatar,
  MuiChip,
  MuiContainer,
  MuiDatePicker,
  MuiDivider,
  MuiLink,
  MuiPaper,
  MuiSkeleton,
  MuiSnackbar,
  MuiSvgIcon,
  MuiTypography,
  ...MuiAccordion,
  ...MuiAlert,
  ...MuiButton,
  ...MuiCard,
  ...MuiDialog,
  ...MuiDrawer,
  ...MuiForm,
  ...MuiInput,
  ...MuiList,
  ...MuiLoader,
  ...MuiPagination,
  ...MuiStepper,
  ...MuiTabs,
  ...MuiSimpleTreeView,
} as Partial<Components>;
