import React from 'react';
import Drawer from '@mui/material/Drawer';
import SidebarLogo from './SidebarLogo';
import SidebarNav from './SidebarNav';
import SidebarFooter from './SidebarFooter';
import { useSidebarContext } from './SidebarContext';
import { SIDEBAR_FULL_SIZE, SIDEBAR_COLLAPSED_SIZE } from './constants';
import SidebarCloseBtn from './SidebarCloseBtn';

function Sidebar() {
  const { fullWidth, variant, open, toggleOpen } = useSidebarContext();
  const width = fullWidth ? SIDEBAR_FULL_SIZE : SIDEBAR_COLLAPSED_SIZE;

  return (
    <Drawer
      anchor="left"
      open={open}
      onClose={toggleOpen}
      variant={variant}
      sx={{ width }}
    >
      <SidebarCloseBtn />
      <SidebarLogo />
      <SidebarNav />
      <SidebarFooter />
    </Drawer>
  );
}

export default Sidebar;
