import { createStateToStorageSaver } from 'src/helpers';
import { CREDITOR_APP_STORE_KEY } from './constants';
import { resetCreditorAppAction, saveCreditorAppAction } from './actions';
import { creditorAppApi } from './api';

export const creditorAppStateSaver = createStateToStorageSaver(
  CREDITOR_APP_STORE_KEY,
  [
    creditorAppApi.endpoints.getCreditorAppDraft.matchFulfilled,
    creditorAppApi.endpoints.saveCreditorAppDraft.matchFulfilled,
    saveCreditorAppAction,
    resetCreditorAppAction,
  ],
);
