import React, { forwardRef, ForwardedRef, useMemo } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import { SelectProps as MuiSelectProps } from '@mui/material/Select';
import TextField, { TextFieldProps } from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import SelectOption, {
  SelectGroupOption,
  BaseOption,
  GroupOption,
  OptionValue,
} from '../SelectOption';
import { SelectContext } from './SelectContext';
import { SelectProps } from './types';
import { getDisplayValue, getGroupedOptions, isGroupOption } from './utils';

const loaderStyle = {
  position: 'absolute',
  right: '3.5rem',
};

function Select<T extends BaseOption, M extends boolean = false>(
  {
    options = [],
    optionRenderer,
    groupRenderer,
    groupProps,
    groupBy,
    loading,
    multiple,
    ...rest
  }: SelectProps<T, M>,
  ref: ForwardedRef<HTMLInputElement>,
) {
  const renderValue = (value: OptionValue) =>
    getDisplayValue({ value, options, optionRenderer, loading, multiple });
  const groupedOptions = useMemo(
    () => getGroupedOptions(options, groupBy),
    [groupBy, options],
  );
  const noOptions = !loading && !options.length;

  return (
    <SelectContext.Provider value={rest}>
      <TextField
        data-testid="select"
        select
        ref={ref}
        SelectProps={{ multiple, renderValue } as MuiSelectProps}
        InputProps={{
          endAdornment: loading && (
            <CircularProgress size="2.3rem" sx={loaderStyle} />
          ),
        }}
        {...(rest as TextFieldProps)}
      >
        {noOptions && (
          <MenuItem data-testid="select-menu-item-no-options" disabled>
            No available options
          </MenuItem>
        )}
        {loading && (
          <MenuItem data-testid="select-menu-item-loading" disabled>
            Loading...
          </MenuItem>
        )}
        {groupedOptions.map((option) =>
          isGroupOption(option) ? (
            <SelectGroupOption
              key={option.id}
              option={{ ...groupProps, ...option } as GroupOption}
              renderer={groupRenderer}
            />
          ) : (
            <SelectOption
              key={option.id}
              value={option.id}
              option={option as T}
              renderer={optionRenderer}
            />
          ),
        )}
      </TextField>
    </SelectContext.Provider>
  );
}

export default forwardRef(Select) as typeof Select;
