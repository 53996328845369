import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery, ReqMethod } from 'src/services';
import {
  AuthChangePassReq,
  AuthConfirmAccountReq,
  AuthForgotPassReq,
  AuthLoginRequest,
  AuthRegisterReq,
  AuthResendCodeReq,
  AuthSetNewPassReq,
} from './types';
import {
  AUTH_CHANGE_PASS_API,
  AUTH_CHECK_API,
  AUTH_CONFIRM_ACCOUNT_API,
  AUTH_FORGOT_PASS_API,
  AUTH_LOGIN_API,
  AUTH_LOGOUT_API,
  AUTH_REGISTER_API,
  AUTH_RESEND_CONF_CODE_API,
  AUTH_ROOT_API,
  AUTH_SET_NEW_PASS_API,
  AUTH_STORE_API_KEY,
} from './constants';

export const authApi = createApi({
  reducerPath: AUTH_STORE_API_KEY,
  baseQuery: baseQuery({ baseUrl: AUTH_ROOT_API }),
  endpoints: ({ mutation, query }) => ({
    authCheck: query<void, void>({
      query: () => ({
        url: AUTH_CHECK_API,
        method: ReqMethod.Get,
      }),
    }),
    login: mutation<void, AuthLoginRequest>({
      query: (body) => ({
        url: AUTH_LOGIN_API,
        method: ReqMethod.Post,
        body,
      }),
    }),
    logout: mutation<void, void>({
      query: () => ({
        url: AUTH_LOGOUT_API,
        method: ReqMethod.Post,
      }),
    }),
    changePassword: mutation<void, AuthChangePassReq>({
      query: (body) => ({
        url: AUTH_CHANGE_PASS_API,
        method: ReqMethod.Post,
        body,
      }),
    }),
    forgotPassword: mutation<void, AuthForgotPassReq>({
      query: (body) => ({
        url: AUTH_FORGOT_PASS_API,
        method: ReqMethod.Post,
        body,
      }),
    }),
    setNewPassword: mutation<void, AuthSetNewPassReq>({
      query: (body) => ({
        url: AUTH_SET_NEW_PASS_API,
        method: ReqMethod.Post,
        body,
      }),
    }),
    register: mutation<void, AuthRegisterReq>({
      query: (body) => ({
        url: AUTH_REGISTER_API,
        method: ReqMethod.Post,
        body,
      }),
    }),
    resendConfCode: mutation<void, AuthResendCodeReq>({
      query: (body) => ({
        url: AUTH_RESEND_CONF_CODE_API,
        method: ReqMethod.Post,
        body,
      }),
    }),
    confirmAccount: mutation<void, AuthConfirmAccountReq>({
      query: (body) => ({
        url: AUTH_CONFIRM_ACCOUNT_API,
        method: ReqMethod.Post,
        body,
      }),
    }),
  }),
});

export const {
  useAuthCheckQuery,
  useChangePasswordMutation,
  useForgotPasswordMutation,
  useLoginMutation,
  useLogoutMutation,
  useSetNewPasswordMutation,
  useRegisterMutation,
  useResendConfCodeMutation,
  useConfirmAccountMutation,
} = authApi;
