import { UserRole } from '../dicts';

export enum AuthFormField {
  Email = 'email',
  FirstName = 'firstName',
  LastName = 'lastName',
  ConfirmationCode = 'confirmationCode',
  ConfirmPassword = 'confirmPassword',
  NewPassword = 'newPassword',
  OldPassword = 'oldPassword',
  Password = 'password',
  Username = 'username',
  UserLogin = 'userLogin',
  VerificationCode = 'verificationCode',
}

export type AuthLoginRequest = {
  username: string;
  password: string;
};

export type AuthChangePassReq = {
  oldPassword: string;
  newPassword: string;
  confirmPassword: string;
};

export type AuthForgotPassReq = {
  email: string;
};

export type AuthSetNewPassReq = {
  email: string;
  verificationCode: string;
  newPassword: string;
  confirmPassword: string;
};

export type AuthRegisterReq = {
  email: string;
  firstName: string;
  lastName: string;
  password: string;
};

export type AuthConfirmAccountReq = {
  email: string;
  confirmationCode: string;
};

export type AuthResendCodeReq = {
  email: string;
};

export type AuthUserInfo = {
  id: number;
  email: string;
  firstName: string;
  lastName: string;
  userLogin: string;
  roles: UserRole[];
};

export type AuthState = {
  isAuthenticated: boolean | null;
  redirectPath: string | null;
  user: AuthUserInfo | null;
};
