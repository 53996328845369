import { generatePath as generateRoutePath } from 'react-router-dom';
import { mapObject } from './object';

type Parameter = string | number | boolean | null | undefined;
type Params = Record<string, Parameter>;

export const generatePath = (
  path: string,
  params: Params,
  defaultValue?: string,
): string => {
  const defaultParamsValue = defaultValue || 'all';
  const transformedParams = mapObject(params, (key, value) =>
    value ? value.toString() : defaultParamsValue,
  );

  return generateRoutePath(path, transformedParams);
};

export const navigateToExternalUrl = (
  url: string,
  params?: Params,
  newTab?: boolean,
) => {
  const path = params ? generatePath(url, params) : url;
  if (newTab) {
    window.open(path);
  } else {
    window.location.replace(path);
  }
};
