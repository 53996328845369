import React from 'react';
import { Link } from 'react-router-dom';
import List from '@mui/material/List';
import { useNavRoutes } from 'src/router';
import SidebarNavLink from './SidebarNavLink';
import { useSidebarContext } from './SidebarContext';

function SidebarNav() {
  const { toggleOpen } = useSidebarContext();
  const navRoutes = useNavRoutes();

  return (
    <List sx={{ flex: 1 }}>
      {navRoutes.map(({ path, ...rest }) => (
        <SidebarNavLink
          key={path}
          component={Link}
          to={path}
          onClick={toggleOpen}
          {...rest}
        />
      ))}
    </List>
  );
}

export default SidebarNav;
