import React from 'react';
import Skeleton from '@mui/material/Skeleton';
import Stack, { StackProps } from '@mui/material/Stack';
import { arrayFromNumber } from 'src/utils';

type Props = {
  rows?: number;
} & StackProps;

function SideModalSkeleton({ rows = 12, ...rest }: Props) {
  return (
    <Stack mt={4} gap={1.2} {...rest}>
      {arrayFromNumber(rows).map((row) => (
        <Skeleton key={row} variant="text" />
      ))}
    </Stack>
  );
}

export default SideModalSkeleton;
