import React, { ForwardedRef, forwardRef, ReactNode } from 'react';
import { useGetCountriesQuery } from 'src/entities/countries';
import AutoComplete from './AutoComplete';

type Props = {
  error?: boolean;
  helperText?: ReactNode;
  label?: string;
  multiple?: boolean;
};

function CountrySelect(
  { label = 'Country', ...rest }: Props,
  ref: ForwardedRef<HTMLInputElement>,
) {
  const { data, isLoading } = useGetCountriesQuery();

  return (
    <AutoComplete
      data-testid="country-select"
      ref={ref}
      options={data?.options}
      loading={isLoading}
      label={label}
      {...rest}
    />
  );
}

export default forwardRef(CountrySelect);
