import React, { ReactNode } from 'react';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { BaseOption } from './types';

const style = {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'baseline',
  gap: 1,
};

type Props = BaseOption & {
  label?: string;
  description?: string;
  icon?: ReactNode;
};

function SelectIconOptionRenderer({ icon, label, description }: Props) {
  return (
    <>
      {icon && <ListItemIcon>{icon}</ListItemIcon>}
      <ListItemText
        sx={style}
        primary={label}
        secondary={description}
        secondaryTypographyProps={{}}
      />
    </>
  );
}

export default SelectIconOptionRenderer;
