import React, { MouseEventHandler } from 'react';
import { ButtonProps } from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Dialog, { DialogProps } from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent, { DialogContentProps } from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import SvgIcon from '@mui/material/SvgIcon';
import CloseIcon from 'src/assets/icons/close-icon.svg?react';
import LoadingButton from 'src/components/LoadingButton';
import { useLayoutBreakpoints } from 'src/hooks';
import { ModalCloseHandler } from './types';

type Props = Omit<DialogProps, 'onClose'> & {
  title: string;
  text?: string;
  onClose?: ModalCloseHandler;
  onCancel?: MouseEventHandler;
  onConfirm?: MouseEventHandler;
  contentProps?: DialogContentProps;
  cancelBtnColor?: ButtonProps['color'];
  confirmBtnColor?: ButtonProps['color'];
  confirmBtnType?: 'button' | 'submit' | 'reset' | undefined;
  cancelBtnLoading?: boolean;
  confirmBtnLoading?: boolean;
  cancelBtnDisabled?: boolean;
  confirmBtnDisabled?: boolean;
  cancelBtnText?: string;
  confirmBtnText?: string;
  showConfirmBtn?: boolean;
  showCancelBtn?: boolean;
};

function ModalDialog({
  children,
  open,
  title,
  text,
  onCancel,
  onClick,
  onClose,
  onConfirm,
  contentProps,
  cancelBtnDisabled,
  confirmBtnDisabled,
  cancelBtnLoading,
  confirmBtnLoading,
  cancelBtnColor = 'secondary',
  confirmBtnColor = 'primary',
  cancelBtnText = 'Cancel',
  confirmBtnText = 'Ok',
  showCancelBtn = true,
  showConfirmBtn = false,
  confirmBtnType,
  ...rest
}: Props) {
  const { isMobile } = useLayoutBreakpoints();
  const showActions = showCancelBtn || showConfirmBtn;

  return (
    <Dialog
      open={open}
      onClose={onClose as DialogProps['onClose']}
      onClick={onClick}
      fullScreen={isMobile}
      data-testid="modal-dialog"
      {...rest}
    >
      <DialogTitle data-testid="modal-dialog-title">
        {title}
        <IconButton
          data-testid="modal-dialog-close-btn"
          size="small"
          onClick={onClose}
        >
          <SvgIcon component={CloseIcon} />
        </IconButton>
      </DialogTitle>
      <DialogContent {...contentProps}>
        {text}
        {children}
      </DialogContent>
      {showActions && (
        <DialogActions data-testid="modal-dialog-actions">
          {showCancelBtn && (
            <LoadingButton
              disabled={cancelBtnDisabled}
              loading={cancelBtnLoading}
              onClick={onCancel || onClose}
              color={cancelBtnColor}
              data-testid="modal-dialog-cancel-btn"
              size="small"
              fullWidth
            >
              {cancelBtnText}
            </LoadingButton>
          )}
          {showConfirmBtn && (
            <LoadingButton
              disabled={confirmBtnDisabled}
              loading={confirmBtnLoading}
              onClick={onConfirm}
              color={confirmBtnColor}
              data-testid="modal-dialog-confirm-btn"
              size="small"
              autoFocus
              fullWidth
              type={confirmBtnType}
            >
              {confirmBtnText}
            </LoadingButton>
          )}
        </DialogActions>
      )}
    </Dialog>
  );
}

export default ModalDialog;
