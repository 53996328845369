import React from 'react';
import { ListItemProps } from './types';
import FilesInputListItem from './FilesInputListItem';

function FilesInputBaseItem({ onDelete, item }: ListItemProps<File, string>) {
  const { name } = item;
  return <FilesInputListItem onDelete={onDelete} id={name} label={name} />;
}

export default FilesInputBaseItem;
