import { LoanAppStatus } from 'src/entities/loanApps';

type StatusData = {
  color: 'primary' | 'secondary' | 'info' | 'warning' | 'error';
  label: string;
  description?: string;
};

export const statusMap: Record<LoanAppStatus, StatusData> = {
  [LoanAppStatus.Draft]: {
    color: 'secondary',
    label: 'Draft',
    description: 'Unfinished application',
  },
  [LoanAppStatus.Processing]: {
    color: 'warning',
    label: 'Processing',
    description: 'Under review',
  },
  [LoanAppStatus.Approved]: {
    color: 'primary',
    label: 'Approved',
  },
  [LoanAppStatus.Rejected]: {
    color: 'error',
    label: 'Rejected',
    description: 'Unsuccessful application',
  },
  [LoanAppStatus.SourcingOfCapital]: {
    color: 'info',
    label: 'Sourcing Of Capital',
    description: 'Shared with capital partners',
  },
  [LoanAppStatus.Funded]: {
    color: 'primary',
    label: 'Funded',
    description: 'Application funded',
  },
  [LoanAppStatus.Inactive]: {
    color: 'secondary',
    label: 'Inactive',
    description: 'Non-actionable',
  },
};
