import { createStateToStorageSaver } from 'src/helpers';
import { LOAN_APP_STORE_KEY } from './constants';
import { resetLoanAppAction, saveLoanAppAction } from './actions';
import { loanAppsApi } from './api';

export const loanAppStateSaver = createStateToStorageSaver(LOAN_APP_STORE_KEY, [
  loanAppsApi.endpoints.getLoanAppDraft.matchFulfilled,
  loanAppsApi.endpoints.saveLoanAppDraft.matchFulfilled,
  saveLoanAppAction,
  resetLoanAppAction,
]);
