import { FundingOppItem } from './types';

export const mapFundingOppItem = (item: FundingOppItem) => {
  const { irrFrom, irrTo } = item;
  return { ...item, irr: [irrFrom, irrTo] };
};

export const getIrrDisplayValue = (irrFrom?: number, irrTo?: number) => {
  if (!irrFrom) {
    return null;
  }
  if (!irrTo) {
    return `>${irrFrom}%`;
  }
  return `${irrFrom} - ${irrTo}%`;
};
