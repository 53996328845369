import baseTheme from '../baseTheme';

const { spacing } = baseTheme;

export default {
  styleOverrides: {
    root: {
      height: spacing(3.2),
      width: spacing(3.2),
      borderRadius: spacing(0.8),
    },
  },
};
