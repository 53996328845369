import React, { ReactNode } from 'react';
import Stack from '@mui/material/Stack';
import Skeleton from '@mui/material/Skeleton';
import { arrayFromNumber } from 'src/utils';
import { SxProps } from 'src/theme/types';

type Props = {
  children?: ReactNode;
  loading?: boolean;
};

const overlayStyle: SxProps = {
  background: ({ palette }) => palette.gradient.bgDefault,
  color: 'text.secondary',
  gap: 2,
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'center',
  position: 'absolute',
  height: '100%',
  width: '100%',
  typography: 'caption',
};

function TreeViewSkeleton({ children, loading }: Props) {
  const animation = loading ? undefined : false;

  return (
    <Stack position="relative" gap={1}>
      {arrayFromNumber(5).map((row) => (
        <Skeleton key={row} animation={animation} variant="text" width="100%" />
      ))}
      {!loading && <Stack sx={overlayStyle}>{children}</Stack>}
    </Stack>
  );
}

export default TreeViewSkeleton;
