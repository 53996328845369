import React from 'react';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import { SelectChangeEvent } from '@mui/material/Select';
import {
  BaseOption,
  DefaultOption,
  OptionValue,
} from 'src/components/SelectOption';
import { useSelectContext } from './SelectContext';
import { getFilteredValue } from './utils';

type Props<T extends BaseOption> = {
  value?: T[];
};

function SelectMultipleValue<T extends BaseOption>({ value = [] }: Props<T>) {
  const { onChange } = useSelectContext();
  const handleUnselect = (
    event: SelectChangeEvent<OptionValue[]>,
    id: OptionValue,
  ) => {
    if (onChange) {
      event.target.value = getFilteredValue(value, id);
      onChange(event);
    }
  };

  return (
    <Stack
      data-testid="select-multiple-value"
      direction="row"
      flexWrap="wrap"
      gap={0.5}
    >
      {value.map(({ id, label }: DefaultOption) => (
        <Chip
          key={id}
          label={label}
          onDelete={(event) => handleUnselect(event, id)}
          onMouseDown={(event) => event.stopPropagation()}
        />
      ))}
    </Stack>
  );
}

export default SelectMultipleValue;
