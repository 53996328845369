import React from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import Link from '@mui/material/Link';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { useForm } from 'src/helpers';
import {
  AuthFormField,
  AuthRegisterReq,
  useRegisterMutation,
} from 'src/entities/auth';
import * as ROUTES from 'src/router';
import FormControl from 'src/components/FormControl';
import LoadingButton from 'src/components/LoadingButton';
import MaskedTextfield from 'src/components/MaskedTextfield';
import AuthPageWrapper from './AuthPageWrapper';
import { registerValidation } from './formData';

function Register() {
  const navigate = useNavigate();
  const [register, { isLoading }] = useRegisterMutation();
  const { handleSubmit, control } = useForm<AuthRegisterReq>({
    validation: registerValidation,
  });

  const onSubmit = (values: AuthRegisterReq) =>
    register(values)
      .unwrap()
      .then(() => navigate(ROUTES.CONFIRM_ACCOUNT, { state: values.email }));

  return (
    <AuthPageWrapper onSubmit={handleSubmit(onSubmit)}>
      <FormControl
        data-testid="email-input"
        name={AuthFormField.Email}
        label="Email"
        control={control}
        component={TextField}
      />
      <FormControl
        data-testid="first-name-input"
        name={AuthFormField.FirstName}
        label="First name"
        control={control}
        component={TextField}
      />
      <FormControl
        data-testid="last-name-input"
        name={AuthFormField.LastName}
        label="Last name"
        control={control}
        component={TextField}
      />
      <FormControl
        data-testid="password-input"
        name={AuthFormField.Password}
        label="Password"
        control={control}
        component={MaskedTextfield}
      />
      <LoadingButton
        data-testid="register-btn"
        type="submit"
        loading={isLoading}
      >
        Sign up
      </LoadingButton>
      <Typography>
        Already have an account?
        <Link
          data-testid="login-link"
          component={RouterLink}
          to={ROUTES.LOGIN}
          ml={1}
        >
          Sign in
        </Link>
      </Typography>
    </AuthPageWrapper>
  );
}

export default Register;
