import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery, QueryRoot, ReqMethod, Response } from 'src/services';
import { generatePath, mapObjToFormData } from 'src/utils';
import {
  LOAN_APP_CONFIRM_CREDITORS_API,
  LOAN_APP_CREDITORS_LIST_API,
  LOAN_APP_CREDITORS_TAG,
  LOAN_APP_DETAILS_API,
  LOAN_APP_DRAFT_API,
  LOAN_APP_DRAFT_TAG,
  LOAN_APP_LIST_TAG,
  LOAN_APP_ROOT_API,
  LOAN_APP_SEND_API,
  LOAN_APP_STORE_API_KEY,
} from './constants';
import {
  LoanAppConfirmCreditorsParams,
  LoanAppCreditor,
  LoanAppItem,
  LoanAppsListParams,
  LoanAppState,
} from './types';

export const loanAppsApi = createApi({
  reducerPath: LOAN_APP_STORE_API_KEY,
  baseQuery: baseQuery({ root: QueryRoot.Pa, baseUrl: LOAN_APP_ROOT_API }),
  tagTypes: [LOAN_APP_DRAFT_TAG, LOAN_APP_LIST_TAG, LOAN_APP_CREDITORS_TAG],
  refetchOnMountOrArgChange: true,
  endpoints: ({ mutation, query }) => ({
    getLoanApps: query<Response<LoanAppItem[]>, LoanAppsListParams>({
      query: (queryParams) => ({ method: ReqMethod.Get, queryParams }),
      providesTags: [LOAN_APP_LIST_TAG],
    }),
    getLoanAppDetails: query<LoanAppItem, number>({
      query: (id) => ({
        method: ReqMethod.Get,
        url: generatePath(LOAN_APP_DETAILS_API, { id }),
      }),
      providesTags: [LOAN_APP_LIST_TAG],
    }),
    getLoanAppDraft: query<LoanAppState, void | null>({
      query: () => ({
        url: LOAN_APP_DRAFT_API,
        method: ReqMethod.Get,
      }),
      providesTags: [LOAN_APP_DRAFT_TAG],
    }),
    saveLoanAppDraft: mutation<LoanAppState, LoanAppState>({
      query: (body) => ({
        url: LOAN_APP_DRAFT_API,
        method: ReqMethod.Put,
        body,
      }),
    }),
    sendLoanApp: mutation<void, LoanAppState>({
      query: (body) => ({
        url: LOAN_APP_SEND_API,
        method: ReqMethod.Put,
        body,
      }),
      invalidatesTags: [LOAN_APP_DRAFT_TAG, LOAN_APP_LIST_TAG],
    }),
    getLoanAppCreditors: query<LoanAppCreditor[], number>({
      query: (id) => ({
        url: generatePath(LOAN_APP_CREDITORS_LIST_API, { id }),
        method: ReqMethod.Get,
      }),
      providesTags: [LOAN_APP_CREDITORS_TAG],
    }),
    confirmLoanAppCreditors: mutation<void, LoanAppConfirmCreditorsParams>({
      query: ({ id, creditorIds }) => ({
        url: generatePath(LOAN_APP_CONFIRM_CREDITORS_API, { id }),
        method: ReqMethod.Put,
        body: mapObjToFormData({ creditorIds }),
      }),
      invalidatesTags: [LOAN_APP_CREDITORS_TAG],
    }),
  }),
});

export const {
  useGetLoanAppsQuery,
  useGetLoanAppDetailsQuery,
  useGetLoanAppDraftQuery,
  useSaveLoanAppDraftMutation,
  useSendLoanAppMutation,
  useGetLoanAppCreditorsQuery,
  useConfirmLoanAppCreditorsMutation,
} = loanAppsApi;
