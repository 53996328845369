import {
  createListenerMiddleware,
  isAnyOf,
  Action,
  PayloadAction,
} from '@reduxjs/toolkit';
import { saveDataToStorage } from 'src/services';
import { RootState } from 'src/store';

type TypeGuard<T> = (value: T) => value is T;
type ActionMatcher<T> = { match: TypeGuard<T> } | TypeGuard<T>;

export const createStateToStorageSaver = <Name extends string>(
  name: Name,
  actions: ActionMatcher<Action>[],
) => {
  const stateSaver = createListenerMiddleware();

  stateSaver.startListening({
    matcher: isAnyOf(...actions),
    effect: (action, listenerApi) => {
      const state = listenerApi.getState() as RootState;
      saveDataToStorage(name, state[name as keyof RootState]);
    },
  });

  return stateSaver;
};

export const createEntityStateReducers = <State>(initialState: State) => ({
  get: (state: State, { payload }: PayloadAction<Partial<State>>) => ({
    ...payload,
    ...state,
  }),
  set: (state: State, { payload }: PayloadAction<Partial<State>>) => ({
    ...state,
    ...payload,
  }),
  reset: () => initialState,
});
