import React, { ComponentType, ReactNode } from 'react';
import { SkeletonProps } from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import { elementsArrFromNumber } from 'src/utils';
import { SxProps } from 'src/theme/types';
import DataListSkeletonRow from './DataListSkeletonRow';

type Props = {
  children?: ReactNode;
  loading?: boolean;
  rowComponent?: ComponentType<SkeletonProps>;
};

const overlayStyle: SxProps = {
  color: 'text.secondary',
  gap: 1.5,
  alignItem: 'center',
  justifyContent: 'center',
  textAlign: 'center',
  position: 'absolute',
  height: '100%',
  width: '100%',
  background: ({ palette }) => palette.gradient.bgDefault,
};

function DataListSkeleton({
  children,
  loading,
  rowComponent: Row = DataListSkeletonRow,
}: Props) {
  const animation = loading ? undefined : false;

  return (
    <Stack gap={5} position="relative">
      {elementsArrFromNumber(<Row animation={animation} />, 5)}
      <Stack sx={overlayStyle}>{!loading && children}</Stack>
    </Stack>
  );
}

export default DataListSkeleton;
