import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery, QueryRoot, ReqMethod } from 'src/services';
import { generatePath, mapObjToFormData } from 'src/utils';
import {
  DOCS_API,
  DOCS_DELETE_API,
  DOCS_ROOT_API,
  DOCS_STORE_API_KEY,
} from './constants';
import {
  DocFileMeta,
  DocGetMetaReqParams,
  DocDeleteReqParams,
  DocUploadReqParams,
} from './types';

export const docsApi = createApi({
  reducerPath: DOCS_STORE_API_KEY,
  baseQuery: baseQuery({ root: QueryRoot.Ver, baseUrl: DOCS_ROOT_API }),
  endpoints: ({ mutation, query }) => ({
    getDocMeta: query<DocFileMeta[], DocGetMetaReqParams>({
      query: ({ userId, documentIds }) => ({
        url: generatePath(DOCS_API, { userId }),
        method: ReqMethod.Get,
        queryParams: { documentIds },
      }),
    }),
    deleteDoc: mutation<DocFileMeta[], DocDeleteReqParams>({
      query: ({ userId, documentId }) => ({
        url: generatePath(DOCS_DELETE_API, { userId, documentId }),
        method: ReqMethod.Delete,
      }),
    }),
    uploadDocs: mutation<DocFileMeta[], DocUploadReqParams>({
      query: ({ userId, docType, files }) => ({
        url: generatePath(DOCS_API, { userId }),
        method: ReqMethod.Post,
        body: mapObjToFormData({
          partyIds: userId,
          documentTypes: docType,
          documentFiles: files,
        }),
      }),
    }),
  }),
});

export const {
  useGetDocMetaQuery,
  useDeleteDocMutation,
  useUploadDocsMutation,
} = docsApi;
