import React, { ComponentType, ReactNode } from 'react';
import {
  BaseOption,
  DefaultOption,
  GroupOption,
  OptionValue,
} from '../SelectOption';
import SelectMultipleValue from './SelectMultipleValue';
import { SelectValue } from './types';

type GetDisplayValueOptions<T extends BaseOption, M extends boolean> = {
  optionRenderer?: ComponentType<T>;
  options: T[];
  loading?: boolean;
  multiple?: boolean;
  value: SelectValue<T, M>;
};

export const getDisplayValue = <T extends BaseOption, M extends boolean>({
  optionRenderer: Renderer,
  options,
  value,
  loading,
  multiple,
}: GetDisplayValueOptions<T, M>): ReactNode => {
  const isMultiple = multiple && Array.isArray(value);
  const inputValue = !loading ? value : undefined;
  const selection = isMultiple
    ? options.filter(({ id }) => value.includes(id))
    : options.find(({ id }) => id === value);

  if (isMultiple) {
    return <SelectMultipleValue value={selection as T[]} />;
  }
  if (Renderer) {
    return <Renderer {...(selection as T)} />;
  }
  return (selection as DefaultOption)?.label || inputValue;
};

export const getFilteredValue = <T extends BaseOption>(
  options: T[],
  excludeId: OptionValue,
): OptionValue[] =>
  options.filter((option) => option.id !== excludeId).map(({ id }) => id);

export const getGroupedOptions = <T extends BaseOption>(
  options: T[],
  groupBy?: string,
): (T | GroupOption)[] => {
  if (groupBy) {
    const groups = options.reduce(
      (acc, curr) => {
        const groupName = curr[groupBy as keyof T] as string;
        if (!acc[groupName]) {
          acc[groupName] = [];
        }
        acc[groupName].push(curr);
        return acc;
      },
      {} as Record<string, T[]>,
    );
    return Object.entries(groups)
      .map(([id, items]) => [{ id, isGroup: true }, ...items])
      .flat();
  }
  return options;
};

export const isGroupOption = <T extends BaseOption>(option: T | GroupOption) =>
  !!(option as GroupOption).isGroup;
