import React, { FormEventHandler, ReactNode } from 'react';
import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { useMatchRouteMeta } from 'src/router';
import AuthPageLogo from './AuthPageLogo';
import AuthPageFooter from './AuthPageFooter';

type Props = {
  children: ReactNode;
  description?: ReactNode;
  onSubmit?: FormEventHandler;
};

const containerStyle = {
  display: 'flex',
  flexDirection: 'column',
  margin: 'auto',
  height: '100%',
  justifyContent: 'space-between',
  textAlign: 'center',
};

const contentStyle = {
  width: '100%',
  gap: 1.6,
};

function AuthPageWrapper({ children, description, onSubmit }: Props) {
  const { title } = useMatchRouteMeta();

  return (
    <Container
      data-testid="auth-page-wrapper"
      maxWidth="xs"
      sx={containerStyle}
    >
      <AuthPageLogo />
      <Stack component="form" onSubmit={onSubmit} {...contentStyle}>
        <Typography variant="h4" mb={3}>
          {title}
        </Typography>
        {description && (
          <Typography variant="body1" textAlign="left">
            {description}
          </Typography>
        )}
        {children}
      </Stack>
      <AuthPageFooter />
    </Container>
  );
}

export default AuthPageWrapper;
