import React from 'react';
import PageTitle from 'src/components/PageTitle';
import { AlertType, useAlert } from 'src/components/AlertsProvider';
import {
  useCreditorAppState,
  useSaveCreditorAppDraftMutation,
} from 'src/entities/creditorApp';

function CreditorAppTitle() {
  const { showAlert } = useAlert();
  const { lastModifiedDate, ...state } = useCreditorAppState(null);
  const [saveDraft, { isLoading }] = useSaveCreditorAppDraftMutation();

  const handleSaveDraft = () =>
    saveDraft(state)
      .unwrap()
      .then(() =>
        showAlert({
          type: AlertType.Success,
          text: 'Debt investor application draft has been saved',
        }),
      );

  return (
    <PageTitle
      title="Debt Investor application"
      onSave={handleSaveDraft}
      loading={isLoading}
      lastSaved={lastModifiedDate}
      canSave
    />
  );
}

export default CreditorAppTitle;
