import React, { useState } from 'react';
import MuiTooltip, { TooltipProps } from '@mui/material/Tooltip';

type Props = TooltipProps & {
  disabled?: boolean;
};

function Tooltip({ disabled, children, ...rest }: Props) {
  const [showToolTip, setShowToolTip] = useState(false);

  return (
    <MuiTooltip
      open={showToolTip}
      onOpen={() => setShowToolTip(!disabled)}
      onClose={() => setShowToolTip(false)}
      {...rest}
    >
      {children}
    </MuiTooltip>
  );
}

export default Tooltip;
