import React from 'react';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

type Props = {
  title: string;
  description?: string;
  actionText?: string;
  onClick?: () => void;
};

function DataListNoContent({ title, description, actionText, onClick }: Props) {
  return (
    <>
      <Typography variant="h4">{title}</Typography>
      <Typography variant="body1">{description}</Typography>
      {actionText && (
        <Button onClick={onClick} size="small" sx={{ alignSelf: 'center' }}>
          {actionText}
        </Button>
      )}
    </>
  );
}

export default DataListNoContent;
