import React from 'react';
import MuiStepper, { StepperProps } from '@mui/material/Stepper';
import StepButton from '@mui/material/StepButton';
import StepContent from '@mui/material/StepContent';
import { useSyncState, useUpdateEffect } from 'src/hooks';
import Step from './Step';
import StepperSkeleton from './StepperSkeleton';
import { StepItem } from './types';

type Props = StepperProps & {
  initialValue?: number;
  items: StepItem[];
  loading?: boolean;
};

function Stepper({ initialValue = 0, items, loading, ...rest }: Props) {
  const [activeStep, setActiveStep] = useSyncState(initialValue);

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };
  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  useUpdateEffect(() => {
    setActiveStep(0);
  }, [items.length]);

  if (loading) {
    return <StepperSkeleton rows={items.length} />;
  }
  return (
    <MuiStepper data-testid="stepper" activeStep={activeStep} {...rest}>
      {items.map(({ label, Component }, index) => (
        <Step data-testid={`stepper-step-${index}`} key={label}>
          <StepButton
            data-testid={`stepper-step-${index}-btn`}
            disabled={index >= activeStep}
            onClick={() => setActiveStep(index)}
          >
            {label}
          </StepButton>
          <StepContent>
            <Component
              step={index}
              activeStep={activeStep}
              onStepBack={handleBack}
              onStepNext={handleNext}
            />
          </StepContent>
        </Step>
      ))}
    </MuiStepper>
  );
}

export default Stepper;
