import React from 'react';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import SvgIcon from '@mui/material/SvgIcon';
import Typography from '@mui/material/Typography';
import CircleCheckIcon from 'src/assets/icons/circle-check-icon.svg?react';
import SaveIcon from 'src/assets/icons/save-icon.svg?react';
import { useLayoutBreakpoints } from 'src/hooks';
import { getDatesOffset } from 'src/utils';
import LoadingButton, { LoadingIconButton } from 'src/components/LoadingButton';

type Props = {
  title: string;
  canSave?: boolean;
  loading?: boolean;
  lastSaved?: string;
  onSave: () => void;
};

function PageTitle({ title, canSave, loading, lastSaved, onSave }: Props) {
  const { isDesktop } = useLayoutBreakpoints();

  return (
    <Stack direction="row" alignItems="center" gap={1}>
      {title}
      {canSave && (
        <Box display="inline-flex" alignItems="center" gap={1}>
          {!isDesktop && (
            <LoadingIconButton
              onClick={onSave}
              loading={loading}
              size="small"
              color="inherit"
            >
              <SvgIcon component={SaveIcon} fontSize="large" />
            </LoadingIconButton>
          )}
          {isDesktop && (
            <LoadingButton
              onClick={onSave}
              loading={loading}
              size="small"
              sx={{ whiteSpace: 'nowrap' }}
            >
              Save data
            </LoadingButton>
          )}
          {isDesktop && lastSaved && (
            <Typography variant="body2">
              <SvgIcon component={CircleCheckIcon} />
              &nbsp;Last saved: {getDatesOffset(lastSaved)} ago
            </Typography>
          )}
        </Box>
      )}
    </Stack>
  );
}

export default PageTitle;
