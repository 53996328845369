import React, { ComponentType, ReactNode } from 'react';
import { SxProps } from '@mui/material/styles';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Paper, { PaperProps } from '@mui/material/Paper';

type Props = PaperProps & {
  component?: ComponentType;
  title?: string;
  description?: string;
  startIcon?: ReactNode;
  endIcon?: ReactNode;
};

const style = {
  color: 'text.secondary',
  cursor: 'pointer',
  position: 'relative',
  display: 'flex',
  flexGrow: 0,
  direction: 'row',
  alignItems: 'center',
  gap: 1.6,
  py: 0.2,
};

function CardButton({
  component = ListItemButton,
  title,
  description,
  startIcon,
  endIcon,
  sx,
  ...rest
}: Props) {
  return (
    <Paper
      data-testid="card-button"
      component={component}
      sx={{ ...style, ...sx } as SxProps}
      {...rest}
    >
      <ListItemAvatar>{startIcon}</ListItemAvatar>
      <ListItemText
        primaryTypographyProps={{ color: 'text.primary' }}
        primary={title}
        secondary={description}
      />
      {endIcon}
    </Paper>
  );
}

export default CardButton;
