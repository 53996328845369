import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery, QueryRoot, ReqMethod } from 'src/services';
import { COUNTRIES_ROOT_API, COUNTRIES_STORE_API_KEY } from './constants';
import { CountriesData } from './types';
import { mapCountriesData } from './utils';

export const countriesApi = createApi({
  reducerPath: COUNTRIES_STORE_API_KEY,
  baseQuery: baseQuery({ root: QueryRoot.Local, baseUrl: COUNTRIES_ROOT_API }),
  endpoints: ({ query }) => ({
    getCountries: query<CountriesData, void>({
      query: () => ({
        method: ReqMethod.Get,
      }),
      transformResponse: mapCountriesData,
    }),
  }),
});

export const { useGetCountriesQuery } = countriesApi;
