import React from 'react';
import Fab from '@mui/material/Fab';
import SvgIcon from '@mui/material/SvgIcon';
import CloseIcon from 'src/assets/icons/close-icon.svg?react';
import { useSidebarContext } from './SidebarContext';

function SidebarCloseBtn() {
  const { variant, toggleOpen } = useSidebarContext();
  const isTemporary = variant === 'temporary';

  return isTemporary ? (
    <Fab
      onClick={toggleOpen}
      sx={{ position: 'fixed', top: '1.5rem', left: '27rem' }}
    >
      <SvgIcon component={CloseIcon} />
    </Fab>
  ) : null;
}

export default SidebarCloseBtn;
