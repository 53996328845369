import React, { ReactElement, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Navigate, useLocation } from 'react-router-dom';
import { setRedirectPath, useAuthState } from 'src/entities/auth';
import { RouteProps } from './types';
import { isRouteAllowedForUserRoles, mapAccessFlagToBool } from './utils';
import * as ROUTES from './constants';

function SecureRoute({
  element,
  requireState,
  allowedForStatus,
  allowedForRoles,
}: RouteProps) {
  const dispatch = useDispatch();
  const { state, pathname } = useLocation();
  const { isAuthenticated, redirectPath, user } = useAuthState();
  const { allowedForAuthorized, allowedForUnauthorized } =
    mapAccessFlagToBool(allowedForStatus);
  const allowedForCurrUserRows = isRouteAllowedForUserRoles(
    allowedForRoles,
    user?.roles,
  );
  const redirectToLogin = !isAuthenticated && allowedForAuthorized;
  const redirectToPath =
    (isAuthenticated && allowedForUnauthorized) ||
    !allowedForCurrUserRows ||
    (!state && requireState);

  useEffect(() => {
    if (redirectToLogin) {
      dispatch(setRedirectPath(pathname));
    }
    if (isAuthenticated && pathname === redirectPath) {
      dispatch(setRedirectPath(null));
    }
  }, [isAuthenticated, pathname, redirectToLogin, redirectPath, dispatch]);

  if (redirectToLogin) {
    return <Navigate to={ROUTES.LOGIN} />;
  }
  if (redirectToPath) {
    return <Navigate to={redirectPath || ROUTES.ROOT} />;
  }
  return element as ReactElement;
}

export default SecureRoute;
