import React from 'react';
import {
  TreeItem,
  TreeItemProps as MuiTreeItemProps,
  treeItemClasses,
} from '@mui/x-tree-view/TreeItem';
import { styled, alpha } from '@mui/material/styles';
import { TreeItemProps, TreeViewItem } from './types';

type Props<T> = {
  data?: T[];
  itemProps?: TreeItemProps<T>;
};

const StyledTreeItem = styled(TreeItem)(({ theme }) => ({
  [`& .${treeItemClasses.groupTransition}`]: {
    marginLeft: 15,
    paddingLeft: 18,
    borderLeft: `1px dashed ${alpha(theme.palette.text.primary, 0.4)}`,
  },
}));

function TreeViewList<T extends TreeViewItem>({
  data = [],
  itemProps,
}: Props<T>) {
  return (
    <>
      {data.map(({ id, name, children, ...rest }) => (
        <StyledTreeItem
          {...(itemProps as MuiTreeItemProps)}
          key={id}
          itemId={id}
          label={name}
          ContentProps={{ id, ...rest }}
        >
          {children ? (
            <TreeViewList data={children as T[]} itemProps={itemProps} />
          ) : null}
        </StyledTreeItem>
      ))}
    </>
  );
}

export default TreeViewList;
