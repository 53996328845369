import { AssetClass, BorrowerType, LoanType, Sector } from './types';

export const assetClassLabels = {
  [AssetClass.Rcf]: 'RCF',
  [AssetClass.HighYieldLoan]: 'High Yield Loan',
  [AssetClass.HybridLoan]: 'Hybrid Loan',
  [AssetClass.ConvertibleLoan]: 'Convertible Loan',
  [AssetClass.SpecialSituation]: 'Special Situation',
  [AssetClass.DistressedCase]: 'Distressed Case',
  [AssetClass.Other]: 'Other',
};

export const borrowerTypeLabels = {
  [BorrowerType.PrivateCorporate]: 'Private corporate',
  [BorrowerType.PrivateCorporateSponsorBacked]:
    'Private corporate (sponsor-backed)',
  [BorrowerType.PublicCorporate]: 'Public corporate',
  [BorrowerType.PublicCorporateSponsorBacked]:
    'Public corporate (sponsor-backed)',
  [BorrowerType.GeneralPartner]: 'General partner',
  [BorrowerType.LimitedPartner]: 'Limited partner',
  [BorrowerType.PartnershipFund]: 'Partnership (fund)',
  [BorrowerType.Government]: 'Government',
  [BorrowerType.Other]: 'Other',
};

export const loanTypeLabels = {
  [LoanType.AcquisitionFinancing]: 'Acquisition financing',
  [LoanType.NavFinancing]: 'NAV financing',
  [LoanType.GpFinancing]: 'GP financing',
  [LoanType.LpFinancing]: 'LP financing',
  [LoanType.TermLoan]: 'Term loan',
  [LoanType.SpecialSituation]: 'Special situation',
  [LoanType.DistressedDebt]: 'Distressed debt',
  [LoanType.SecondaryLoan]: 'Secondary loan',
  [LoanType.SecondaryLoansBook]: 'Secondary loan book',
  [LoanType.OtherSecondary]: 'Other secondary',
  [LoanType.StructuredProduct]: 'Structured product',
  [LoanType.Other]: 'Other',
};

export const sectorLabels = {
  [Sector.Agnostic]: 'Agnostic',
  [Sector.Agriculture]: 'Agriculture',
  [Sector.BuildingProducts]: 'Building Products',
  [Sector.BusinessServices]: 'Business Services',
  [Sector.Chemicals]: 'Chemicals',
  [Sector.CleanEnergy]: 'Clean energy',
  [Sector.Coal]: 'Coal',
  [Sector.Communications]: 'Communications',
  [Sector.Construction]: 'Construction',
  [Sector.ConsumerRetail]: 'Consumer & Retail',
  [Sector.Education]: 'Education',
  [Sector.Energy]: 'Energy',
  [Sector.Fig]: 'FIG',
  [Sector.Healthcare]: 'Healthcare',
  [Sector.Insurance]: 'Insurance',
  [Sector.IndustrialsManufacturing]: 'Industrials & Manufacturing',
  [Sector.Infrastructure]: 'Infrastructure',
  [Sector.LifeScience]: 'Life Science',
  [Sector.Materials]: 'Materials',
  [Sector.MediaTelecoms]: 'Media & Telecoms',
  [Sector.Mining]: 'Mining',
  [Sector.NaturalResources]: 'Natural Resources',
  [Sector.RealAssets]: 'Real Assets',
  [Sector.RealEstate]: 'Real Estate',
  [Sector.Renewables]: 'Renewables',
  [Sector.Shipping]: 'Shipping',
  [Sector.SportEntertainment]: 'Sport & Entertainment',
  [Sector.TechnologySoftware]: 'Technology & Software',
  [Sector.Transportation]: 'Transportation',
  [Sector.Other]: 'Other',
};
