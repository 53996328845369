import React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import { useForm } from 'src/helpers';
import {
  AuthChangePassReq,
  AuthFormField,
  useChangePasswordMutation,
} from 'src/entities/auth';
import { AlertType, useAlert } from 'src/components/AlertsProvider';
import FormControl from 'src/components/FormControl';
import MaskedTextfield from 'src/components/MaskedTextfield';
import LoadingButton from 'src/components/LoadingButton';
import ResponsiveStack from 'src/components/ResponsiveStack';
import { changePassValidation } from './formData';

function Settings() {
  const { showAlert } = useAlert();
  const [changePassword, { isLoading }] = useChangePasswordMutation();
  const { control, handleSubmit, reset } = useForm<AuthChangePassReq>({
    validation: changePassValidation,
  });

  const onSubmit = (values: AuthChangePassReq) =>
    changePassword(values)
      .unwrap()
      .then(() => {
        showAlert({
          type: AlertType.Success,
          text: 'Password has been changed successfully.',
        });
        reset();
      });

  return (
    <Card sx={{ maxWidth: '80rem', mx: { xs: -2, sm: 0 } }}>
      <CardHeader title="Change password" />
      <CardContent
        data-testid="change-password-form"
        component="form"
        sx={{ gap: 2 }}
        onSubmit={handleSubmit(onSubmit)}
      >
        <ResponsiveStack>
          <FormControl
            data-testid="old-password-input"
            name={AuthFormField.OldPassword}
            label="Old password"
            control={control}
            component={MaskedTextfield}
          />
          <FormControl
            data-testid="new-password-input"
            name={AuthFormField.NewPassword}
            label="New password"
            control={control}
            component={MaskedTextfield}
          />
          <FormControl
            data-testid="confirm-password-input"
            name={AuthFormField.ConfirmPassword}
            label="Confirm password"
            control={control}
            component={MaskedTextfield}
          />
        </ResponsiveStack>
        <LoadingButton
          data-testid="change-password-btn"
          type="submit"
          loading={isLoading}
          sx={{ width: { sm: '31.5%' } }}
        >
          Change password
        </LoadingButton>
      </CardContent>
    </Card>
  );
}

export default Settings;
