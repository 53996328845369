import React, {
  forwardRef,
  ReactNode,
  ForwardedRef,
  useMemo,
  SyntheticEvent,
  ComponentType,
  useCallback,
} from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import MuiAutocomplete, { AutocompleteProps } from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { InputProps } from '@mui/material/Input';
import { PaperProps } from '@mui/material/Paper';
import {
  BaseOption,
  DefaultOption,
  SelectIconOptionRenderer,
} from '../SelectOption';
import { mapOptionsToValue, mapValueToOptions } from './utils';
import { SelectedOptions, SelectedValue } from './types';

type Props<T extends BaseOption> = Partial<
  Omit<AutocompleteProps<T, boolean, false, false>, 'value' | 'onChange'>
> & {
  value?: SelectedValue<T>;
  label?: ReactNode;
  onChange?: (value: SelectedValue<T>) => void;
  optionRenderer?: ComponentType<T>;
  error?: boolean;
  helperText?: ReactNode;
  loading?: boolean;
  multiple?: boolean;
  required?: boolean;
};

function AutoComplete<T extends BaseOption>(
  {
    id,
    value,
    options = [],
    optionRenderer = SelectIconOptionRenderer,
    label,
    loading,
    error,
    helperText,
    multiple,
    required,
    onChange,
    ...rest
  }: Props<T>,
  ref: ForwardedRef<HTMLSelectElement>,
) {
  const mappedValue = useMemo(
    () => mapValueToOptions<T>(value, options, multiple),
    [value, options, multiple],
  );
  const handleChange = useCallback(
    (event: SyntheticEvent, newValue: SelectedOptions<T>) =>
      onChange && onChange(mapOptionsToValue<T>(newValue)),
    [onChange],
  );

  return (
    <MuiAutocomplete<T, boolean>
      data-testid="autocomplete"
      ref={ref}
      loading={loading}
      multiple={multiple}
      onChange={handleChange}
      options={options}
      componentsProps={{
        paper: { 'data-testid': 'autocomplete-options' } as PaperProps,
      }}
      renderOption={(props, { id, label }: DefaultOption) => (
        <li data-testid={`autocomplete-option-${id}`} {...props}>
          {label}
        </li>
      )}
      renderInput={({ InputProps, ...rest }) => (
        <TextField
          data-testid="autocomplete-textfield"
          error={error}
          helperText={helperText}
          label={label}
          required={required}
          InputProps={
            {
              ...InputProps,
              'data-testid': 'autocomplete-input',
              endAdornment: (
                <>
                  {loading && (
                    <CircularProgress
                      data-testid="autocomplete-loader"
                      size="2.3rem"
                    />
                  )}
                  {InputProps.endAdornment}
                </>
              ),
            } as InputProps
          }
          {...rest}
        />
      )}
      value={mappedValue}
      {...rest}
    />
  );
}

export default forwardRef(AutoComplete) as typeof AutoComplete;
