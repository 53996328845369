import React, { ReactElement, ReactNode } from 'react';
import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';
import { mapAccessFlagToBool, useMatchRoute } from 'src/router';
import { useAuthState, useAuthCheckQuery } from 'src/entities/auth';
import { useUserDetailsQuery } from 'src/entities/customer';
import Header from './Header';
import Loader from './Loader';
import Sidebar, { SidebarContextProvider } from './Sidebar';

type Props = {
  children: ReactNode;
};

const rootStyle = {
  flexDirection: 'row',
  height: '100%',
};

const contentStyle = {
  mb: { xs: 5, sm: 0 },
  flex: 1,
  alignItems: 'center',
  overflow: 'auto',
};

function AppLayout({ children }: Props) {
  const { isAuthenticated } = useAuthState();
  const { isLoading } = useAuthCheckQuery();
  const { isFetching } = useUserDetailsQuery(null, { skip: !isAuthenticated });
  const { allowedForStatus } = useMatchRoute() || {};
  const { allowedForAll } = mapAccessFlagToBool(allowedForStatus);

  if (isLoading || isFetching) {
    return <Loader>Loading...</Loader>;
  }
  if (isAuthenticated || allowedForAll) {
    return (
      <Stack data-testid="authorized-layout" sx={rootStyle}>
        <SidebarContextProvider>
          <Sidebar />
          <Stack {...contentStyle}>
            <Header />
            <Container>{children}</Container>
          </Stack>
        </SidebarContextProvider>
      </Stack>
    );
  }
  return children as ReactElement;
}

export default AppLayout;
