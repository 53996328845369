import React from 'react';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import { elementsArrFromNumber } from 'src/utils';

type Props = {
  rows: number;
};

function StepperSkeleton({ rows }: Props) {
  return (
    <Stack data-testid="stepper-skeleton" gap={2}>
      {elementsArrFromNumber(
        <Stack
          data-testid="stepper-skeleton-row"
          direction="row"
          gap={1.5}
          ml={2.5}
          my={1}
        >
          <Skeleton variant="circular" width="3.2rem" height="3.2rem" />
          <Skeleton variant="text" width="65%" />
        </Stack>,
        rows,
      )}
    </Stack>
  );
}

export default StepperSkeleton;
