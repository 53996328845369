import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery, QueryRoot, ReqMethod, Response } from 'src/services';
import {
  CUSTOMER_ACCOUNTS_API,
  CUSTOMER_ROOT_API,
  CUSTOMER_STORE_API_KEY,
  CUSTOMER_USER_API,
} from './constants';
import { AccountPurposeType, AccountsData, UserInfo } from './types';
import { Currency } from '../currency';
import { mapAccountsResponse } from './utils';

export const customerApi = createApi({
  reducerPath: CUSTOMER_STORE_API_KEY,
  baseQuery: baseQuery({ root: QueryRoot.Pa, baseUrl: CUSTOMER_ROOT_API }),
  endpoints: ({ query }) => ({
    userDetails: query<UserInfo, void | null>({
      query: () => ({
        url: CUSTOMER_USER_API,
        method: ReqMethod.Get,
      }),
      transformResponse: ({ data }: Response<UserInfo>) => data,
    }),
    getAccounts: query<AccountsData, Currency | undefined>({
      query: (currency) => ({
        url: CUSTOMER_ACCOUNTS_API,
        method: ReqMethod.Get,
        queryParams: {
          accountPurposeType: AccountPurposeType.CustomerCurrent,
          currency,
        },
      }),
      transformResponse: mapAccountsResponse,
    }),
  }),
});

export const { useUserDetailsQuery, useGetAccountsQuery } = customerApi;
