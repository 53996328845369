import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import LinkButton from 'src/components/LinkButton';
import { PRIVACY_POLICY_URL, TERMS_AND_COND_URL } from 'src/constants';

function AuthPageFooter() {
  return (
    <Box p={2}>
      <LinkButton href={PRIVACY_POLICY_URL}>Privacy Policy</LinkButton>
      <LinkButton href={TERMS_AND_COND_URL}>Terms and Conditions</LinkButton>
      <Typography color="text.disabled" pt={1}>
        Copyright © 2022 | Monivolt Ltd
      </Typography>
    </Box>
  );
}

export default AuthPageFooter;
