import ListItemText, { ListItemTextProps } from '@mui/material/ListItemText';
import React from 'react';

type Props = ListItemTextProps & {
  primary: React.ReactNode;
  secondary?: React.ReactNode;
  primaryColor?: string;
};

function DataListCell({
  primary,
  secondary,
  primaryColor,
  sx,
  ...rest
}: Props) {
  return (
    <ListItemText
      primaryTypographyProps={{
        color: primaryColor,
        typography: 'button',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
      }}
      primary={primary}
      secondary={secondary}
      sx={{ flex: 1, m: 0, ...sx }}
      {...rest}
    />
  );
}

export default DataListCell;
