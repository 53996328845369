import React from 'react';
import Stepper, { StepItem } from 'src/components/Stepper';
import {
  CreditorAppFormField,
  useGetCreditorAppDraftQuery,
  useCreditorAppState,
} from 'src/entities/creditorApp';
import CreditorAppBusinessInfo from './CreditorAppBusinessInfo';
import CreditorAppContactPersons from './CreditorAppContactPersons';
import CreditorAppReview from './CreditorAppReview';

const steps: StepItem[] = [
  { label: 'General Business Information', Component: CreditorAppBusinessInfo },
  { label: 'Contact person', Component: CreditorAppContactPersons },
  { label: 'Review & Submit Application', Component: CreditorAppReview },
];

function CreditorApp() {
  const { step } = useCreditorAppState([CreditorAppFormField.Step]);
  const { isLoading } = useGetCreditorAppDraftQuery();

  return (
    <Stepper
      data-testid="creditor-app"
      initialValue={step}
      items={steps}
      loading={isLoading}
      sx={{ maxWidth: '80rem' }}
    />
  );
}

export default CreditorApp;
