export enum AssetClass {
  Rcf = 'RCF',
  HighYieldLoan = 'HIGH_YIELD_LOAN',
  HybridLoan = 'HYBRID_LOAN',
  ConvertibleLoan = 'CONVERTIBLE_LOAN',
  SpecialSituation = 'SPECIAL_SITUATION',
  DistressedCase = 'DISTRESSED_CASE',
  Other = 'OTHER',
}

export enum BorrowerType {
  PrivateCorporate = 'PRIVATE_CORPORATE',
  PrivateCorporateSponsorBacked = 'PRIVATE_CORPORATE_SPONSOR_BACKED',
  PublicCorporate = 'PUBLIC_CORPORATE',
  PublicCorporateSponsorBacked = 'PUBLIC_CORPORATE_SPONSOR_BACKED',
  GeneralPartner = 'GENERAL_PARTNER',
  LimitedPartner = 'LIMITED_PARTNER',
  PartnershipFund = 'PARTNERSHIP_FUND',
  Government = 'GOVERNMENT',
  Other = 'OTHER',
}

export enum CollateralType {
  Bonds = 'BONDS',
  Crypto = 'CRYPTO',
  Equities = 'EQUITIES',
  LpInterest = 'LP_INTEREST',
  OtherAssets = 'OTHER_ASSETS',
  OtherSecurities = 'OTHER_SECURITIES',
  PrivateAssets = 'PRIVATE_ASSETS',
}

export enum LoanType {
  AcquisitionFinancing = 'ACQUISITION_FINANCING',
  NavFinancing = 'NAV_FINANCING',
  GpFinancing = 'GP_FINANCING',
  LpFinancing = 'LP_FINANCING',
  TermLoan = 'TERM_LOAN',
  SpecialSituation = 'SPECIAL_SITUATION',
  DistressedDebt = 'DISTRESSED_DEBT',
  SecondaryLoan = 'SECONDARY_LOAN',
  SecondaryLoansBook = 'SECONDARY_LOANS_BOOK',
  OtherSecondary = 'OTHER_SECONDARY',
  StructuredProduct = 'STRUCTURED_PRODUCT',
  Other = 'OTHER',
}

export enum Sector {
  Agnostic = 'AGNOSTIC',
  Agriculture = 'AGRICULTURE',
  BuildingProducts = 'BUILDING_PRODUCTS',
  BusinessServices = 'BUSINESS_SERVICES',
  Chemicals = 'CHEMICALS',
  CleanEnergy = 'CLEAN_ENERGY',
  Coal = 'COAL',
  Communications = 'COMMUNICATIONS',
  Construction = 'CONSTRUCTION',
  ConsumerRetail = 'CONSUMER_RETAIL',
  Education = 'EDUCATION',
  Energy = 'ENERGY',
  Fig = 'FIG',
  Healthcare = 'HEALTHCARE',
  Insurance = 'INSURANCE',
  IndustrialsManufacturing = 'INDUSTRIALS_MANUFACTURING',
  Infrastructure = 'INFRASTRUCTURE',
  LifeScience = 'LIFE_SCIENCE',
  Materials = 'MATERIALS',
  MediaTelecoms = 'MEDIA_TELECOMS',
  Mining = 'MINING',
  NaturalResources = 'NATURAL_RESOURCES',
  RealAssets = 'REAL_ASSETS',
  RealEstate = 'REAL_ESTATE',
  Renewables = 'RENEWABLES',
  Shipping = 'SHIPPING',
  SportEntertainment = 'SPORT_ENTERTAINMENT',
  TechnologySoftware = 'TECHNOLOGY_SOFTWARE',
  Transportation = 'TRANSPORTATION',
  Other = 'OTHER',
}

export enum UserRole {
  CreditOfficer = 'CREDIT_OFFICER',
}
