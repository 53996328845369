import { DATE_FORMAT } from 'src/utils';
import { DateTimePickerActions } from 'src/components/DatePicker';

export default {
  defaultProps: {
    format: DATE_FORMAT,
    slots: { actionBar: DateTimePickerActions },
    slotProps: { openPickerButton: { size: 'small' } },
  },
};
