import React, { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import { ROOT as ROUTE_ROOT } from 'src/router';
import { useAppDispatch } from 'src/store';
import { getRandomInt } from 'src/utils';
import {
  CreditorAppFormField,
  CreditorAppState,
  resetCreditorAppAction,
  saveCreditorAppAction,
  useCreditorAppState,
  useSendCreditorAppMutation,
} from 'src/entities/creditorApp';
import { AlertType, useAlert } from 'src/components/AlertsProvider';
import FieldsSet, { FieldsSetCountryFormatter } from 'src/components/FieldsSet';
import { StepComponentProps } from 'src/components/Stepper';
import { StepperFormReview } from 'src/components/StepperForm';

const businessInfoFields = [
  {
    id: CreditorAppFormField.FullCompanyName,
    label: 'Full Company or Business Name',
  },
  {
    id: CreditorAppFormField.Website,
    label: 'Website',
  },
  {
    id: CreditorAppFormField.Description,
    label: 'Short description of your business',
  },
];

const addressFields = [
  {
    id: CreditorAppFormField.AddressLine1,
    label: 'Address Line 1',
  },
  {
    id: CreditorAppFormField.AddressLine2,
    label: 'Address Line 2',
  },
  {
    id: CreditorAppFormField.City,
    label: 'City',
  },
  {
    id: CreditorAppFormField.StateProvince,
    label: 'State/Province (Optional)',
  },
  {
    id: CreditorAppFormField.Country,
    label: 'Country',
    Component: FieldsSetCountryFormatter,
  },
  {
    id: CreditorAppFormField.PostalCode,
    label: 'Zip/Postal Code',
  },
];
const primaryAddressFields = [
  {
    id: CreditorAppFormField.PrimaryAddressLine1,
    label: 'Address Line 1',
  },
  {
    id: CreditorAppFormField.PrimaryAddressLine2,
    label: 'Address Line 2',
  },
  {
    id: CreditorAppFormField.PrimaryCity,
    label: 'City',
  },
  {
    id: CreditorAppFormField.PrimaryStateProvince,
    label: 'State/Province (Optional)',
  },
  {
    id: CreditorAppFormField.PrimaryCountry,
    label: 'Country',
    Component: FieldsSetCountryFormatter,
  },
  {
    id: CreditorAppFormField.PrimaryPostalCode,
    label: 'Zip/Postal Code',
  },
];

const contactPersonFields = [
  {
    id: CreditorAppFormField.Name,
    label: 'First Name',
  },
  {
    id: CreditorAppFormField.Surname,
    label: 'Last Name',
  },
  {
    id: CreditorAppFormField.PersonRelType,
    label: 'Relationship to the Company',
  },
  {
    id: CreditorAppFormField.PhoneNumber,
    label: 'Contact Telephone Number',
  },
  {
    id: CreditorAppFormField.Email,
    label: 'Email Address for Correspondence',
  },
];

function CreditorAppReview(props: StepComponentProps) {
  const { showAlert } = useAlert();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const creditorAppState = useCreditorAppState();
  const { addressIsSame, contactPersons, ...formData } = creditorAppState;
  const [sendCreditorApp, { isLoading }] = useSendCreditorAppMutation();

  const handleSubmit = () =>
    sendCreditorApp(creditorAppState)
      .unwrap()
      .then(() => {
        showAlert({
          type: AlertType.Success,
          text: 'Your application has been successfully submitted, our team will review it and get back to you as soon as possible.',
        });
        dispatch(resetCreditorAppAction());
        navigate(ROUTE_ROOT);
      });
  const handleUpdate = useCallback(
    (values: CreditorAppState) => {
      dispatch(saveCreditorAppAction(values));
    },
    [dispatch],
  );

  return (
    <StepperFormReview
      {...props}
      loading={isLoading}
      onStepNext={handleSubmit}
      onUpdate={handleUpdate}
    >
      <Divider>General Business Information</Divider>
      <FieldsSet
        data-testid="review-business-info"
        fields={businessInfoFields}
        data={formData}
        zebra
      />
      <Typography variant="overline">Registered Office Address</Typography>
      <FieldsSet
        data-testid="review-office-address"
        fields={addressFields}
        data={formData}
        zebra
      />
      {!addressIsSame && (
        <>
          <Typography variant="overline">Primary Business Address</Typography>
          <FieldsSet
            data-testid="review-business-address"
            fields={primaryAddressFields}
            data={formData}
            zebra
          />
        </>
      )}
      <Divider>Contact Person</Divider>
      {contactPersons?.map((item) => (
        <FieldsSet
          data-testid="review-contact-persons"
          key={getRandomInt()}
          fields={contactPersonFields}
          data={item}
          pb={1}
          zebra
        />
      ))}
    </StepperFormReview>
  );
}

export default CreditorAppReview;
