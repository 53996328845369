import {
  EqualityFn,
  TypedUseSelectorHook,
  useDispatch,
  useSelector,
} from 'react-redux';
import { compareObjects } from 'src/utils';
import type { RootState, AppDispatch } from './types';

export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export const useObjSelector = <Selected extends Record<string, unknown>>(
  selector: (state: RootState) => Selected,
  watchKeys: string[] | null = [],
) => {
  const equalityFn: EqualityFn<Selected> = (prev, next) =>
    compareObjects(prev, next, watchKeys);
  return useAppSelector(
    selector,
    watchKeys ? (equalityFn as EqualityFn<unknown>) : undefined,
  );
};
