import React from 'react';

import { DataRoomGroup } from 'src/entities/dataRoom';
import {
  DataRoomUploadFilesParams,
  DataRoomUploadFilesFields,
} from 'src/entities/dataRoom/types';
import ResponsiveStack from 'src/components/ResponsiveStack';
import FormControl from 'src/components/FormControl';
import ModalDialog from '../ModalDialog/ModalDialog';
import { ModalCloseHandler } from '../ModalDialog/types';
import { useUploadDataRoomDocsMutation } from '../../entities/dataRoom';
import { AlertType, useAlert } from '../AlertsProvider';
import { useForm } from '../../helpers';
import { uploadFilesValidation } from './DataRoomFormData';
import { DocTypeCode } from '../../entities/documents';
import {
  DataRoomFolderUploadInput,
  DataRoomDocUploadInput,
} from '../DocUploadInput';

type Props = {
  group: DataRoomGroup;
  loanApplicationId: number;
  parentId: string;
  uploadFolder: boolean;
  open: boolean;
  onClose: ModalCloseHandler;
};

function FilesDataRoomUploadModal({
  loanApplicationId,
  group,
  parentId,
  uploadFolder = false,
  open,
  onClose,
}: Props) {
  const [uploadFilesRequest, { isLoading: isSaving }] =
    useUploadDataRoomDocsMutation();
  const { showAlert } = useAlert();
  const { control, handleSubmit, setValue } =
    useForm<DataRoomUploadFilesParams>({
      validation: uploadFilesValidation,
    });
  const onSubmit = (values: DataRoomUploadFilesParams) => {
    uploadFilesRequest({
      loanApplicationId,
      parentId,
      group,
      documentFiles: values.documentFiles,
    })
      .unwrap()
      .then(() => {
        showAlert({
          type: AlertType.Success,
          text: 'Files were uploaded',
        });
        onClose();
      });
  };

  const handleFilesChange = (files: File[] | null) => {
    const dt = new DataTransfer();
    files?.forEach((file) => dt.items.add(file));
    setValue(DataRoomUploadFilesFields.DocumentFiles, dt.files);
  };

  return (
    <ModalDialog
      PaperProps={{
        component: 'form',
        onSubmit: handleSubmit(onSubmit),
        // onSubmit,
        sx: { minHeight: '25rem' },
      }}
      open={open}
      onClose={onClose}
      title="Upload files"
      cancelBtnText="Close"
      confirmBtnText={uploadFolder ? 'Upload folder' : 'Upload files'}
      showConfirmBtn
      confirmBtnType="submit"
      confirmBtnLoading={isSaving}
    >
      <ResponsiveStack>
        <FormControl
          control={control}
          docType={DocTypeCode.Uncategorized}
          name={DataRoomUploadFilesFields.DocumentFiles}
          label="Supporting documents"
          description="Documents to support your loan application."
          component={
            uploadFolder ? DataRoomFolderUploadInput : DataRoomDocUploadInput
          }
          onChange={handleFilesChange}
          multiple
        />
      </ResponsiveStack>
    </ModalDialog>
  );
}

export default FilesDataRoomUploadModal;
