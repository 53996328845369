import React, { ReactNode } from 'react';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import { useUpdateEffect } from 'src/hooks';
import LoadingButton from 'src/components/LoadingButton';
import ResponsiveStack from 'src/components/ResponsiveStack';
import { StepComponentProps } from 'src/components/Stepper';
import { StepperFormValues } from './types';

type Props<TFieldValues extends StepperFormValues> =
  Partial<StepComponentProps> & {
    children?: ReactNode;
    loading?: boolean;
    onUpdate: (values: TFieldValues) => void;
  };

const btnStyle = { alignSelf: { sm: 'flex-start' } };

function StepperFormReview<TFieldValues extends StepperFormValues>({
  activeStep,
  children,
  loading,
  onStepBack,
  onStepNext,
  onUpdate,
}: Props<TFieldValues>) {
  useUpdateEffect(() => {
    onUpdate({ step: activeStep } as TFieldValues);
  }, [onUpdate]);

  return (
    <Stack data-testid="step-review" gap={2}>
      {children}
      <ResponsiveStack mb={2}>
        <LoadingButton
          data-testid="submit-btn"
          onClick={onStepNext}
          loading={loading}
          sx={btnStyle}
        >
          Submit Application
        </LoadingButton>
        <Button
          data-testid="step-back-btn"
          color="secondary"
          onClick={onStepBack}
          sx={btnStyle}
        >
          Back
        </Button>
      </ResponsiveStack>
    </Stack>
  );
}

export default StepperFormReview;
