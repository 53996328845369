import React from 'react';
import Box from '@mui/material/Box';
import { SxProps } from 'src/theme/types';

type Props = {
  children: React.ReactNode;
};

const style: SxProps = {
  display: 'flex',
  flexDirection: 'column',
  maxWidth: '60rem',
  position: 'fixed',
  gap: 2,
  m: 2.5,
  bottom: 0,
  right: 0,
  zIndex: ({ zIndex }) => zIndex.snackbar,
};

function AlertsContainer({ children }: Props) {
  return <Box sx={style}>{children}</Box>;
}

export default AlertsContainer;
