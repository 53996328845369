import baseTheme from '../baseTheme';

const { spacing } = baseTheme;

export default {
  defaultProps: {
    elevation: 0,
  },
  styleOverrides: {
    root: {
      borderRadius: spacing(0.8),
    },
  },
};
