import formatISO from 'date-fns/formatISO';
import parseISO from 'date-fns/parseISO';

export const dateToString = (value: Date | null): string => {
  if (value) {
    const strValue = value.toString();
    return strValue !== 'Invalid Date'
      ? formatISO(new Date(value), { representation: 'date' })
      : strValue;
  }
  return '';
};

export const stringToDate = (value?: string | null): Date =>
  parseISO(String(value));
