import React, { ForwardedRef, forwardRef, useState } from 'react';
import InputAdornment from '@mui/material/InputAdornment';
import SvgIcon from '@mui/material/SvgIcon';
import TextField, { TextFieldProps } from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import EyeIcon from 'src/assets/icons/eye-icon.svg?react';
import EyeCloseIcon from 'src/assets/icons/eye-close-icon.svg?react';

function MaskedTextfield(
  { disabled, ...rest }: TextFieldProps,
  ref: ForwardedRef<HTMLInputElement>,
) {
  const [masked, setMasked] = useState(true);
  const icon = masked ? EyeCloseIcon : EyeIcon;

  return (
    <TextField
      ref={ref}
      disabled={disabled}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              data-testid="masked-input-btn"
              disabled={disabled}
              onClick={() => setMasked((prev) => !prev)}
              size="small"
            >
              <SvgIcon component={icon} />
            </IconButton>
          </InputAdornment>
        ),
      }}
      {...rest}
      type={masked ? 'password' : 'text'}
    />
  );
}

export default forwardRef(MaskedTextfield);
