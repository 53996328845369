import * as Yup from 'yup';
import { joinValues } from 'src/utils';
import { ValidateFilesParams } from './types';

export const getListItemKey = <TFile extends File | Record<string, unknown>>(
  file: TFile,
): string => (file.name || Object.values(file).join('')).toString();

export const getValidationMessage = (
  allowedFormats: string[],
  maxSize: number,
  maxItems: number,
) => {
  let message = '';
  if (allowedFormats.length) {
    message = `${joinValues(allowedFormats, ', ')}.`;
  }
  if (maxSize > 0) {
    message = `${message} Max size: ${maxSize}mb.`;
  }
  if (maxItems > 1) {
    message = `${message} Max files: ${maxItems}`;
  }
  return message;
};

export const validateFiles = ({
  files,
  allowedFormats,
  itemsLeft,
  itemsLimit,
  maxSize,
}: ValidateFilesParams) =>
  Yup.mixed()
    .test(
      'fileItemsLimit',
      `The files amount exceeds the limit. You can upload up to ${itemsLimit} files.`,
      (files: FileList) => files.length <= itemsLeft,
    )
    .test(
      'fileSize',
      'The file exceeds the maximum file size.',
      (files: FileList) =>
        Array.from(files).every(
          ({ size }) => !maxSize || size <= maxSize * 1024 ** 2,
        ),
    )
    .test(
      'fileFormat',
      'The file type is not supported.',
      (files: FileList) =>
        !allowedFormats.length ||
        Array.from(files).every(
          ({ name }: File) =>
            !!allowedFormats?.find((format) =>
              name.toUpperCase().endsWith(format),
            ),
        ),
    )
    .validate(files)
    .then(() => null)
    .catch(({ message }) => message);
