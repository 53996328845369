import { useContext, useEffect } from 'react';
import { API_ERROR_EVENT, Response } from 'src/services';
import AlertContext from './AlertContext';
import { DEFAULT_ALERT_MESSAGE } from './constants';
import { AlertHandlerProps, AlertType } from './types';

export const useAlert = () => useContext(AlertContext);

export const useApiErrorAlert = (
  showAlert: (props: AlertHandlerProps) => void,
) => {
  const handleApiError = ({ detail }: CustomEvent) => {
    const { errCode, errMessage, message } = (detail?.data || {}) as Response;
    const title = errCode?.toString();
    const text = errMessage || message || DEFAULT_ALERT_MESSAGE;
    if (title || text) {
      showAlert({ type: AlertType.Error, title, text });
    }
  };

  useEffect(() => {
    document.addEventListener(API_ERROR_EVENT, handleApiError as EventListener);
    return () => {
      document.removeEventListener(
        API_ERROR_EVENT,
        handleApiError as EventListener,
      );
    };
  });
};
