import { FundingOppStatus } from 'src/entities/fundingOpps';

type StatusData = {
  color: 'primary' | 'secondary' | 'info' | 'warning';
  label: string;
  description: string;
};

export const statusMap: Record<FundingOppStatus, StatusData> = {
  [FundingOppStatus.New]: {
    color: 'warning',
    label: 'New',
    description: 'Initial information shared',
  },
  [FundingOppStatus.DueDiligence]: {
    color: 'info',
    label: 'Due diligence',
    description: 'Full disclosure',
  },
  [FundingOppStatus.Funded]: {
    color: 'primary',
    label: 'Funded',
    description: 'Loan was funded',
  },
  [FundingOppStatus.Gone]: {
    color: 'secondary',
    label: 'Gone',
    description: 'No longer actionable',
  },
  [FundingOppStatus.Declined]: {
    color: 'secondary',
    label: 'Declined',
    description: 'Out of criteria',
  },
};
