import React, { ReactNode } from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import ThemeProvider from '@mui/material/styles/ThemeProvider';
import CssBaseline from '@mui/material/CssBaseline';
import AlertsProvider from './AlertsProvider';
import store from '../store';
import theme from '../theme';

type Props = {
  children: ReactNode;
};

function AppProviders({ children }: Props) {
  return (
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <BrowserRouter>
          <AlertsProvider>{children}</AlertsProvider>
        </BrowserRouter>
      </ThemeProvider>
    </Provider>
  );
}

export default AppProviders;
