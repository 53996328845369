import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import TextField from '@mui/material/TextField';
import { useForm } from 'src/helpers';
import { LOGIN as ROUTE_LOGIN } from 'src/router';
import {
  AuthFormField,
  AuthSetNewPassReq,
  useSetNewPasswordMutation,
} from 'src/entities/auth';
import FormControl from 'src/components/FormControl';
import LoadingButton from 'src/components/LoadingButton';
import { AlertType, useAlert } from 'src/components/AlertsProvider';
import MaskedTextfield from 'src/components/MaskedTextfield';
import AuthPageWrapper from './AuthPageWrapper';
import { setNewPasswordValidation } from './formData';

function SetNewPassword() {
  const { state: email } = useLocation();
  const { showAlert } = useAlert();
  const navigate = useNavigate();
  const [setPassword, { isLoading }] = useSetNewPasswordMutation();
  const { control, handleSubmit } = useForm<Partial<AuthSetNewPassReq>>({
    validation: setNewPasswordValidation,
  });

  const onSubmit = (values: Partial<AuthSetNewPassReq>) =>
    setPassword({ email, ...values } as AuthSetNewPassReq)
      .unwrap()
      .then(() => {
        showAlert({
          type: AlertType.Success,
          text: 'Password has been changed successfully.',
        });
        navigate(ROUTE_LOGIN);
      });

  return (
    <AuthPageWrapper
      description={
        <>
          We have sent a password reset code by email to <b>{email}</b>. Enter
          it below to reset your password
        </>
      }
      onSubmit={handleSubmit(onSubmit)}
    >
      <FormControl
        data-testid="verification-code-input"
        name={AuthFormField.VerificationCode}
        label="Verification code"
        control={control}
        component={TextField}
      />
      <FormControl
        data-testid="new-password-input"
        name={AuthFormField.NewPassword}
        label="New password"
        control={control}
        component={MaskedTextfield}
      />
      <FormControl
        data-testid="confirm-password-input"
        name={AuthFormField.ConfirmPassword}
        label="Confirm password"
        control={control}
        component={MaskedTextfield}
      />
      <LoadingButton
        data-testid="change-password-btn"
        loading={isLoading}
        type="submit"
      >
        Change password
      </LoadingButton>
    </AuthPageWrapper>
  );
}

export default SetNewPassword;
