import React, { useMemo } from 'react';
import Pagination from '@mui/material/Pagination';
import { DEFAULT_PAGE_SIZE } from './constants';
import { PagingProps } from './types';

function Paging({
  onPageChange,
  total,
  page = 0,
  pageSize = DEFAULT_PAGE_SIZE,
}: PagingProps) {
  const count = useMemo(
    () => (total ? Math.ceil(total / pageSize) : 0),
    [pageSize, total],
  );
  const isVisible = count > 1;

  return isVisible ? (
    <Pagination
      onChange={(event, index) => onPageChange && onPageChange(index - 1)}
      count={count}
      page={page + 1}
    />
  ) : null;
}

export default Paging;
