import React from 'react';
import {
  FUNDING_OPPS as ROUTE_FUNDING_OPPS,
  LOAN_APPS as ROUTE_LOAN_APPS,
} from 'src/router';
import { useAuthState } from 'src/entities/auth';
import { UserRole } from 'src/entities/dicts';
import { FundingOppStatus } from 'src/entities/fundingOpps';
import { LoanAppStatus } from 'src/entities/loanApps';
import {
  FundingOppDetailsContextProvider,
  FundingOppsList,
} from '../FundingOpps';
import { LoanAppsDetailsContextProvider, LoanAppsList } from '../LoanApps';
import DashboardInfo from './DashboardInfo';
import DashboardSectionTitle from './DashboardSectionTitle';
import { DASHBOARD_PAGE_SIZE } from './constants';

const FUNDING_OPP_PARAM_QUERY_KEY = 'foId';
const LOAN_APP_PARAM_QUERY_KEY = 'laId';

function Dashboard() {
  const { user } = useAuthState();
  const isCreditor = user?.roles.includes(UserRole.CreditOfficer);

  return (
    <>
      <DashboardInfo isCreditor={isCreditor} />
      {isCreditor ? (
        <>
          <DashboardSectionTitle path={ROUTE_FUNDING_OPPS} />
          <FundingOppDetailsContextProvider
            queryParamKey={FUNDING_OPP_PARAM_QUERY_KEY}
          >
            <FundingOppsList
              pageSize={DASHBOARD_PAGE_SIZE}
              status={[FundingOppStatus.New, FundingOppStatus.DueDiligence]}
            />
          </FundingOppDetailsContextProvider>
        </>
      ) : (
        <>
          <DashboardSectionTitle path={ROUTE_LOAN_APPS} />
          <LoanAppsDetailsContextProvider
            queryParamKey={LOAN_APP_PARAM_QUERY_KEY}
          >
            <LoanAppsList
              pageSize={DASHBOARD_PAGE_SIZE}
              status={[
                LoanAppStatus.Processing,
                LoanAppStatus.SourcingOfCapital,
              ]}
            />
          </LoanAppsDetailsContextProvider>
        </>
      )}
    </>
  );
}

export default Dashboard;
