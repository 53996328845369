export const ROOT = '/';
export const ROOT_ALL = '/*';
export const LOGIN = '/login';
export const FORGOT_PASSWORD = '/forgot-password';
export const SET_NEW_PASSWORD = '/set-new-password';
export const REGISTER = '/register';
export const CONFIRM_ACCOUNT = '/confirm-account';
export const DASHBOARD = '/dashboard';
export const FUNDING_OPPS = '/funding-opportunities';
export const CREDITOR_APPLICATION = '/creditor-application';
export const LOAN_APPLICATION = '/loan-application';
export const LOAN_APPS = '/loan-applications';
export const PRODUCTS = '/products';
export const MESSAGES = '/messages';
export const ACCOUNT = '/account';
export const SETTINGS = '/settings';
