import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery, QueryRoot, ReqMethod } from 'src/services';
import {
  CREDITOR_APP_DRAFT_TAG,
  CREDITOR_APP_ROOT_API,
  CREDITOR_APP_SEND_API,
  CREDITOR_APP_STORE_API_KEY,
} from './constants';
import { CreditorAppState } from './types';

export const creditorAppApi = createApi({
  reducerPath: CREDITOR_APP_STORE_API_KEY,
  baseQuery: baseQuery({ root: QueryRoot.Ver, baseUrl: CREDITOR_APP_ROOT_API }),
  tagTypes: [CREDITOR_APP_DRAFT_TAG],
  endpoints: ({ mutation, query }) => ({
    getCreditorAppDraft: query<CreditorAppState, void | null>({
      query: () => ({
        method: ReqMethod.Get,
      }),
      providesTags: [CREDITOR_APP_DRAFT_TAG],
    }),
    saveCreditorAppDraft: mutation<CreditorAppState, CreditorAppState>({
      query: (body) => ({
        method: ReqMethod.Put,
        body,
      }),
    }),
    sendCreditorApp: mutation<void, CreditorAppState>({
      query: (body) => ({
        url: CREDITOR_APP_SEND_API,
        method: ReqMethod.Put,
        body,
      }),
      invalidatesTags: [CREDITOR_APP_DRAFT_TAG],
    }),
  }),
});

export const {
  useGetCreditorAppDraftQuery,
  useSaveCreditorAppDraftMutation,
  useSendCreditorAppMutation,
} = creditorAppApi;
