import React from 'react';
import { FilesInputListItem } from '../FileInput';

type Props = {
  onDelete: (id?: string) => void;
  item: File;
};

function DataRoomDocUploadInputListItem({ onDelete, item }: Props) {
  const { name, webkitRelativePath } = item;
  const fileLabel = webkitRelativePath || name;
  return (
    <FilesInputListItem onDelete={onDelete} id={fileLabel} label={fileLabel} />
  );
}

export default DataRoomDocUploadInputListItem;
