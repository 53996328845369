import baseTheme from '../baseTheme';

const { palette, spacing, typography } = baseTheme;

export default {
  MuiAlert: {
    defaultProps: {
      variant: 'filled',
    },
    styleOverrides: {
      root: {
        ...typography.caption,
        borderRadius: spacing(0.8),
        color: palette.common.white,
        width: '100%',
      },
      icon: {
        opacity: 0.7,
      },
      action: {
        paddingLeft: spacing(3),
      },
    },
  },
  MuiAlertTitle: {
    styleOverrides: {
      root: {
        ...typography.h6,
      },
    },
  },
};
