import React from 'react';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import { arrayFromNonEmpty, generatePath } from 'src/utils';
import { useAuthState } from 'src/entities/auth';
import { DOCS_DOWNLOAD_URL, useGetDocMetaQuery } from 'src/entities/documents';
import { FieldSetItemComponentProps } from './types';

function FieldsSetDocFileFormatter({
  value,
  defaultValue,
}: FieldSetItemComponentProps) {
  const documentIds = arrayFromNonEmpty(value as number);
  const { user } = useAuthState();
  const { id: userId } = user || {};
  const { data } = useGetDocMetaQuery(
    { documentIds, userId },
    { skip: !userId || !documentIds || !documentIds.length },
  );

  return data ? (
    <Stack gap={1}>
      {data?.map(({ id, filename }) => (
        <Link key={id} href={generatePath(DOCS_DOWNLOAD_URL, { id, userId })}>
          {filename}
        </Link>
      ))}
    </Stack>
  ) : (
    <span>{defaultValue}</span>
  );
}

export default FieldsSetDocFileFormatter;
