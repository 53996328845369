import React from 'react';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import Typography from '@mui/material/Typography';
import { useForm } from 'src/helpers';
import {
  CreditorAppFormBusinessInfo,
  CreditorAppFormField,
  useCreditorAppState,
} from 'src/entities/creditorApp';
import Checkbox from 'src/components/Checkbox';
import CountrySelect from 'src/components/CountrySelect';
import FormControl from 'src/components/FormControl';
import ResponsiveStack from 'src/components/ResponsiveStack';
import { StepComponentProps } from 'src/components/Stepper';
import CreditorAppFormWrapper from './CreditorAppFormWrapper';
import { businessInfoValidation } from './formData';

function CreditorAppBusinessInfo({ onStepBack, ...props }: StepComponentProps) {
  const formProps = useForm<CreditorAppFormBusinessInfo>({
    defaultValues: { addressIsSame: true, ...useCreditorAppState() },
    validation: businessInfoValidation,
  });
  const addressIsSame = formProps.watch(CreditorAppFormField.AddressIsSame);

  return (
    <CreditorAppFormWrapper stepId="business-info" {...props} {...formProps}>
      <ResponsiveStack>
        <FormControl
          data-testid="full-company-name-input"
          name={CreditorAppFormField.FullCompanyName}
          label="Full company business name"
          component={TextField}
        />
        <FormControl
          data-testid="website-input"
          name={CreditorAppFormField.Website}
          label="Website (Optional)"
          placeholder="monivolt.com"
          component={TextField}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">https://</InputAdornment>
            ),
          }}
        />
      </ResponsiveStack>
      <FormControl
        data-testid="description-input"
        name={CreditorAppFormField.Description}
        label="Short description of your business"
        component={TextField}
      />
      <Typography variant="subtitle1">Registered Office Address</Typography>
      <FormControl
        data-testid="address-line-1-input"
        name={CreditorAppFormField.AddressLine1}
        label="Address Line 1"
        component={TextField}
      />
      <FormControl
        data-testid="address-line-2-input"
        name={CreditorAppFormField.AddressLine2}
        label="Address Line 2 (Optional)"
        component={TextField}
      />
      <ResponsiveStack>
        <FormControl
          data-testid="city-input"
          name={CreditorAppFormField.City}
          label="City"
          component={TextField}
        />
        <FormControl
          data-testid="state-province-input"
          name={CreditorAppFormField.StateProvince}
          label="State/Province (Optional)"
          component={TextField}
        />
      </ResponsiveStack>
      <ResponsiveStack>
        <FormControl
          name={CreditorAppFormField.Country}
          label="Country"
          component={CountrySelect}
        />
        <FormControl
          data-testid="postal-code-input"
          name={CreditorAppFormField.PostalCode}
          label="Zip/Postal Code"
          component={TextField}
        />
      </ResponsiveStack>
      <FormControl
        data-testid="address-is-same-checkbox"
        name={CreditorAppFormField.AddressIsSame}
        label="Primary Business Address is the same"
        component={Checkbox}
      />
      {!addressIsSame && (
        <>
          <Typography variant="subtitle1">Primary Business Address</Typography>
          <FormControl
            data-testid="primary-address-line-1-input"
            name={CreditorAppFormField.PrimaryAddressLine1}
            label="Address Line 1"
            component={TextField}
          />
          <FormControl
            data-testid="primary-address-line-2-input"
            name={CreditorAppFormField.PrimaryAddressLine2}
            label="Address Line 2 (Optional)"
            component={TextField}
          />
          <ResponsiveStack>
            <FormControl
              data-testid="primary-city-2-input"
              name={CreditorAppFormField.PrimaryCity}
              label="City"
              component={TextField}
            />
            <FormControl
              data-testid="primary-state-input"
              name={CreditorAppFormField.PrimaryStateProvince}
              label="State/Province (Optional)"
              component={TextField}
            />
          </ResponsiveStack>
          <ResponsiveStack>
            <FormControl
              data-testid="primary-country-select"
              name={CreditorAppFormField.PrimaryCountry}
              label="Country"
              component={CountrySelect}
            />
            <FormControl
              data-testid="primary-postal-code"
              name={CreditorAppFormField.PrimaryPostalCode}
              label="Zip/Postal Code"
              component={TextField}
            />
          </ResponsiveStack>
        </>
      )}
    </CreditorAppFormWrapper>
  );
}

export default CreditorAppBusinessInfo;
