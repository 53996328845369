import React from 'react';
import TextField from '@mui/material/TextField';
import { useForm } from 'src/helpers';
import { currencyLabels } from 'src/entities/currency';
import { labelsToOptions, loanTypeLabels } from 'src/entities/dicts';
import {
  LoanAppFormField,
  LoanAppFormLoanParams,
  useLoanAppState,
} from 'src/entities/loanApps';
import CurrencyTextfield from 'src/components/CurrencyTextfield';
import FormControl from 'src/components/FormControl';
import ResponsiveStack from 'src/components/ResponsiveStack';
import Select from 'src/components/Select';
import { StepComponentProps } from 'src/components/Stepper';
import LoanAppFormWrapper from './LoanAppFormWrapper';
import { loanParamsValidation } from './formData';

const loanCurrencyOptions = labelsToOptions(currencyLabels);
const loanTypeOptions = labelsToOptions(loanTypeLabels);

function LoanAppLoanParams({ onStepBack, ...rest }: StepComponentProps) {
  const defaultValues = useLoanAppState();
  const formProps = useForm<LoanAppFormLoanParams>({
    validation: loanParamsValidation,
    defaultValues,
  });
  const currency = formProps.watch(LoanAppFormField.LoanCurrency);

  return (
    <LoanAppFormWrapper stepId="loan-params" {...rest} {...formProps}>
      <ResponsiveStack>
        <FormControl
          data-testid="loan-amount-input"
          name={LoanAppFormField.LoanAmount}
          label="Loan Amount"
          component={CurrencyTextfield}
          currency={currency}
        />
        <FormControl
          data-testid="loan-currency-select"
          name={LoanAppFormField.LoanCurrency}
          label="Loan currency"
          options={loanCurrencyOptions}
          component={Select}
        />
      </ResponsiveStack>
      <ResponsiveStack>
        <FormControl
          data-testid="loan-type-select"
          name={LoanAppFormField.LoanType}
          label="Type of Debt Solution"
          options={loanTypeOptions}
          component={Select}
        />
        <FormControl
          data-testid="loan-term-input"
          name={LoanAppFormField.LoanTerm}
          label="Loan term (Months)"
          type="number"
          component={TextField}
        />
      </ResponsiveStack>
      <FormControl
        data-testid="additional-info-input"
        name={LoanAppFormField.AdditionalInfo}
        label="Case Summary"
        helperText="i.e. what is the purpose of the loan or brief description of the case"
        component={TextField}
        rows={3}
        multiline
      />
    </LoanAppFormWrapper>
  );
}

export default LoanAppLoanParams;
