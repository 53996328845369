import baseTheme from '../baseTheme';

const { spacing } = baseTheme;

export default {
  MuiTabs: {
    defaultProps: {
      scrollButtons: false,
      variant: 'scrollable',
    },
    styleOverrides: {
      root: {
        minHeight: spacing(5.6),
      },
    },
  },
  MuiTab: {
    defaultProps: {
      iconPosition: 'start',
    },
    styleOverrides: {
      root: {
        padding: spacing(1.6, 1.5),
        textTransform: 'none',
      },
    },
  },
};
