import React, { ForwardedRef, forwardRef, ReactNode } from 'react';
import { DocInputValue } from './types';
import { DocFormat, DocTypeCode } from '../../entities/documents';
import DataRoomDocUploadInput from './DataRoomDocUploadInput';

type Props<TMultiple extends boolean> = {
  ref?: ForwardedRef<HTMLInputElement>;
  name?: string;
  value?: DocInputValue<TMultiple>;
  label?: ReactNode;
  description?: string;
  docType: DocTypeCode;
  error?: boolean;
  helperText?: ReactNode;
  required?: boolean;
  onChange?: (value: File[] | null) => void;
  allowedFormats?: DocFormat[];
  maxItems?: number;
  maxSize?: number;
};

function DataRoomFolderUploadInput(
  props: Props<boolean>,
  ref: ForwardedRef<HTMLInputElement>,
) {
  return (
    <DataRoomDocUploadInput
      multiple
      ref={ref}
      webkitdirectory="true"
      {...props}
    />
  );
}

export default forwardRef(DataRoomFolderUploadInput);
