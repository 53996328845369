import { ReactNode } from 'react';

export enum AlertType {
  Info = 'info',
  Success = 'success',
  Error = 'error',
  Warning = 'warning',
}

export type AlertHandlerProps = {
  id?: number;
  type?: AlertType;
  text: ReactNode;
  title?: string;
};
