import React from 'react';
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import SvgIcon from '@mui/material/SvgIcon';
import TrashIcon from 'src/assets/icons/trash-icon.svg?react';

type Props = {
  title: string;
  onRemoveRow: () => void;
  canRemoveRow?: boolean;
};

function StepperFormRowHead({ title, onRemoveRow, canRemoveRow }: Props) {
  return (
    <Stack direction="row" gap={1}>
      <Divider sx={{ flex: 1, alignSelf: 'center' }}>{title}</Divider>
      <IconButton disabled={!canRemoveRow} onClick={onRemoveRow}>
        <SvgIcon component={TrashIcon} />
      </IconButton>
    </Stack>
  );
}

export default StepperFormRowHead;
