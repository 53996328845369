import React from 'react';
import { matchRoutes } from 'react-router-dom';
import { UserRole } from '../entities/dicts';
import SecureRoute from './SecureRoute';
import { routes as rootRoutes } from './routes';
import { RouteAccessStatus, RouteMetaProps, RouteProps } from './types';

export const getRoutes = (routes?: RouteProps[]): RouteProps[] =>
  routes || rootRoutes;

export const getRoute = (
  pathname: string,
  routes?: RouteProps[],
): RouteProps | undefined => {
  const routesMatch = matchRoutes(getRoutes(routes), pathname) || [];
  const { route } = routesMatch.pop() || {};
  const { children } = route || {};
  return children ? getRoute(pathname, getRoutes(children)) : route;
};

export const getRouteMeta = (
  ...args: [string, RouteProps[]?]
): RouteMetaProps => getRoute(...args)?.meta || ({} as RouteMetaProps);

export const isRouteAllowedForUserRoles = (
  allowedFor?: UserRole[],
  userRoles?: string[],
) => !allowedFor || allowedFor.find((role) => userRoles?.includes(role));

export const mapRouteElement = ({
  element,
  children,
  ...rest
}: RouteProps): RouteProps =>
  ({
    children: children?.map(mapRouteElement),
    element: <SecureRoute element={element} {...rest} />,
    ...rest,
  }) as RouteProps;

export const mapAccessFlagToBool = (allowedFor?: RouteAccessStatus) => {
  const type = allowedFor ?? RouteAccessStatus.Authorized;
  return {
    allowedForAll: type === RouteAccessStatus.All,
    allowedForAuthorized: type === RouteAccessStatus.Authorized,
    allowedForUnauthorized: type === RouteAccessStatus.Unauthorized,
  };
};
