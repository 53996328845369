import React from 'react';
import Typography from '@mui/material/Typography';
import { getRoute } from 'src/router';

type Props = {
  path: string;
};

function DashboardSectionTitle({ path }: Props) {
  const { meta } = getRoute(path) || {};

  return (
    <Typography variant="h4" marginBottom={3}>
      {meta?.title}
    </Typography>
  );
}

export default DashboardSectionTitle;
