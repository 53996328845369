import { BaseOption } from 'src/components/SelectOption';
import { SelectedOptions, SelectedValue } from './types';

export const mapOptionsToValue = <TOption extends BaseOption>(
  selectedOptions: SelectedOptions<TOption>,
): SelectedValue<TOption> => {
  const defaultValue = '';
  const result = Array.isArray(selectedOptions)
    ? selectedOptions.map(({ id }) => id)
    : selectedOptions?.id;

  return result ?? defaultValue;
};

export const mapValueToOptions = <TOption extends BaseOption>(
  selectedValue: SelectedValue<TOption>,
  options: readonly TOption[],
  multiple?: boolean,
): SelectedOptions<TOption> => {
  const defaultValue = multiple ? [] : null;
  const result = Array.isArray(selectedValue)
    ? options.filter(({ id }) => selectedValue.includes(id))
    : options.find(({ id }) => id === selectedValue);

  return result ?? defaultValue;
};
