import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Stack from '@mui/material/Stack';
import {
  LOAN_APPLICATION as ROUTE_LOAN_APP,
  LOGIN as ROUTE_LOGIN,
} from 'src/router';
import { useAppDispatch } from 'src/store';
import Stepper from 'src/components/Stepper';
import { setRedirectPath, useAuthState } from 'src/entities/auth';
import {
  LoanAppFormField,
  useGetLoanAppDraftQuery,
  useLoanAppState,
} from 'src/entities/loanApps';
import LoanAppLoanParams from './LoanAppLoanParams';
import LoanAppBusinessInfo from './LoanAppBusinessInfo';
import LoanAppContactPersons from './LoanAppContactPersons';
import LoanAppSupportingDocs from './LoanAppSupportingDocs';
import LoanAppReview from './LoanAppReview';

const steps = [
  { label: 'Loan Parameters', Component: LoanAppLoanParams },
  { label: 'General Business Information', Component: LoanAppBusinessInfo },
  { label: 'Contact Person', Component: LoanAppContactPersons },
  { label: 'Supporting Documents', Component: LoanAppSupportingDocs },
  { label: 'Review & Submit Application', Component: LoanAppReview },
];

function LoanApp() {
  const navigate = useNavigate();
  const { isAuthenticated } = useAuthState();
  const dispatch = useAppDispatch();
  const { isLoading } = useGetLoanAppDraftQuery(null, {
    skip: !isAuthenticated,
  });
  const { step } = useLoanAppState([LoanAppFormField.Step]);

  useEffect(() => {
    if (!isAuthenticated && step) {
      dispatch(setRedirectPath(ROUTE_LOAN_APP));
      navigate(ROUTE_LOGIN);
    }
  }, [isAuthenticated, step, dispatch, navigate]);

  return (
    <Stack data-testid="loan-app" maxWidth="80rem" gap={2.5}>
      <Stepper initialValue={step} items={steps} loading={isLoading} />
    </Stack>
  );
}

export default LoanApp;
