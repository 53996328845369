import React from 'react';
import Stack, { StackProps } from '@mui/material/Stack';

function ResponsiveStack({
  direction = { xs: 'column', sm: 'row' },
  gap = 2,
  ...rest
}: StackProps) {
  return <Stack direction={direction} gap={gap} {...rest} />;
}

export default ResponsiveStack;
