import React, { ReactElement } from 'react';
import Skeleton from '@mui/material/Skeleton';
import { elementsArrFromNumber } from 'src/utils';

function InfoCardSkeleton() {
  return elementsArrFromNumber(
    <Skeleton data-testid="info-card-skeleton" variant="text" width="100%" />,
    5,
  ) as ReactElement;
}

export default InfoCardSkeleton;
