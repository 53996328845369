import baseTheme from '../baseTheme';

const { spacing, typography } = baseTheme;

export default {
  MuiFormControlLabel: {
    styleOverrides: {
      root: {
        marginRight: spacing(2),
      },
    },
  },
  MuiFormHelperText: {
    styleOverrides: {
      root: {
        ...typography.body2,
      },
    },
  },
};
