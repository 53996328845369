import baseTheme from '../baseTheme';

const { spacing, typography } = baseTheme;

export default {
  styleOverrides: {
    html: {
      fontSize: '62.5%',
      height: '100%',
    },
    body: {
      ...typography.body1,
      height: '100%',
    },
    '#root': {
      height: '100%',
    },
    '*': {
      scrollMarginTop: spacing(9),
    },
  },
};
