import { useCallback, useMemo } from 'react';
import { useQueryStateValues } from 'src/hooks';
import { DEFAULT_PAGE_SIZE } from './constants';
import { PagingParams } from './types';

export const usePagingParams = (
  initialPageSize = DEFAULT_PAGE_SIZE,
  initialPage = 0,
) => {
  const [{ page, pageSize }, setParams] = useQueryStateValues<PagingParams>({
    page: initialPage,
    pageSize: initialPageSize,
  });

  const onPageChange = useCallback(
    (page: number) => setParams({ page }),
    [setParams],
  );
  const onPageSizeChange = useCallback(
    (pageSize: number) => setParams({ pageSize }),
    [setParams],
  );

  return useMemo(
    () => ({
      page: Number(page),
      pageSize: Number(pageSize),
      onPageChange,
      onPageSizeChange,
    }),
    [page, pageSize, onPageChange, onPageSizeChange],
  );
};
