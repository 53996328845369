import React, { createContext, ReactNode, useMemo } from 'react';
import { useSingleQueryStateValue } from 'src/hooks';
import SideModal from 'src/components/SideModal';
import LoanAppDetails from './LoanAppDetails';

const DEFAULT_QUERY_PARAM_KEY = 'id';

type Props = {
  children: ReactNode;
  queryParamKey?: string;
};

type ContextProps = {
  showLoanAppDetails: (id: number) => void;
};

export const LoanAppDetailsContext = createContext<ContextProps>({
  showLoanAppDetails: () => null,
});

function LoanAppsAddPartnersContextProvider({
  children,
  queryParamKey = DEFAULT_QUERY_PARAM_KEY,
}: Props) {
  const [selectedId, setSelectedId] = useSingleQueryStateValue<number | null>(
    queryParamKey,
  );
  const handleClose = () => setSelectedId(null);
  const value = useMemo(
    () => ({ showLoanAppDetails: setSelectedId }),
    [setSelectedId],
  );

  return (
    <LoanAppDetailsContext.Provider value={value}>
      {children}
      <SideModal open={!!selectedId} onClose={handleClose}>
        {selectedId && <LoanAppDetails id={selectedId} />}
      </SideModal>
    </LoanAppDetailsContext.Provider>
  );
}

export default LoanAppsAddPartnersContextProvider;
