export enum CreditorAppFormField {
  Step = 'step',
  FullCompanyName = 'fullCompanyName',
  Description = 'description',
  Website = 'website',
  AddressLine1 = 'addressLine1',
  AddressLine2 = 'addressLine2',
  City = 'city',
  StateProvince = 'stateProvince',
  Country = 'country',
  PostalCode = 'postalCode',
  AddressIsSame = 'addressIsSame',
  PrimaryAddressLine1 = 'primaryAddressLine1',
  PrimaryAddressLine2 = 'primaryAddressLine2',
  PrimaryCity = 'primaryCity',
  PrimaryCountry = 'primaryCountry',
  PrimaryPostalCode = 'primaryPostalCode',
  PrimaryStateProvince = 'primaryStateProvince',
  ContactPersons = 'contactPersons',
  Name = 'name',
  Surname = 'surname',
  PersonRelType = 'personRelType',
  PhoneNumber = 'phoneNumber',
  Email = 'email',
}

export type CreditorAppFormBusinessInfo = {
  fullCompanyName?: string;
  description?: string;
  website?: string;
  addressLine1?: string;
  addressLine2?: string;
  city?: string;
  stateProvince?: string;
  country?: string;
  postalCode?: string;
  addressIsSame?: boolean;
  primaryAddressLine1?: string;
  primaryAddressLine2?: string;
  primaryCity?: string;
  primaryCountry?: string;
  primaryPostalCode?: string;
  primaryStateProvince?: string;
};

export type CreditorAppFormContactPerson = {
  name?: string;
  surname?: string;
  personRelType?: string;
  phoneNumber?: string;
  email?: string;
};
export type CreditorAppFormContactPersons = {
  contactPersons?: CreditorAppFormContactPerson[];
};

export type CreditorAppFormParams = CreditorAppFormBusinessInfo &
  CreditorAppFormContactPersons;

export type CreditorAppState = {
  lastModifiedDate?: string;
  step?: number;
} & Partial<CreditorAppFormParams>;
