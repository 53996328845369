import baseTheme from '../baseTheme';

const { spacing, breakpoints } = baseTheme;

export default {
  defaultProps: {
    maxWidth: 'lg',
  },
  styleOverrides: {
    root: {
      marginLeft: 0,
      [breakpoints.up('sm')]: {
        paddingInline: spacing(3),
      },
      [breakpoints.up('md')]: {
        paddingInline: spacing(4),
      },
    },
  },
};
