import React, { forwardRef, ComponentType, Ref } from 'react';
import MenuItem, { MenuItemProps } from '@mui/material/MenuItem';
import { BaseOption, DefaultOption } from './types';

type Props<T> = MenuItemProps & {
  renderer?: ComponentType<T>;
  option: T;
};

function SelectOption<T extends BaseOption = DefaultOption>(
  { option, renderer: Renderer, ...rest }: Props<T>,
  ref: Ref<HTMLLIElement>,
) {
  const { id, label } = option as DefaultOption;
  return (
    <MenuItem data-testid={`select-option-${id}`} ref={ref} {...rest}>
      {Renderer ? <Renderer {...option} /> : label}
    </MenuItem>
  );
}

export default forwardRef(SelectOption) as typeof SelectOption;
