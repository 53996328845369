import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import TextField from '@mui/material/TextField';
import { useForm } from 'src/helpers';
import * as ROUTES from 'src/router';
import {
  AuthFormField,
  AuthLoginRequest,
  useLoginMutation,
} from 'src/entities/auth';
import FormControl from 'src/components/FormControl';
import LoadingButton from 'src/components/LoadingButton';
import MaskedTextfield from 'src/components/MaskedTextfield';
import AuthPageWrapper from './AuthPageWrapper';
import { loginValidation } from './formData';

function Login() {
  const [login, { isLoading }] = useLoginMutation();
  const { handleSubmit, control } = useForm<AuthLoginRequest>({
    validation: loginValidation,
  });

  return (
    <AuthPageWrapper onSubmit={handleSubmit(login)}>
      <FormControl
        data-testid="username-input"
        name={AuthFormField.Username}
        label="Username"
        control={control}
        component={TextField}
      />
      <FormControl
        data-testid="password-input"
        name={AuthFormField.Password}
        label="Password"
        control={control}
        component={MaskedTextfield}
      />
      <Link
        data-testid="forgot-password-link"
        component={RouterLink}
        to={ROUTES.FORGOT_PASSWORD}
        alignSelf="flex-start"
      >
        Forgot password?
      </Link>
      <LoadingButton data-testid="login-btn" type="submit" loading={isLoading}>
        Sign in
      </LoadingButton>
      <Button
        data-testid="create-acc-link"
        component={RouterLink}
        to={ROUTES.REGISTER}
        color="secondary"
      >
        Create account
      </Button>
    </AuthPageWrapper>
  );
}

export default Login;
