import React, { ForwardedRef, forwardRef } from 'react';
import Box, { BoxProps } from '@mui/material/Box';
import { useTabContext } from './TabContext';

type Props = BoxProps & {
  value: number;
};

function TabPanel(
  { children, value, ...rest }: Props,
  ref: ForwardedRef<HTMLButtonElement>,
) {
  const context = useTabContext();

  return (
    <Box
      aria-labelledby={value.toString()}
      hidden={value !== context.value}
      ref={ref}
      role="tabpanel"
      {...rest}
    >
      {value === context.value && children}
    </Box>
  );
}

export default forwardRef(TabPanel);
