import React, { ReactNode } from 'react';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import { TabContext } from 'src/components/Tabs';

type Props = {
  value: number;
  children: ReactNode;
  tabsPanel: ReactNode;
};

const tabPanelStyle = {
  overflowx: 'hidden',
  bgcolor: 'background.default',
  flex: 1,
  mb: -2.5,
  mx: -2,
  p: 2,
};

function SideModalTabs({ children, tabsPanel, value }: Props) {
  return (
    <TabContext value={value}>
      {tabsPanel}
      <Divider sx={{ mx: -2, px: 2 }} />
      <Stack {...tabPanelStyle}>{children}</Stack>
    </TabContext>
  );
}

export default SideModalTabs;
