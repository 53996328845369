import {
  DependencyList,
  Dispatch,
  EffectCallback,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from 'react';

export const useIsFirstRender = (): boolean => {
  const isFirst = useRef(true);
  if (isFirst.current) {
    isFirst.current = false;
    return true;
  }
  return isFirst.current;
};

export const useUpdateEffect = (
  effect: EffectCallback,
  deps?: DependencyList,
) => {
  const isFirst = useIsFirstRender();
  useEffect(() => {
    if (!isFirst) {
      return effect();
    }
    return undefined;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);
};

export const useDelayedEffect = (
  effect: () => void,
  delay: number,
  deps?: DependencyList,
) =>
  useEffect(() => {
    setTimeout(effect, delay);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);

export const useSyncState = <T>(
  initialState: T,
  deps?: DependencyList,
): [T, Dispatch<SetStateAction<T>>] => {
  const [state, setState] = useState<T>(initialState);

  useEffect(
    () => {
      setState(initialState);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    deps ?? [initialState],
  );

  return [state, setState];
};
