import React from 'react';
import { useMatch } from 'react-router-dom';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import SvgIcon from '@mui/material/SvgIcon';
import Typography from '@mui/material/Typography';
import MenuIcon from 'src/assets/icons/menu-icon.svg?react';
import { useLayoutBreakpoints } from 'src/hooks';
import { DASHBOARD as ROUTE_DASHBOARD, useMatchRouteMeta } from 'src/router';
import { useAuthState } from 'src/entities/auth';
import HeaderMenu from './HeaderMenu';
import { useSidebarContext } from '../Sidebar';

function Header() {
  const { isMobile } = useLayoutBreakpoints();
  const { user } = useAuthState();
  const { toggleOpen } = useSidebarContext();
  const { title } = useMatchRouteMeta();
  const isDashboard = !!useMatch(ROUTE_DASHBOARD);

  return (
    <AppBar data-testid="header">
      {isMobile && (
        <IconButton size="small" onClick={toggleOpen}>
          <SvgIcon fontSize="large" component={MenuIcon} />
        </IconButton>
      )}
      <Typography variant={isMobile ? 'h6' : 'h4'}>
        {isDashboard ? `Welcome, ${user?.firstName}` : title}
      </Typography>
      <Box minWidth="3rem">
        {user && (
          <HeaderMenu
            email={user.email}
            userName={`${user.firstName} ${user.lastName}`}
          />
        )}
      </Box>
    </AppBar>
  );
}

export default Header;
