import * as Yup from 'yup';
import { getValidationArrSchema } from 'src/helpers';
import { CreditorAppFormField } from 'src/entities/creditorApp';

const whenAddressIsNotSame = (then: Yup.AnySchema) =>
  Yup.string().when(CreditorAppFormField.AddressIsSame, { is: false, then });
export const businessInfoValidation = {
  [CreditorAppFormField.FullCompanyName]: Yup.string().required(
    'Please enter company name',
  ),
  [CreditorAppFormField.Website]: Yup.string().max(
    255,
    'Website should have max ${max} symbols',
  ),
  [CreditorAppFormField.Description]: Yup.string().max(
    400,
    'Description should have max ${max} symbols',
  ),
  [CreditorAppFormField.AddressLine1]: Yup.string()
    .required('Please enter your address')
    .max(100, 'Address Line 1 should have max ${max} symbols'),
  [CreditorAppFormField.AddressLine2]: Yup.string().max(
    100,
    'Address Line 2 should have max ${max} symbols',
  ),
  [CreditorAppFormField.City]: Yup.string()
    .required('Please enter city')
    .max(100, 'City should have max ${max} symbols'),
  [CreditorAppFormField.StateProvince]: Yup.string().max(
    100,
    'Province should have max ${max} symbols',
  ),
  [CreditorAppFormField.Country]: Yup.string().required(
    'Please select country',
  ),
  [CreditorAppFormField.PostalCode]: Yup.string()
    .required('Please enter postal code')
    .max(10, 'Postal code should have max ${max} digits'),
  [CreditorAppFormField.AddressIsSame]: Yup.boolean(),
  [CreditorAppFormField.PrimaryAddressLine1]: whenAddressIsNotSame(
    Yup.string()
      .required('Please enter your address')
      .max(100, 'Address Line 1 should have max ${max} symbols'),
  ),
  [CreditorAppFormField.PrimaryAddressLine2]: Yup.string().max(
    100,
    'Address Line 2 should have max ${max} symbols',
  ),
  [CreditorAppFormField.PrimaryCity]: whenAddressIsNotSame(
    Yup.string()
      .required('Please enter city')
      .max(100, 'City should have max ${max} symbols'),
  ),
  [CreditorAppFormField.PrimaryStateProvince]: Yup.string().max(
    100,
    'State/Province should have max ${max} symbols',
  ),
  [CreditorAppFormField.PrimaryCountry]: whenAddressIsNotSame(
    Yup.string().required('Please select country'),
  ),
  [CreditorAppFormField.PrimaryPostalCode]: whenAddressIsNotSame(
    Yup.string()
      .required('Please enter postal code')
      .max(10, 'Postal code should have max 10 digits'),
  ),
};

export const contactPersonValidation = {
  [CreditorAppFormField.Name]: Yup.string()
    .required('Please enter first name')
    .max(100, 'First name should have max ${max} symbols'),
  [CreditorAppFormField.Surname]: Yup.string()
    .required('Please enter last name')
    .max(100, 'Last name should have max ${max} symbols'),
  [CreditorAppFormField.PersonRelType]: Yup.string()
    .required('Please enter relationship to the company')
    .max(100, 'Relationship description should have max ${max} symbols'),
  [CreditorAppFormField.PhoneNumber]: Yup.string()
    .required('Please enter phone number')
    .max(100, 'Phone number should have max ${max} symbols'),
  [CreditorAppFormField.Email]: Yup.string()
    .required('Please enter email')
    .email('Please enter a valid email')
    .max(100, 'Email should have max ${max} symbols'),
};
export const contactPersonsValidation = {
  [CreditorAppFormField.ContactPersons]: getValidationArrSchema(
    contactPersonValidation,
  ),
};
