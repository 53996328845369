import React, { ReactNode } from 'react';
import { TabList } from 'src/components/Tabs';

type Props = {
  onChange: (value: number) => void;
  children: ReactNode;
};

function SideModalTabsList({ children, onChange }: Props) {
  return (
    <TabList
      onChange={(event, value) => onChange(value)}
      sx={{ pt: 1, '.MuiTab-root': { minHeight: 0 } }}
    >
      {children}
    </TabList>
  );
}

export default SideModalTabsList;
