import React from 'react';
import { AlertHandlerProps } from './types';

type Props = {
  showAlert: (props: AlertHandlerProps) => void;
};

export default React.createContext<Props>({
  showAlert: () => ({}),
});
