import { DocFileMeta } from 'src/entities/documents';
import { DocInputValue } from './types';

export const getDeleteHandlerValue = <TMultiple extends boolean>(
  currItems: DocFileMeta[],
  removedId?: number,
  multiple?: TMultiple,
) =>
  multiple
    ? (currItems
        .filter(({ id }) => id !== removedId)
        .map(({ id }) => id) as DocInputValue<TMultiple>)
    : null;

export const getChangeHandlerValue = <TMultiple extends boolean>(
  currItems: DocFileMeta[],
  uploadedItems: DocFileMeta[],
  multiple?: TMultiple,
) => {
  const { id } = uploadedItems[0];
  return (
    multiple ? [...currItems, ...uploadedItems].map(({ id }) => id) : id
  ) as DocInputValue<TMultiple>;
};
