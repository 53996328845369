import baseTheme from '../baseTheme';

const { breakpoints, palette, spacing, typography } = baseTheme;

export default {
  MuiPopover: {
    styleOverrides: {
      root: {
        '&.MuiMenu-root': {
          width: '100%',
        },
      },
    },
  },
  MuiMenu: {
    styleOverrides: {
      list: {
        padding: spacing(0.5, 0),
      },
    },
  },
  MuiMenuItem: {
    styleOverrides: {
      root: {
        padding: spacing(1.2, 1.8),
      },
    },
  },
  MuiListItemAvatar: {
    styleOverrides: {
      root: {
        minWidth: '3.2rem',
      },
    },
  },
  MuiListItem: {
    styleOverrides: {
      root: {
        gap: spacing(1),
      },
    },
  },
  MuiListItemButton: {
    styleOverrides: {
      root: {
        borderRadius: spacing(1),
        color: palette.text.secondary,
        gap: spacing(1),
        minHeight: spacing(4.5),
        ...typography.subtitle2,
        '&.Mui-selected': {
          color: palette.primary.dark,
          ...typography.subtitle1,
        },
        [breakpoints.down('lg')]: {
          paddingInline: spacing(1.3),
        },
      },
    },
  },
  MuiListItemIcon: {
    styleOverrides: {
      root: {
        color: 'inherit',
        minWidth: spacing(2),
      },
    },
  },
  MuiListItemText: {
    defaultProps: {
      primaryTypographyProps: {
        variant: 'inherit',
      },
      secondaryTypographyProps: {
        variant: 'helper',
      },
    },
    styleOverrides: {
      primary: {
        textTransform: 'none',
      },
    },
  },
};
