import React, { ReactNode } from 'react';
import Stack from '@mui/material/Stack';

type Props = {
  children?: ReactNode;
};

const style = {
  overflowX: 'auto',
  flexDirection: 'row',
  gap: 2.5,
  pb: 3,
  px: { xs: 2, sm: 3 },
  mx: { xs: -2, sm: -3 },
  '&::-webkit-scrollbar': {
    display: 'none',
  },
};

function InfoCardsContainer({ children }: Props) {
  return (
    <Stack data-testid="info-card-container" sx={style}>
      {children}
    </Stack>
  );
}

export default InfoCardsContainer;
