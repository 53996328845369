import React, { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import Link from '@mui/material/Link';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import { TERMS_AND_COND_URL } from 'src/constants';
import { ROOT as ROUTE_ROOT } from 'src/router';
import { useAppDispatch } from 'src/store';
import { getRandomInt } from 'src/utils';
import { Currency } from 'src/entities/currency';
import { loanTypeLabels } from 'src/entities/dicts';
import {
  LoanAppFormField,
  LoanAppState,
  resetLoanAppAction,
  saveLoanAppAction,
  useLoanAppState,
  useSendLoanAppMutation,
} from 'src/entities/loanApps';
import { AlertType, useAlert } from 'src/components/AlertsProvider';
import FieldsSet, {
  createCurrFormatter,
  createLabelFormatter,
  FieldsSetCountryFormatter,
  FieldsSetDocFileFormatter,
} from 'src/components/FieldsSet';
import { StepComponentProps } from 'src/components/Stepper';
import { StepperFormReview } from 'src/components/StepperForm';

const getLoanParamsFields = (currency?: Currency) => [
  {
    id: LoanAppFormField.LoanAmount,
    label: 'Loan amount',
    formatter: createCurrFormatter(currency),
  },
  {
    id: LoanAppFormField.LoanType,
    label: 'Type of Debt Solution',
    formatter: createLabelFormatter(loanTypeLabels),
  },
  {
    id: LoanAppFormField.LoanTerm,
    label: 'Loan term',
  },
  {
    id: LoanAppFormField.AdditionalInfo,
    label: 'Additional information',
  },
];

const businessInfoFields = [
  {
    id: LoanAppFormField.FullCompanyName,
    label: 'Full Company or Business Name',
  },
  {
    id: LoanAppFormField.RegistrationNumber,
    label: 'Registration Number',
  },
  {
    id: LoanAppFormField.Website,
    label: 'Website',
  },
  {
    id: LoanAppFormField.Description,
    label: 'Short description of your business',
  },
];

const addressFields = [
  {
    id: LoanAppFormField.AddressLine1,
    label: 'Address Line 1',
  },
  {
    id: LoanAppFormField.AddressLine2,
    label: 'Address Line 2',
  },
  {
    id: LoanAppFormField.City,
    label: 'City',
  },
  {
    id: LoanAppFormField.StateProvince,
    label: 'State/Province (Optional)',
  },
  {
    id: LoanAppFormField.Country,
    label: 'Country',
    Component: FieldsSetCountryFormatter,
  },
  {
    id: LoanAppFormField.PostalCode,
    label: 'Zip/Postal Code',
  },
];
const primaryAddressFields = [
  {
    id: LoanAppFormField.PrimaryAddressLine1,
    label: 'Address Line 1',
  },
  {
    id: LoanAppFormField.PrimaryAddressLine2,
    label: 'Address Line 2',
  },
  {
    id: LoanAppFormField.PrimaryCity,
    label: 'City',
  },
  {
    id: LoanAppFormField.PrimaryStateProvince,
    label: 'State/Province (Optional)',
  },
  {
    id: LoanAppFormField.PrimaryCountry,
    label: 'Country',
    Component: FieldsSetCountryFormatter,
  },
  {
    id: LoanAppFormField.PrimaryPostalCode,
    label: 'Zip/Postal Code',
  },
];

const contactPersonFields = [
  {
    id: LoanAppFormField.Name,
    label: 'First Name',
  },
  {
    id: LoanAppFormField.Surname,
    label: 'Last Name',
  },
  {
    id: LoanAppFormField.PersonRelType,
    label: 'Relationship to the Company',
  },
  {
    id: LoanAppFormField.PhoneNumber,
    label: 'Contact Telephone Number',
  },
  {
    id: LoanAppFormField.Email,
    label: 'Email Address for Correspondence',
  },
];

const supportingDocsFields = [
  {
    id: LoanAppFormField.Docs,
    label: 'Supporting documents',
    Component: FieldsSetDocFileFormatter,
  },
];

function LoanAppReview(props: StepComponentProps) {
  const { showAlert } = useAlert();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const loanAppState = useLoanAppState();
  const {
    partyType,
    loanCurrency,
    addressIsSame,
    contactPersons,
    ...formData
  } = loanAppState;
  const [sendLoanApp, { isLoading }] = useSendLoanAppMutation();

  const handleSubmit = () =>
    sendLoanApp(loanAppState)
      .unwrap()
      .then(() => {
        showAlert({
          type: AlertType.Success,
          text: 'Your application has been successfully submitted, our team will review it and get back to you as soon as possible.',
        });
        dispatch(resetLoanAppAction());
        navigate(ROUTE_ROOT);
      });
  const handleUpdate = useCallback(
    (values: LoanAppState) => {
      dispatch(saveLoanAppAction(values));
    },
    [dispatch],
  );

  return (
    <StepperFormReview
      {...props}
      loading={isLoading}
      onStepNext={handleSubmit}
      onUpdate={handleUpdate}
    >
      <Divider>Loan Parameters</Divider>
      <FieldsSet
        fields={getLoanParamsFields(loanCurrency)}
        data={formData}
        zebra
      />
      <Divider>General Business Information</Divider>
      <FieldsSet
        data-testid="review-business-info"
        fields={businessInfoFields}
        data={formData}
        zebra
      />
      <Typography variant="overline">Registered Office Address</Typography>
      <FieldsSet
        data-testid="review-office-address"
        fields={addressFields}
        data={formData}
        zebra
      />
      {!addressIsSame && (
        <>
          <Typography variant="overline">Primary Business Address</Typography>
          <FieldsSet
            data-testid="review-business-address"
            fields={primaryAddressFields}
            data={formData}
            zebra
          />
        </>
      )}
      <Divider>Contact Person</Divider>
      {contactPersons?.map((item) => (
        <FieldsSet
          data-testid="review-contact-persons"
          key={getRandomInt()}
          fields={contactPersonFields}
          data={item}
          pb={1}
          zebra
        />
      ))}
      <Divider>Supporting Documents</Divider>
      <FieldsSet
        data-testid="review-supporting-docs"
        fields={supportingDocsFields}
        data={formData}
        zebra
      />
      <Typography variant="subtitle1">After applying</Typography>
      <Typography>
        By clicking the submit button below, I hereby agree to and accept the
        following&nbsp;
        <Link href={TERMS_AND_COND_URL}>Terms and Conditions</Link>
      </Typography>
    </StepperFormReview>
  );
}

export default LoanAppReview;
