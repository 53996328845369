import baseTheme from '../baseTheme';

const { breakpoints, palette, spacing } = baseTheme;

export default {
  defaultProps: {
    color: 'inherit',
    position: 'sticky',
  },
  styleOverrides: {
    root: {
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      borderRadius: 0,
      backgroundColor: palette.background.paper,
      gap: spacing(1),
      minHeight: spacing(5.6),
      marginBottom: spacing(2),
      padding: spacing(1.2, 2),
      [breakpoints.up('sm')]: {
        backgroundColor: palette.background.default,
        boxShadow: 'none',
        margin: 0,
        minHeight: spacing(8),
        padding: spacing(1.6, 3),
      },
      [breakpoints.up('md')]: {
        padding: spacing(1.6, 4),
      },
    },
  },
};
