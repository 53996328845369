import React from 'react';
import AppRouter from 'src/router';
import AppProviders from './components/AppProviders';
import AppLayout from './components/AppLayout';

function App() {
  return (
    <AppProviders>
      <AppLayout>
        <AppRouter />
      </AppLayout>
    </AppProviders>
  );
}

export default App;
