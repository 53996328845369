import { ComponentType, ReactNode } from 'react';
import { RouteObject } from 'react-router-dom';
import { UserRole } from '../entities/dicts';

export enum RouteAccessStatus {
  All,
  Authorized,
  Unauthorized,
}

export type RouteMetaProps = {
  title: ReactNode;
  icon?: ComponentType;
  activeIcon?: ComponentType;
  navLink?: boolean;
};

export type RouteProps = RouteObject & {
  children?: RouteProps[];
  allowedForStatus?: RouteAccessStatus;
  allowedForRoles?: UserRole[];
  requireState?: boolean;
  meta?: RouteMetaProps;
};
