import * as Yup from 'yup';
import {
  DataRoomItemFields,
  DataRoomUploadFilesFields,
} from 'src/entities/dataRoom/types';

export const manageFolderValidation = {
  [DataRoomItemFields.Name]: Yup.string().required(
    'Please enter a folder name',
  ),
};

export const uploadFilesValidation = {
  [DataRoomUploadFilesFields.DocumentFiles]: Yup.mixed()
    .required('required')
    .test('files', 'Please attach files', (value) => {
      if (!value || !(value instanceof FileList) || value.length === 0) {
        return false;
      }
      return true;
    }),
};
