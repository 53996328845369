import { ALERT_TIMEOUT } from '../constants';
import baseTheme from '../baseTheme';

const { breakpoints, spacing } = baseTheme;

export default {
  defaultProps: {
    autoHideDuration: ALERT_TIMEOUT,
  },
  styleOverrides: {
    root: {
      position: 'static',
      [breakpoints.up('sm')]: {
        minWidth: spacing(30),
      },
    },
  },
};
