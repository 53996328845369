import React from 'react';
import { useNavigate } from 'react-router-dom';
import TextField from '@mui/material/TextField';
import { useForm } from 'src/helpers';
import { SET_NEW_PASSWORD as ROUTE_SET_NEW_PASSWORD } from 'src/router';
import {
  AuthFormField,
  AuthForgotPassReq,
  useForgotPasswordMutation,
} from 'src/entities/auth';
import FormControl from 'src/components/FormControl';
import LoadingButton from 'src/components/LoadingButton';
import AuthPageWrapper from './AuthPageWrapper';
import { forgotPasswordValidation } from './formData';

function ForgotPassword() {
  const navigate = useNavigate();
  const [sendEmail, { isLoading }] = useForgotPasswordMutation();
  const { control, handleSubmit } = useForm<AuthForgotPassReq>({
    validation: forgotPasswordValidation,
  });

  const onSubmit = (values: AuthForgotPassReq) =>
    sendEmail(values)
      .unwrap()
      .then(() => navigate(ROUTE_SET_NEW_PASSWORD, { state: values.email }));

  return (
    <AuthPageWrapper
      description="Enter your Email below and we will send a message to reset your password"
      onSubmit={handleSubmit(onSubmit)}
    >
      <FormControl
        data-testid="email-input"
        name={AuthFormField.Email}
        label="Email"
        control={control}
        component={TextField}
      />
      <LoadingButton
        data-testid="reset-password-btn"
        loading={isLoading}
        type="submit"
      >
        Reset my password
      </LoadingButton>
    </AuthPageWrapper>
  );
}

export default ForgotPassword;
