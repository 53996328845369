export default {
  defaultProps: {
    underline: 'hover',
  },
  styleOverrides: {
    root: {
      cursor: 'pointer',
    },
  },
};
