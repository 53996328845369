import baseTheme from '../baseTheme';
import { STEPPER_COLLAPSE_DURATION } from '../constants';

const { breakpoints, palette, spacing, typography } = baseTheme;

export default {
  MuiStepper: {
    defaultProps: {
      orientation: 'vertical',
    },
    styleOverrides: {
      root: {
        paddingBottom: spacing(2),
      },
    },
  },
  MuiStep: {
    styleOverrides: {
      root: {
        paddingLeft: spacing(2),
        [breakpoints.down('sm')]: {
          marginInline: spacing(-2),
        },
        [breakpoints.up('sm')]: {
          borderRadius: spacing(1.2),
        },
        '&.Mui-active': {
          backgroundColor: palette.background.paper,
          padding: spacing(2),
          '.MuiStepButton-root': {
            marginBottom: spacing(1),
          },
        },
      },
    },
  },
  MuiStepButton: {
    styleOverrides: {
      root: {
        borderRadius: 8,
        padding: spacing(1),
        width: 'calc(100% - 2rem)',
      },
    },
  },
  MuiStepIcon: {
    styleOverrides: {
      root: {
        height: spacing(3.2),
        width: spacing(3.2),
        color: palette.secondary.main,
        ':not(.Mui-active) text': {
          fill: palette.text.secondary,
        },
      },
      text: {
        ...typography.subtitle1,
      },
    },
  },
  MuiStepLabel: {
    styleOverrides: {
      root: {
        padding: 0,
      },
      label: {
        ...typography.h6,
        '&.Mui-active, &.Mui-completed': {
          fontWeight: 600,
        },
      },
    },
  },
  MuiStepContent: {
    defaultProps: {
      transitionDuration: STEPPER_COLLAPSE_DURATION,
    },
    styleOverrides: {
      root: {
        border: 0,
        paddingInline: 0,
        marginInline: 0,
        [breakpoints.up('sm')]: {
          marginLeft: spacing(3.8),
        },
      },
    },
  },
  MuiStepConnector: {
    styleOverrides: {
      root: {
        marginLeft: spacing(1.5),
        marginBottom: spacing(0.8),
        marginTop: spacing(0.8),
        [breakpoints.up('sm')]: {
          marginLeft: spacing(3.5),
        },
      },
      line: {
        minHeight: spacing(1.5),
      },
    },
  },
};
