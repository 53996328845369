import { isBoolean, isEmpty } from './common';

export const getPercentFromNum = (value: number) =>
  `${(value * 100).toFixed(1)}%`;

export const getRandomInt = (digits = 10000) =>
  Math.floor(Math.random() * digits);

export const isValidNumber = <T>(value?: T): value is T => {
  const numberValue = !isEmpty(value) && !isBoolean(value) && Number(value);
  return !!numberValue || numberValue === 0;
};

export const transformNan = <T>(value: T) =>
  Number.isNaN(value) ? undefined : value;
