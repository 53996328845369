import baseTheme from '../baseTheme';

const { spacing } = baseTheme;

export default {
  MuiCard: {
    styleOverrides: {
      root: {
        borderRadius: spacing(1.2),
        display: 'flex',
        flexDirection: 'column',
      },
    },
  },
  MuiCardContent: {
    styleOverrides: {
      root: {
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        justifyContent: 'space-between',
        padding: spacing(2),
        width: '100%',
      },
    },
  },
  MuiCardMedia: {
    styleOverrides: {
      root: {
        width: 'auto',
        objectFit: 'none',
      },
    },
  },
  MuiCardHeader: {
    defaultProps: {
      titleTypographyProps: {
        variant: 'h6',
      },
    },
    styleOverrides: {
      root: {
        padding: spacing(2),
        paddingBottom: spacing(0),
      },
    },
  },
};
