import React from 'react';
import Button from '@mui/material/Button';
import SvgIcon from '@mui/material/SvgIcon';
import UserPlusIcon from 'src/assets/icons/user-plus-icon.svg?react';

type Props = {
  label: string;
  onClick: () => void;
  disabled?: boolean;
  showIcon?: boolean;
};

const style = {
  alignSelf: 'flex-start',
};

function LoanAppFormRowAddBtn({ label, showIcon = true, ...rest }: Props) {
  return (
    <Button
      variant="text"
      startIcon={showIcon && <SvgIcon component={UserPlusIcon} />}
      sx={style}
      {...rest}
    >
      {label}
    </Button>
  );
}

export default LoanAppFormRowAddBtn;
