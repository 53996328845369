import React, { ChangeEvent, useState } from 'react';
import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import SvgIcon from '@mui/material/SvgIcon';
import Typography from '@mui/material/Typography';
import LockIcon from 'src/assets/icons/lock-icon.svg?react';
import { generatePath } from 'src/utils';
import { AlertType, useAlert } from 'src/components/AlertsProvider';
import FileInput from 'src/components/FileInput';
import FilesInputBaseItem from 'src/components/FileInput/FilesInputBaseItem';
import LoadingButton from 'src/components/LoadingButton';
import {
  useUploadNdaMutation,
  FUNDING_OPPS_DOWNLOAD_NDA,
} from 'src/entities/fundingOpps';

type Props = {
  id: number;
};

function FundingOppDetailsUploadNda({ id }: Props) {
  const { showAlert } = useAlert();
  const [file, setFile] = useState<File>();
  const [upload, { isLoading }] = useUploadNdaMutation();

  const handleUpload = () =>
    upload({ id, file }).then(() =>
      showAlert({
        type: AlertType.Success,
        text: 'NDA file has been upload successfully',
      }),
    );
  const handleChange = ({ target: { files } }: ChangeEvent<HTMLInputElement>) =>
    setFile(files?.[0]);
  const handleDelete = () => setFile(undefined);

  return (
    <Stack component={Paper} gap={1} p={2}>
      <Typography variant="subtitle1">
        <SvgIcon component={LockIcon} sx={{ mr: 1 }} />
        Sign and upload an NDA to view all Locked details
      </Typography>
      <Typography>
        To access complete information, it is necessary to sign and upload an
        NDA. You can&nbsp;
        <Link href={generatePath(FUNDING_OPPS_DOWNLOAD_NDA, { id })}>
          download this NDA
        </Link>
        &nbsp;or upload your own. After the upload, our manager will review the
        document and release the loan information.
      </Typography>
      <Stack direction="row" alignItems="center" gap={2}>
        <LoadingButton
          onClick={handleUpload}
          disabled={isLoading || !file}
          loading={isLoading}
          sx={{ width: '30%' }}
          size="small"
        >
          Upload
        </LoadingButton>
        {!isLoading && (
          <FileInput
            maxSize={10}
            value={file}
            canUpload={!file}
            onChange={handleChange}
            onDelete={handleDelete}
            listItemComponent={FilesInputBaseItem}
          />
        )}
      </Stack>
    </Stack>
  );
}

export default FundingOppDetailsUploadNda;
