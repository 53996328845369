import React, { ComponentType, ReactNode } from 'react';
import ListItemButton, {
  ListItemButtonProps,
} from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import SvgIcon from '@mui/material/SvgIcon';
import Tooltip from 'src/components/Tooltip';
import { useSidebarContext } from './SidebarContext';

type Props<T> = Omit<ListItemButtonProps, 'title'> & {
  component?: ComponentType<T>;
  icon?: ComponentType;
  to?: string;
  title?: ReactNode;
  subtitle?: ReactNode;
};

function SidebarNavLink<T = null>({
  disabled,
  icon,
  title,
  subtitle,
  ...rest
}: Props<T>) {
  const { fullWidth } = useSidebarContext();

  return (
    <Tooltip disabled={fullWidth} title={title} placement="right">
      <ListItemButton disabled={disabled} {...rest}>
        <ListItemIcon>{icon && <SvgIcon component={icon} />}</ListItemIcon>
        {fullWidth && <ListItemText primary={title} secondary={subtitle} />}
      </ListItemButton>
    </Tooltip>
  );
}

export default SidebarNavLink;
