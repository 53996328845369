import * as Yup from 'yup';
import { AuthFormField } from 'src/entities/auth';

const emailValidation = Yup.string()
  .required('Please enter an email')
  .email('Please enter a valid email address')
  .max(100, 'Email should have max ${max} symbols');

const passwordValidation = Yup.string()
  .required('Password enter a password')
  .min(8, 'Password should be minimum ${min} characters')
  .matches(
    /(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*\W)/,
    'Password should contain at least one number, lowercase, uppercase and special characters',
  );

export const loginValidation = {
  [AuthFormField.Username]: Yup.string().required('Please enter a username'),
  [AuthFormField.Password]: Yup.string().required('Please enter a password'),
};

export const forgotPasswordValidation = {
  [AuthFormField.Email]: emailValidation,
};

export const setNewPasswordValidation = {
  [AuthFormField.VerificationCode]: Yup.string().required(
    'Please enter a verification code',
  ),
  [AuthFormField.NewPassword]: passwordValidation,
  [AuthFormField.ConfirmPassword]: Yup.string()
    .required('Password confirm password')
    .oneOf([Yup.ref(AuthFormField.NewPassword)], 'Passwords do not match'),
};

export const registerValidation = {
  [AuthFormField.Email]: emailValidation,
  [AuthFormField.FirstName]: Yup.string()
    .required('Please enter your first name')
    .max(100, 'First name should have max ${max} symbols'),
  [AuthFormField.LastName]: Yup.string()
    .required('Please enter your last name')
    .max(100, 'Last name should have max ${max} symbols'),
  [AuthFormField.Password]: passwordValidation,
};

export const confirmAccountValidation = {
  [AuthFormField.ConfirmationCode]: Yup.string().required(
    'Please enter a confirmation code',
  ),
};
