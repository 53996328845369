export const typography = {
  fontFamily: 'Poppins',
  fontSize: 13,
  htmlFontSize: 10,
  body1: {
    fontSize: '1.3rem',
    fontWeight: 400,
  },
  body2: {
    fontSize: '1.1rem',
    fontWeight: 500,
    letterSpacing: 0.02,
  },
  button: {
    fontSize: '1.3rem',
    fontWeight: 600,
  },
  caption: {
    fontSize: '1.3rem',
    fontWeight: 600,
    lineHeight: 1.2,
  },
  input: {
    fontSize: '1.4rem',
    fontWeight: 400,
  },
  helper: {
    fontSize: '1.1rem',
    fontWeight: 400,
    letterSpacing: 0.02,
    lineHeight: 1.1,
  },
  h1: {
    fontFamily: 'Frank Ruhl Libre',
    fontWeight: 400,
    fontSize: '8.8rem',
  },
  h2: {
    fontFamily: 'Frank Ruhl Libre',
    fontWeight: 500,
    fontSize: '5.6rem',
  },
  h3: {
    fontFamily: 'Frank Ruhl Libre',
    fontSize: '4.8rem',
  },
  h4: {
    fontSize: '2.4rem',
    fontWeight: 600,
    lineHeight: 1,
  },
  h5: {
    fontSize: '2.4rem',
    fontWeight: 400,
    lineHeight: 1.3,
  },
  h6: {
    fontSize: '2rem',
    fontWeight: 600,
    lineHeight: 1.3,
  },
  h7: {
    fontSize: '1.8rem',
    fontWeight: 600,
    lineHeight: 1.2,
  },
  h8: {
    fontSize: '1.8rem',
    fontWeight: 400,
    lineHeight: 1.7,
  },
  overline: {
    fontSize: '1.1rem',
    fontWeight: 600,
    lineHeight: 1.2,
  },
  subtitle1: {
    fontSize: '1.4rem',
    fontWeight: 600,
    lineHeight: 1.4,
  },
  subtitle2: {
    fontSize: '1.4rem',
    fontWeight: 400,
    lineHeight: 1.4,
  },
};
