import React from 'react';
import Stack from '@mui/material/Stack';
import Skeleton, { SkeletonProps } from '@mui/material/Skeleton';
import { useLayoutBreakpoints } from 'src/hooks';
import { elementsArrFromNumber } from 'src/utils';

const style = {
  gap: 2,
  flexDirection: 'row',
  width: '100%',
};

const iconStyle = {
  mt: 0.3,
  height: '4rem',
  width: '4rem',
};

const buttonStyle = {
  alignSelf: 'flex-end',
  width: '15rem',
};

function DataListSkeletonRow({ animation }: SkeletonProps) {
  const { isMobile } = useLayoutBreakpoints();
  const textSkeleton = elementsArrFromNumber(
    <Skeleton animation={animation} variant="text" width="100%" />,
    2,
  );

  return (
    <Stack sx={style}>
      <Skeleton animation={animation} sx={iconStyle} />
      <Stack flex="0 0 17%">{textSkeleton}</Stack>
      <Stack width="5rem">{textSkeleton}</Stack>
      <Stack flex={1}>{textSkeleton}</Stack>
      <Stack flex={1}>{textSkeleton}</Stack>
      {!isMobile && (
        <>
          <Stack flex={1}>
            <Skeleton animation={animation} sx={buttonStyle} />
          </Stack>
          <Skeleton animation={animation} width="8rem" />
        </>
      )}
    </Stack>
  );
}

export default DataListSkeletonRow;
