import { alpha } from '@mui/material/styles';
import baseTheme from '../baseTheme';

const { palette, spacing } = baseTheme;

export default {
  MuiCircularProgress: {
    defaultProps: {
      color: 'inherit',
    },
  },
  MuiLinearProgress: {
    defaultProps: {
      color: 'success',
    },
    styleOverrides: {
      root: {
        height: spacing(0.7),
        borderRadius: spacing(0.4),
      },
      colorSuccess: {
        backgroundColor: alpha(palette.success.dark, 0.12),
      },
      barColorSuccess: {
        backgroundColor: palette.success.dark,
      },
      colorWarning: {
        backgroundColor: alpha(palette.warning.dark, 0.12),
      },
      barColorWarning: {
        backgroundColor: palette.warning.dark,
      },
      colorError: {
        backgroundColor: alpha(palette.error.dark, 0.12),
      },
      barColorError: {
        backgroundColor: palette.error.dark,
      },
    },
  },
};
