import React, { ReactNode } from 'react';
import Drawer from '@mui/material/Drawer';
import Fab from '@mui/material/Fab';
import SvgIcon from '@mui/material/SvgIcon';
import CloseIcon from 'src/assets/icons/close-icon.svg?react';
import SideModalSkeleton from './SideModalSkeleton';

type Props = {
  children: ReactNode;
  open: boolean;
  onClose: () => void;
  loading?: boolean;
};

const closeBtnStyle = {
  position: 'fixed',
  top: '1.5rem',
  right: { xs: '1.5rem', md: '53.5rem' },
};

function SideModal({ children, open, onClose, loading }: Props) {
  return (
    <Drawer
      anchor="right"
      open={open}
      onClose={onClose}
      PaperProps={{ sx: { width: '52rem', maxWidth: '100%' } }}
      hideBackdrop
    >
      <Fab onClick={onClose} sx={closeBtnStyle}>
        <SvgIcon component={CloseIcon} />
      </Fab>
      {loading ? <SideModalSkeleton /> : children}
    </Drawer>
  );
}

export default SideModal;
