export enum DocFormat {
  Pdf = 'PDF',
  Png = 'PNG',
  Jpg = 'JPG',
  Jpeg = 'JPEG',
  Bmp = 'BMP',
  Xlsx = 'XLSX',
  Xls = 'XLS',
  Doc = 'DOC',
  Docx = 'DOCX',
  Ppt = 'PPT',
  Pptx = 'PPTX',
  Csv = 'CSV',
}

export enum DocTypeCode {
  BackofficeDocument = 'BACKOFFICE_LOAN_APPLICATION_DOCUMENT',
  BriefLoanApplDescription = 'BRIEF_LOAN_APPLICATION_DESCRIPTION',
  CreditorNda = 'CREDITOR_NDA',
  ProofOfId = 'PROOF_OF_ID',
  ProofOfAddress = 'PROOF_OF_ADDRESS',
  ProofOfFundsSource = 'PROOF_OF_SOURCE_OF_FUNDS',
  RecentAnnualFinStatements = 'RECENT_ANNUAL_FINANCIAL_STATEMENTS',
  RecentQuarterFinStatements = 'RECENT_QUARTERLY_FINANCIAL_STATEMENTS',
  Report = 'REPORT',
  SettingsDocument = 'SETTINGS_DOCUMENT',
  Uncategorized = 'UNCATEGORIZED',
}

export type DocGetMetaReqParams = {
  documentIds?: number | number[];
  userId?: number;
};

export type DocDeleteReqParams = {
  documentId?: number;
  userId?: number;
};

export type DocUploadReqParams = {
  userId?: number;
  docType: DocTypeCode;
  files?: FileList | null;
};

export type DocFileMeta = {
  createdDate?: string;
  docTypeCode: DocTypeCode;
  filename: string;
  filesize: number;
  id: number;
  mimeType: string;
};
