export enum QueryRoot {
  Default,
  Dicts,
  Local,
  Pa,
  Ver,
}

export enum ReqMethod {
  Get = 'GET',
  Post = 'POST',
  Put = 'PUT',
  Delete = 'DELETE',
}

export enum ReqSortOrder {
  Asc = 'ASC',
  Desc = 'DESC',
}

export enum ResponseCode {
  Success = 200,
  Unauthorized = 401,
}

export type Response<T = unknown> = {
  data: T;
  errCode?: number;
  errMessage?: string;
  message?: string;
  success: boolean;
  total: number;
};
