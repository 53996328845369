import React from 'react';
import { Link } from 'react-router-dom';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import CardBuilding from 'src/assets/images/card-building.svg';
import CardPhone from 'src/assets/images/card-phone.svg';
import {
  LOAN_APPLICATION as ROUTE_LOAN_APPLICATION,
  CREDITOR_APPLICATION as ROUTE_CREDITOR_APPLICATION,
} from 'src/router';
import InfoCard, {
  InfoCardColor,
  InfoCardsContainer,
} from 'src/components/InfoCard';

type Props = {
  isCreditor?: boolean;
};

function DashboardInfo({ isCreditor }: Props) {
  return (
    <InfoCardsContainer>
      <InfoCard color={InfoCardColor.Primary} image={CardPhone}>
        <Typography variant="h5" textTransform="capitalize" mb={4}>
          Submit a New Loan Application
        </Typography>
        <Button
          color="success"
          size="small"
          component={Link}
          to={ROUTE_LOAN_APPLICATION}
        >
          Get Funding
        </Button>
      </InfoCard>
      {!isCreditor && (
        <InfoCard image={CardBuilding} color={InfoCardColor.Paper}>
          <Typography variant="h5" textTransform="capitalize" mb={4}>
            Get Access to Private Debt Opportunities
          </Typography>
          <Button
            color="secondary"
            size="small"
            component={Link}
            to={ROUTE_CREDITOR_APPLICATION}
          >
            Access Opportunities
          </Button>
        </InfoCard>
      )}
    </InfoCardsContainer>
  );
}

export default DashboardInfo;
