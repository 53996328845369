import React, { useContext } from 'react';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import SvgIcon from '@mui/material/SvgIcon';
import FolderOff from 'src/assets/icons/folder-off-icon.svg?react';
import {
  DataRoomGroup,
  getDataRoomItemsIds,
  useGetDataRoomlistQuery,
} from 'src/entities/dataRoom';
import TreeView from 'src/components/TreeView';
import CreateNewFolderIcon from 'src/assets/icons/create-new-folder.svg?react';
import DownloadCloudIcon from 'src/assets/icons/download-cloud.svg?react';
import { FilesDataRoomContext } from './FilesDataRoomContext';
import FundingOppDetailsDataRoomItem from './FilesDataRoomItem';

type Props = {
  group: DataRoomGroup;
  id: number;
  canUpdate: boolean;
};

function FilesDataRoomContent(params: Props) {
  const { showDownloadFilesModal, showCreateFolderModal } =
    useContext(FilesDataRoomContext);
  const { data, isLoading } = useGetDataRoomlistQuery(params);
  const { group, canUpdate } = params;

  return (
    <Stack gap={2}>
      <Stack direction="row" gap={2}>
        {group === DataRoomGroup.loanApps && canUpdate && (
          <Button
            size="small"
            fullWidth
            color="secondary"
            startIcon={<CreateNewFolderIcon />}
            disabled={!data?.length}
            onClick={() => showCreateFolderModal(undefined)}
          >
            Create folder
          </Button>
        )}
        <Button
          size="small"
          fullWidth
          startIcon={<DownloadCloudIcon />}
          color="secondary"
          disabled={!data?.length}
          onClick={() => showDownloadFilesModal(getDataRoomItemsIds(data))}
        >
          Download all
        </Button>
      </Stack>
      <TreeView
        data={data}
        loading={isLoading}
        itemProps={{ ContentComponent: FundingOppDetailsDataRoomItem }}
        noDataContent={
          <>
            <SvgIcon component={FolderOff} />
            No items in the data room
          </>
        }
      />
    </Stack>
  );
}

export default FilesDataRoomContent;
