import baseTheme from '../baseTheme';

const { spacing } = baseTheme;

export default {
  defaultProps: {
    component: 'span',
    size: 'small',
  },
  styleOverrides: {
    sizeXs: {
      borderRadius: spacing(0.4),
      height: spacing(1.6),
    },
    labelXs: {
      paddingInline: spacing(0.5),
    },
  },
};
