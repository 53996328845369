import React, { useMemo } from 'react';
import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import {
  PickersActionBarAction,
  PickersActionBarProps,
} from '@mui/x-date-pickers';

type DatePickerActionData = {
  onClick: () => void;
  label: string;
  color?: 'primary' | 'secondary';
};

function DateTimePickerActions({
  actions,
  onAccept,
  onCancel,
  onClear,
  onSetToday,
  ...rest
}: PickersActionBarProps) {
  const actionsMap: Map<PickersActionBarAction, DatePickerActionData> = useMemo(
    () =>
      new Map([
        ['clear', { onClick: onClear, label: 'Clear' }],
        ['cancel', { onClick: onCancel, label: 'Cancel', color: 'secondary' }],
        ['accept', { onClick: onAccept, label: 'OK' }],
        ['today', { onClick: onSetToday, label: 'Today' }],
      ]),
    [onClear, onCancel, onAccept, onSetToday],
  );

  return (
    <DialogActions data-testid="datepicker-actions" {...rest}>
      {actions?.map((action) => {
        const { label, ...rest } = actionsMap.get(
          action,
        ) as DatePickerActionData;
        return (
          <Button
            data-testid={`datepicker-actions-${action}-btn`}
            key={action}
            size="small"
            {...rest}
          >
            {label}
          </Button>
        );
      })}
    </DialogActions>
  );
}

export default DateTimePickerActions;
