import { useMemo, useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  filterObject,
  filterObjectByKeys,
  parseQueryString,
  stringifyQuery,
} from 'src/utils';

export const useQueryString = () => {
  const location = useLocation();
  return useMemo(() => parseQueryString(location.search), [location.search]);
};

export const useQueryStateValues = <T extends Record<string, unknown>>(
  defaultValue: T,
  replace = true,
  copyState = true,
): [T, (value: T) => void] => {
  const location = useLocation();
  const navigate = useNavigate();
  const query = useQueryString();
  const currentValue = useMemo(
    () => ({
      ...defaultValue,
      ...filterObject(filterObjectByKeys(query, Object.keys(defaultValue))),
    }),
    [defaultValue, query],
  );
  const setValue = useCallback(
    (values: Partial<T>) => {
      const currQuery = parseQueryString(window.location.search);
      const newQuery = filterObject({ ...currQuery, ...values });
      const search = stringifyQuery(newQuery);
      const { state, ...restLocation } = location;
      navigate(
        { ...restLocation, search },
        { replace, state: copyState ? state : null },
      );
    },
    [copyState, location, navigate, replace],
  );
  return [currentValue, setValue];
};

export const useSingleQueryStateValue = <TValue>(
  paramKey: string,
): [TValue, (selected: TValue) => void] => {
  const [params, setParams] = useQueryStateValues<Record<string, TValue>>({
    [paramKey]: null as TValue,
  });
  const setValue = useCallback(
    (value: TValue) => setParams({ [paramKey]: value }),
    [paramKey, setParams],
  );

  return [params[paramKey], setValue];
};
