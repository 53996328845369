import React from 'react';
import { useAuthState } from 'src/entities/auth';
import {
  useLoanAppState,
  useSaveLoanAppDraftMutation,
} from 'src/entities/loanApps';
import { AlertType, useAlert } from 'src/components/AlertsProvider';
import PageTitle from 'src/components/PageTitle';

function LoanAppTitle() {
  const { showAlert } = useAlert();
  const { isAuthenticated } = useAuthState();
  const { lastModifiedDate, ...state } = useLoanAppState(null);
  const [saveDraft, { isLoading }] = useSaveLoanAppDraftMutation();

  const handleSaveDraft = () =>
    saveDraft(state)
      .unwrap()
      .then(() =>
        showAlert({
          type: AlertType.Success,
          text: 'Loan application draft has been saved',
        }),
      );

  return (
    <PageTitle
      title="Loan application"
      onSave={handleSaveDraft}
      canSave={isAuthenticated}
      loading={isLoading}
      lastSaved={lastModifiedDate}
    />
  );
}

export default LoanAppTitle;
