import React, { ReactNode, useContext, useMemo, useState } from 'react';
import { DrawerProps } from '@mui/material/Drawer';
import { useLayoutBreakpoints } from 'src/hooks';

type Props = {
  children?: ReactNode;
};

type ContextProps = {
  fullWidth?: boolean;
  open?: boolean;
  variant?: DrawerProps['variant'];
  toggleOpen: () => void;
};

const SidebarContext = React.createContext<ContextProps>({
  fullWidth: true,
  open: false,
  variant: 'permanent',
  toggleOpen: () => null,
});

export const useSidebarContext = () => useContext(SidebarContext);

function SidebarContextProvider({ children }: Props) {
  const { isMobile, isTablet } = useLayoutBreakpoints();
  const [open, setOpen] = useState<boolean>();
  const value = useMemo(
    (): ContextProps => ({
      toggleOpen: () => setOpen(!open),
      variant: isMobile ? 'temporary' : 'permanent',
      fullWidth: !isTablet,
      open,
    }),
    [isMobile, isTablet, open],
  );

  return (
    <SidebarContext.Provider value={value}>{children}</SidebarContext.Provider>
  );
}

export default SidebarContextProvider;
