import React, { ForwardedRef, forwardRef } from 'react';
import InputAdornment from '@mui/material/InputAdornment';
import TextField, { TextFieldProps } from '@mui/material/TextField';
import { getCurrSymbol } from 'src/entities/currency';
import CurrencyTextfieldInput from './CurrencyTextfieldInput';

type Props = TextFieldProps & {
  currency?: string;
};

function CurrencyTextfield(
  { currency, ...rest }: Props,
  ref: ForwardedRef<HTMLInputElement>,
) {
  return (
    <TextField
      data-testid="currency-textfield"
      ref={ref}
      InputProps={{
        startAdornment: currency && (
          <InputAdornment
            data-testid="currency-textfield-curr-symbol"
            position="start"
          >
            {getCurrSymbol(currency)}
          </InputAdornment>
        ),
        inputComponent: CurrencyTextfieldInput,
      }}
      {...rest}
    />
  );
}

export default forwardRef(CurrencyTextfield);
