import baseTheme from '../baseTheme';

const { spacing, typography } = baseTheme;

export default {
  MuiTextField: {
    defaultProps: {
      size: 'small',
      variant: 'outlined',
      fullWidth: true,
    },
  },
  MuiInputBase: {
    styleOverrides: {
      input: {
        height: spacing(2.3),
      },
    },
  },
  MuiFilledInput: {
    defaultProps: {
      disableUnderline: true,
    },
    styleOverrides: {
      root: {
        borderRadius: spacing(0.8),
      },
    },
  },
  MuiOutlinedInput: {
    styleOverrides: {
      root: {
        ...typography.input,
      },
    },
  },
  MuiInputLabel: {
    styleOverrides: {
      root: {
        ...typography.input,
      },
    },
  },
  MuiSelect: {
    styleOverrides: {
      select: {
        display: 'flex',
        alignItems: 'center',
      },
      outlined: {
        minHeight: spacing(2.3),
        '>*': {
          marginTop: spacing(-0.2),
          marginBottom: spacing(-0.2),
        },
      },
    },
  },
  MuiAutocomplete: {
    defaultProps: {
      autoComplete: true,
      fullWidth: true,
      size: 'small',
      componentsProps: {
        paper: {
          elevation: 8,
        },
      },
    },
    styleOverrides: {
      endAdornment: {
        top: 'calc(50% - 1.2rem)',
      },
      listbox: {
        '.MuiAutocomplete-option': {
          padding: spacing(1.2, 1.8),
        },
      },
    },
  },
  MuiCheckbox: {
    styleOverrides: {
      root: {
        padding: spacing(0, 1),
      },
    },
  },
  MuiRadioGroup: {
    defaultProps: {
      row: true,
    },
  },
};
