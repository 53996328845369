import React from 'react';
import Box from '@mui/material/Box';
import MonivoltLogo from 'src/assets/images/monivolt-logo.svg?react';

function AuthPageLogo() {
  return (
    <Box color="text.disabled" pt={5}>
      <MonivoltLogo />
    </Box>
  );
}

export default AuthPageLogo;
