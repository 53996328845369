import React, { ChangeEvent, ForwardedRef, forwardRef } from 'react';
import {
  NumericFormat,
  NumericFormatProps,
  OnValueChange,
} from 'react-number-format';

function CurrencyTextfieldInput(
  { onChange, ...rest }: Omit<NumericFormatProps, 'defaultValue' | 'name'>,
  ref: ForwardedRef<HTMLInputElement>,
) {
  const handleChange: OnValueChange = ({ value }, { event }) =>
    onChange &&
    onChange({
      target: { ...event?.target, value },
    } as ChangeEvent<HTMLInputElement>);

  return (
    <NumericFormat
      {...rest}
      getInputRef={ref}
      onValueChange={handleChange}
      thousandSeparator
      valueIsNumericString
    />
  );
}

export default forwardRef(CurrencyTextfieldInput);
