import React, { ReactNode } from 'react';
import {
  SimpleTreeView,
  SimpleTreeViewProps,
} from '@mui/x-tree-view/SimpleTreeView';
import TreeViewList from './TreeViewList';
import TreeViewSkeleton from './TreeViewSkeleton';
import { TreeItemProps } from './types';
import { DataRoomItem } from '../../entities/dataRoom';

type Props<T extends DataRoomItem> = SimpleTreeViewProps<false> & {
  data?: T[];
  loading?: boolean;
  itemProps?: TreeItemProps<T>;
  noDataContent?: ReactNode;
};

function TreeView({
  data,
  itemProps,
  loading,
  noDataContent,
  ...rest
}: Props<DataRoomItem>) {
  const noData = !data?.length;

  if (loading || noData) {
    return (
      <TreeViewSkeleton loading={loading}>{noDataContent}</TreeViewSkeleton>
    );
  }
  return (
    <SimpleTreeView {...rest}>
      <TreeViewList data={data} itemProps={itemProps} />
    </SimpleTreeView>
  );
}

export default TreeView;
