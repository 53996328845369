import React from 'react';
import LoanAppsList from './LoanAppsList';
import LoanAppsAddPartnersContextProvider from './LoanAppDetailsContext';

function LoanApps() {
  return (
    <LoanAppsAddPartnersContextProvider>
      <LoanAppsList />
    </LoanAppsAddPartnersContextProvider>
  );
}

export default LoanApps;
