import React, { ReactNode } from 'react';
import Stack, { StackProps } from '@mui/material/Stack';
import Paging, { PagingProps } from 'src/components/Paging';

type Props = StackProps & {
  children?: ReactNode;
  pagingProps?: PagingProps;
  showPaging?: boolean;
};

function DataListWrapper({
  children,
  pagingProps,
  showPaging = true,
  ...rest
}: Props) {
  return (
    <Stack gap={1} mb={2} {...rest}>
      {children}
      {showPaging && <Paging {...pagingProps} />}
    </Stack>
  );
}

export default DataListWrapper;
