import * as Yup from 'yup';
import { getValidationArrSchema } from 'src/helpers';
import { transformNan } from 'src/utils';
import { LoanAppFormField } from 'src/entities/loanApps';

export const loanParamsValidation = {
  [LoanAppFormField.LoanAmount]: Yup.number()
    .transform(transformNan)
    .nullable()
    .required('Please enter loan amount')
    .min(1, 'Please enter amount greater than or equal to $${min}')
    .max(1000000000, 'Please enter amount less or equal than $${max}'),
  [LoanAppFormField.LoanCurrency]: Yup.string().required(
    'Please select loan currency',
  ),
  [LoanAppFormField.LoanType]: Yup.string().required(
    'Please select type of debt solution',
  ),
  [LoanAppFormField.LoanTerm]: Yup.number()
    .transform(transformNan)
    .nullable()
    .required('Please enter loan term')
    .min(1, 'Loan term should be at least ${min} month')
    .max(1000, 'Loan term should be max ${max} months'),
  [LoanAppFormField.AdditionalInfo]: Yup.string()
    .required('Please enter additional information')
    .max(2000, 'Additional information should have max ${max} symbols'),
};

const addressValidation = {
  [LoanAppFormField.AddressLine1]: Yup.string()
    .required('Please enter your address')
    .max(100, 'Address Line 1 should have max ${max} symbols'),
  [LoanAppFormField.AddressLine2]: Yup.string().max(
    100,
    'Address Line 2 should have max ${max} symbols',
  ),
  [LoanAppFormField.City]: Yup.string()
    .required('Please enter city')
    .max(100, 'City should have max ${max} symbols'),
  [LoanAppFormField.StateProvince]: Yup.string().max(
    100,
    'Province should have max ${max} symbols',
  ),
  [LoanAppFormField.Country]: Yup.string().required('Please select country'),
  [LoanAppFormField.PostalCode]: Yup.string()
    .required('Please enter postal code')
    .max(10, 'Postal code should have max ${max} digits'),
};

const whenAddressIsNotSame = (then: Yup.AnySchema) =>
  Yup.string().when(LoanAppFormField.AddressIsSame, { is: false, then });
export const businessInfoValidation = {
  [LoanAppFormField.FullCompanyName]: Yup.string().required(
    'Please enter company name',
  ),
  [LoanAppFormField.RegistrationNumber]: Yup.string()
    .required('Please enter registration number')
    .max(100, 'Registration number should have max ${max} digits'),
  [LoanAppFormField.Website]: Yup.string().max(
    255,
    'Website should have max ${max} symbols',
  ),
  [LoanAppFormField.Description]: Yup.string().max(
    400,
    'Description should have max ${max} symbols',
  ),
  [LoanAppFormField.AddressIsSame]: Yup.boolean(),
  [LoanAppFormField.PrimaryAddressLine1]: whenAddressIsNotSame(
    Yup.string()
      .required('Please enter your address')
      .max(100, 'Address Line 1 should have max ${max} symbols'),
  ),
  [LoanAppFormField.PrimaryAddressLine2]: Yup.string().max(
    100,
    'Address Line 2 should have max ${max} symbols',
  ),
  [LoanAppFormField.PrimaryCity]: whenAddressIsNotSame(
    Yup.string()
      .required('Please enter city')
      .max(100, 'City should have max ${max} symbols'),
  ),
  [LoanAppFormField.PrimaryStateProvince]: Yup.string().max(
    100,
    'State/Province should have max ${max} symbols',
  ),
  [LoanAppFormField.PrimaryCountry]: whenAddressIsNotSame(
    Yup.string().required('Please select country'),
  ),
  [LoanAppFormField.PrimaryPostalCode]: whenAddressIsNotSame(
    Yup.string()
      .required('Please enter postal code')
      .max(10, 'Postal code should have max 10 digits'),
  ),
  ...addressValidation,
};

export const contactPersonValidation = {
  [LoanAppFormField.Name]: Yup.string()
    .required('Please enter first name')
    .max(100, 'First name should have max ${max} symbols'),
  [LoanAppFormField.Surname]: Yup.string()
    .required('Please enter last name')
    .max(100, 'Last name should have max ${max} symbols'),
  [LoanAppFormField.PersonRelType]: Yup.string()
    .required('Please enter relationship to the company')
    .max(100, 'Relationship description should have max ${max} symbols'),
  [LoanAppFormField.PhoneNumber]: Yup.string()
    .required('Please enter phone number')
    .max(100, 'Phone number should have max ${max} symbols'),
  [LoanAppFormField.Email]: Yup.string()
    .required('Please enter email')
    .email('Please enter a valid email')
    .max(100, 'Email should have max ${max} symbols'),
};
export const contactPersonsValidation = {
  [LoanAppFormField.ContactPersons]: getValidationArrSchema(
    contactPersonValidation,
  ),
};

export const supportingDocsValidation = {
  [LoanAppFormField.Docs]: Yup.mixed(),
};
