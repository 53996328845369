import React from 'react';
import { useGetCountriesQuery } from 'src/entities/countries';
import { FieldSetItemComponentProps } from './types';
import { createLabelFormatter } from './utils';

function FieldsSetCountryFormatter({
  defaultValue,
  value,
}: FieldSetItemComponentProps) {
  const { data } = useGetCountriesQuery();
  const formatter = createLabelFormatter(data?.list);

  return <span>{data ? formatter(value) : defaultValue}</span>;
}

export default FieldsSetCountryFormatter;
