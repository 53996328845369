import React, { useContext, useRef } from 'react';
import clsx from 'clsx';
import { StepperContext, StepperContextType } from '@mui/material/Stepper';
import MuiStep, { StepProps } from '@mui/material/Step';
import { useDelayedEffect } from 'src/hooks';
import { scrollIntoView } from 'src/utils';
import { STEPPER_COLLAPSE_DURATION } from 'src/theme/constants';

function Step({ children, index, ...rest }: StepProps) {
  const ref = useRef<HTMLDivElement>(null);
  const { activeStep } = useContext(StepperContext) as StepperContextType;
  const isActive = activeStep === index;

  useDelayedEffect(
    () => isActive && index && scrollIntoView(ref.current),
    STEPPER_COLLAPSE_DURATION * 1.2,
    [ref, isActive],
  );

  return (
    <MuiStep
      data-testid={`stepper-item-${index}`}
      ref={ref}
      index={index}
      className={clsx({ 'Mui-active': isActive })}
      {...rest}
    >
      {children}
    </MuiStep>
  );
}

export default Step;
