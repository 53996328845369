import { PaletteOptions } from '@mui/material/styles/createPalette';

export const palette: PaletteOptions = {
  primary: {
    dark: '#27AE60',
    main: '#21C365',
    light: '#2CDC76',
    contrastText: '#FFFFFF',
  },
  secondary: {
    dark: '#00000024',
    main: '#00000012',
    light: '#00000008',
    contrastText: '#413D54',
  },
  success: {
    dark: '#21C465',
    main: '#4ECB71',
    light: '#21C46503',
    contrastText: '#FFFFFF',
  },
  error: {
    dark: '#AF2727',
    main: '#D32F2F',
    light: '#D32F2F40',
  },
  warning: {
    dark: '#E09D00',
    main: '#FFB400',
    light: '#FFB40040',
  },
  info: {
    dark: '#A334CB',
    main: '#BB6BD9',
    light: '#BB6BD940',
  },
  background: {
    default: '#E3E4EA',
    paper: '#ECEDF2',
  },
  common: {
    black: '#17191D',
    white: '#FFFFFF',
  },
  currency: {
    btc: '#F7931A',
    eth: '#6481E7',
    usdt: '#1BA27A',
  },
  text: {
    primary: '#413D54',
    secondary: '#72707D',
  },
  gradient: {
    primary: 'linear-gradient(90deg, transparent 70%, #413D5440 100%)',
    secondary:
      'linear-gradient(140deg, transparent 55%, #72707D40 75%, #2BD07150 100%)',
    bgDefault: 'linear-gradient(transparent, #E3E4EA)',
  },
};
