import React from 'react';
import { generatePath } from 'src/utils';
import { DocFileMeta, DOCS_DOWNLOAD_URL } from 'src/entities/documents';
import { FilesInputListItem } from '../FileInput';

type Props = {
  onDelete: (id?: number) => void;
  item: DocFileMeta;
  userId: number;
};

function DocUploadInputListItem({ onDelete, item, userId }: Props) {
  const { id, filename } = item;
  return (
    <FilesInputListItem
      onDelete={onDelete}
      id={id}
      label={filename}
      href={generatePath(DOCS_DOWNLOAD_URL, { id, userId })}
    />
  );
}

export default DocUploadInputListItem;
