export const loadDataFromStorage = (name: string) => {
  const data = localStorage.getItem(name) || '';
  try {
    return JSON.parse(data);
  } catch {
    return data;
  }
};

export const saveDataToStorage = <T = unknown>(name: string, data: T) => {
  if (data) {
    const stringData = JSON.stringify(data);
    localStorage.setItem(name, stringData);
  }
};
