import React, { useEffect, useState } from 'react';
import TimeIcon from 'src/assets/icons/time-icon.svg?react';
import { formatDateTime, getTimeZone } from 'src/utils';
import SidebarNavLink from './SidebarNavLink';
import { useSidebarContext } from './SidebarContext';

function SidebarFooter() {
  const { open } = useSidebarContext();
  const [date, setDate] = useState<Date>(new Date());

  useEffect(() => {
    const interval = setInterval(() => setDate(new Date()), 1000);
    return () => clearInterval(interval);
  }, []);

  return (
    <SidebarNavLink
      sx={{ flex: 0 }}
      icon={TimeIcon}
      title={formatDateTime(date)}
      subtitle={getTimeZone()}
      disabled={open}
    />
  );
}

export default SidebarFooter;
