import React from 'react';

import TextField from '@mui/material/TextField';
import { DataRoomGroup } from 'src/entities/dataRoom';
import {
  DataRoomItemFields,
  DataRoomCreateFolderParams,
} from 'src/entities/dataRoom/types';
import ResponsiveStack from 'src/components/ResponsiveStack';
import FormControl from 'src/components/FormControl';
import ModalDialog from '../ModalDialog/ModalDialog';
import { ModalCloseHandler } from '../ModalDialog/types';
import { useCreateDataRoomFolderMutation } from '../../entities/dataRoom';
import { AlertType, useAlert } from '../AlertsProvider';
import { useForm } from '../../helpers';
import { manageFolderValidation } from './DataRoomFormData';

type Props = {
  group: DataRoomGroup;
  loanApplicationId: number;
  parentId: string;
  open: boolean;
  onClose: ModalCloseHandler;
};

function ManageFolderModal({
  loanApplicationId,
  group,
  parentId,
  open,
  onClose,
}: Props) {
  const [createFolderRequest, { isLoading: isSaving }] =
    useCreateDataRoomFolderMutation();
  const { showAlert } = useAlert();
  const { control, handleSubmit } = useForm<DataRoomCreateFolderParams>({
    validation: manageFolderValidation,
  });
  const onSumit = (values: DataRoomCreateFolderParams) => {
    createFolderRequest({
      loanApplicationId,
      parentId,
      group,
      name: values.name,
    })
      .unwrap()
      .then(() => {
        showAlert({
          type: AlertType.Success,
          text: 'Folder created',
        });
        onClose();
      });
  };

  return (
    <ModalDialog
      PaperProps={{
        component: 'form',
        onSubmit: handleSubmit(onSumit),
        sx: { minHeight: '25rem' },
      }}
      open={open}
      onClose={onClose}
      title="Create folder"
      cancelBtnText="Close"
      confirmBtnText="Create folder"
      showConfirmBtn
      confirmBtnType="submit"
      confirmBtnLoading={isSaving}
    >
      <ResponsiveStack>
        <FormControl
          name={DataRoomItemFields.Name}
          label="Create folder name"
          control={control}
          component={TextField}
        />
      </ResponsiveStack>
    </ModalDialog>
  );
}

export default ManageFolderModal;
