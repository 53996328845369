import React, { useEffect, useState } from 'react';
import LinearProgress from '@mui/material/LinearProgress';
import Stack from '@mui/material/Stack';
import SvgIcon from '@mui/material/SvgIcon';
import DownloadIcon from 'src/assets/icons/download-icon.svg?react';
import { navigateToExternalUrl } from 'src/utils';
import ModalDialog, { ModalCloseHandler } from 'src/components/ModalDialog';
import {
  DATA_ROOM_ARCH_CONTENT,
  DataRoomArchiveStatus,
  DataRoomGroup,
  useGetArchiveStatusQuery,
  useInitArchiveQuery,
} from 'src/entities/dataRoom';

type Props = {
  group: DataRoomGroup;
  id: number;
  items: string | string[];
  open: boolean;
  onClose: ModalCloseHandler;
};

function FilesDataRoomDownloadModal({
  group,
  id,
  items,
  open,
  onClose,
}: Props) {
  const [isReady, setIsReady] = useState<boolean>();
  const { data: initData } = useInitArchiveQuery(
    { group, id, items },
    { skip: !items },
  );
  const { data: statusData } = useGetArchiveStatusQuery(
    { group, id, archiveId: initData?.id },
    { pollingInterval: 5000, skip: !initData || isReady },
  );
  const downloadArchive = () =>
    navigateToExternalUrl(DATA_ROOM_ARCH_CONTENT, {
      group,
      id,
      archiveId: initData?.id,
    });

  useEffect(() => {
    setIsReady(statusData?.statusCode === DataRoomArchiveStatus.Done);
  }, [statusData?.statusCode]);

  return (
    <ModalDialog
      PaperProps={{ sx: { minHeight: '25rem' } }}
      open={open}
      onClose={onClose}
      onConfirm={downloadArchive}
      title="Download files"
      cancelBtnText="Close"
      confirmBtnText="Download archive"
      confirmBtnDisabled={!isReady}
      showConfirmBtn
    >
      <Stack direction="row" alignItems="center" gap={2}>
        <SvgIcon component={DownloadIcon} fontSize="xl" />
        {isReady
          ? 'The archive has been created, you can download files'
          : 'Files archive creation is in a progress...'}
      </Stack>
      <LinearProgress
        variant="determinate"
        value={statusData?.readiness ?? 0}
        sx={{ mb: 1 }}
      />
    </ModalDialog>
  );
}

export default FilesDataRoomDownloadModal;
