import { CURRENCY_FORMAT } from './constants';
import { Currency } from './types';

const currSymbols: Partial<Record<Currency, string>> = {
  [Currency.Usd]: '$',
  [Currency.Eur]: '€',
  [Currency.Gbp]: '£',
};

export const getCurrSymbol = (curr?: string) =>
  curr && (currSymbols[curr as Currency] || curr);

export const formatCurrValue = (val: string | number, curr?: string) => {
  const currency = curr ?? '';
  const value = Number(val);
  const options = { maximumFractionDigits: 0 };
  try {
    return value.toLocaleString(CURRENCY_FORMAT, {
      style: 'currency',
      currency,
      ...options,
    });
  } catch {
    return `${getCurrSymbol(curr)} ${value.toLocaleString(
      CURRENCY_FORMAT,
      options,
    )}`;
  }
};
