import { useCallback, useMemo, useState } from 'react';

const selectionToArray = (selected: Record<string, boolean>) =>
  Object.entries(selected)
    .filter(([, value]) => value)
    .map(([key]) => key);

export const useItemsSelection = <TKey>() => {
  const [selected, setSelected] = useState<Record<string, boolean>>({});
  const selectedArr = useMemo(() => selectionToArray(selected), [selected]);

  const onSelect = useCallback((itemId: TKey) => {
    const key = String(itemId);
    setSelected((prevState) => ({ ...prevState, [key]: !prevState[key] }));
  }, []);

  return { selected, selectedArr, onSelect };
};
