import {
  BaseQueryFn as RtkBaseQueryFn,
  FetchArgs as RtkFetchArgs,
  fetchBaseQuery,
  FetchBaseQueryError,
} from '@reduxjs/toolkit/query';
import { joinValues, QueryStringParams, stringifyQuery } from 'src/utils';
import { logoutAction } from 'src/entities/auth/actions';
import { AUTH_CHECK_API, AUTH_ROOT_API } from 'src/entities/auth/constants';
import {
  API_ERROR_EVENT,
  API_VERSION,
  DICTS_BASE_API,
  PA_BASE_API,
} from './constants';
import { QueryRoot, ResponseCode } from './types';

type FetchArgs = Omit<RtkFetchArgs, 'url'> & {
  url?: string;
  queryParams?: QueryStringParams;
};

type BaseQueryFn = RtkBaseQueryFn<FetchArgs, unknown, FetchBaseQueryError>;

type BaseQueryParams = {
  baseUrl?: string;
  root?: QueryRoot;
};

const getApiReqParams = ({ url: reqUrl, queryParams, ...rest }: FetchArgs) => {
  const queryString = queryParams ? stringifyQuery(queryParams) : '';
  const url = (reqUrl ?? '') + queryString;
  return { url, ...rest };
};

const queryRootMap = new Map([
  [QueryRoot.Ver, API_VERSION],
  [QueryRoot.Dicts, DICTS_BASE_API],
  [QueryRoot.Pa, PA_BASE_API],
]);

export const baseQuery = (params?: BaseQueryParams): BaseQueryFn => {
  const { baseUrl, root = QueryRoot.Default } = params || {};
  const rootPath = queryRootMap.get(root);
  const proxyPath =
    root !== QueryRoot.Local && import.meta.env.REACT_APP_API_PATH;
  const query = fetchBaseQuery({
    baseUrl: joinValues([proxyPath, rootPath, baseUrl]),
  });

  return async (args, api, extraOptions) => {
    const result = await query(getApiReqParams(args), api, extraOptions);
    const isAuthApi = baseUrl === AUTH_ROOT_API;
    const isAuthCheckApi = args.url?.includes(AUTH_CHECK_API);
    const isUnauthorized = result.error?.status === ResponseCode.Unauthorized;

    if ((!isAuthApi || isAuthCheckApi) && isUnauthorized) {
      api.dispatch(logoutAction());
    } else if (result.error) {
      document.dispatchEvent(
        new CustomEvent(API_ERROR_EVENT, { detail: result.error }),
      );
    }

    return result;
  };
};
