import { AlertType, useAlert } from 'src/components/AlertsProvider';

export const useCopyToClipboard = <T>() => {
  const { showAlert } = useAlert();

  return async (value: T) => {
    if (value) {
      await navigator.clipboard.writeText(value.toString());
      showAlert({
        type: AlertType.Info,
        text: 'Value has been copied to the clipboard ',
      });
    }
  };
};
