import React from 'react';
import { DataRoomGroup } from 'src/entities/dataRoom';
import FilesDataRoomContent from './FilesDataRoomContent';
import FilesDataRoomContextProvider from './FilesDataRoomContext';

type Props = {
  group: DataRoomGroup;
  id: number;
  canUpdate: boolean;
};

function FilesDataRoom(params: Props) {
  return (
    <FilesDataRoomContextProvider {...params}>
      <FilesDataRoomContent {...params} />
    </FilesDataRoomContextProvider>
  );
}

export default FilesDataRoom;
