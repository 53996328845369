import baseTheme from '../baseTheme';

const { spacing } = baseTheme;

export default {
  defaultProps: {
    variant: 'rounded',
  },
  styleOverrides: {
    rounded: {
      height: spacing(4),
      borderRadius: spacing(0.8),
    },
  },
};
