import React, { ReactNode, useCallback } from 'react';
import { FormProviderProps } from 'react-hook-form';
import { useAppDispatch } from 'src/store';
import {
  CreditorAppState,
  saveCreditorAppAction,
} from 'src/entities/creditorApp';
import StepperForm from 'src/components/StepperForm';

type Props<TFieldValues extends CreditorAppState> =
  FormProviderProps<TFieldValues> & {
    children: ReactNode;
    onStepBack?: () => void;
    onStepNext: (values?: TFieldValues) => void;
    activeStep: number;
    step: number;
    stepId?: string;
  };

function CreditorAppFormWrapper<TFieldValues extends CreditorAppState>({
  children,
  ...props
}: Props<TFieldValues>) {
  const dispatch = useAppDispatch();
  const saveCreditorApp = useCallback(
    (values: TFieldValues) => dispatch(saveCreditorAppAction(values)),
    [dispatch],
  );

  return (
    <StepperForm onUpdate={saveCreditorApp} {...props}>
      {children}
    </StepperForm>
  );
}

export default CreditorAppFormWrapper;
