import AccordionCollapse from 'src/components/AccordionCollapse';
import baseTheme from '../baseTheme';

const { palette, spacing } = baseTheme;

export default {
  MuiAccordion: {
    defaultProps: {
      elevation: 0,
      TransitionComponent: AccordionCollapse,
    },
    styleOverrides: {
      root: {
        background: 'transparent',
        borderRadius: spacing(1.2),
        margin: spacing(0, -2),
        overflow: 'hidden',
        '&:before': {
          height: 0,
        },
        '&:first-of-type': {
          borderRadius: spacing(1.2),
        },
        '&.Mui-expanded': {
          margin: spacing(0, -2),
        },
      },
    },
  },
  MuiAccordionDetails: {
    styleOverrides: {
      root: {
        padding: spacing(2),
      },
    },
  },
  MuiAccordionSummary: {
    styleOverrides: {
      root: {
        '&.Mui-expanded': {
          background: palette.background.paper,
        },
      },
      content: {
        maxWidth: '100%',
        margin: spacing(2, 0),
      },
    },
  },
};
