import React from 'react';
import Paper, { PaperProps } from '@mui/material/Paper';
import { SxProps } from 'src/theme/types';

const style = {
  p: 2,
  gap: { xs: 1, md: 2 },
  display: 'grid',
  gridTemplateColumns: { xs: '2fr 3fr', md: 'repeat(5, 1fr)' },
  width: '100%',
  '&>*': {
    alignSelf: 'flex-start',
  },
  '&>:last-child': {
    gridColumn: { xs: '1 / 3', sm: 'auto' },
  },
};

function DataListRow({ children, sx, ...rest }: PaperProps) {
  return (
    <Paper sx={{ ...style, ...sx } as SxProps} {...rest}>
      {children}
    </Paper>
  );
}

export default DataListRow;
