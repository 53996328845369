import React, { useLayoutEffect, useState } from 'react';
import Collapse, { CollapseProps } from '@mui/material/Collapse';

function AccordionCollapse({ in: into, children, ...rest }: CollapseProps) {
  const [renderContent, setRenderContent] = useState<boolean>(false);
  useLayoutEffect(() => {
    if (into) {
      setRenderContent(true);
    }
  }, [into]);

  return (
    <Collapse in={into} {...rest}>
      {renderContent && children}
    </Collapse>
  );
}

export default AccordionCollapse;
