import React, { useContext } from 'react';
import { monthsToYears } from 'src/utils';
import DataListRow, { DataListCell } from 'src/components/DataList';
import { LoanAppItem } from 'src/entities/loanApps';
import { formatCurrValue } from 'src/entities/currency';
import { loanTypeLabels } from 'src/entities/dicts';
import LoanAppsListItemStatus from './LoanAppsListItemStatus';
import { LoanAppDetailsContext } from './LoanAppDetailsContext';

function LoanAppsListItem({
  id,
  currency,
  loanAmount,
  loanTypeCode,
  loanTerm,
  regionCode,
  statusCode,
}: LoanAppItem) {
  const { showLoanAppDetails } = useContext(LoanAppDetailsContext);

  return (
    <DataListRow
      sx={{ cursor: 'pointer' }}
      onClick={() => showLoanAppDetails(id)}
    >
      <DataListCell primary={formatCurrValue(loanAmount, currency)} />
      <DataListCell primary={`${monthsToYears(loanTerm)} years`} />
      <DataListCell primary={regionCode} />
      <DataListCell primary={loanTypeLabels[loanTypeCode]} />
      <LoanAppsListItemStatus status={statusCode} />
    </DataListRow>
  );
}

export default LoanAppsListItem;
