import baseTheme from '../baseTheme';

const { spacing } = baseTheme;

export default {
  defaultProps: {
    color: 'inherit',
  },
  styleOverrides: {
    root: {
      verticalAlign: 'bottom',
    },
    fontSizeXs: {
      fontSize: spacing(1.2),
    },
    fontSizeSmall: {
      fontSize: spacing(1.6),
    },
    fontSizeMedium: {
      fontSize: spacing(1.9),
    },
    fontSizeLarge: {
      fontSize: spacing(2.3),
    },
    fontSizeXl: {
      fontSize: spacing(3.2),
    },
    fontSizeXxl: {
      fontSize: spacing(5.5),
    },
  },
};
