import { BaseSyntheticEvent } from 'react';
import * as Yup from 'yup';
import {
  DefaultValues,
  FieldErrors,
  FieldValues,
  useForm as useHookForm,
  UseFormProps as UseHookFormProps,
  UseFormReturn,
} from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { mapObject, scrollIntoView } from 'src/utils';

type Validation = Record<string, Yup.AnySchema>;

type UseFormProps<TFieldValues extends FieldValues> = Omit<
  UseHookFormProps<TFieldValues>,
  'defaultValues'
> & {
  defaultValues?: DefaultValues<TFieldValues>;
  validation: Validation;
};

const getFieldDefaultValue = (key: string, field: Yup.AnySchema) => {
  if (field instanceof Yup.ArraySchema) {
    return [];
  }
  if (field instanceof Yup.BooleanSchema) {
    return false;
  }
  return '';
};

const getValidationSchema = (validation: Validation): Yup.AnyObjectSchema =>
  Yup.object().shape(validation);

export const getValidationArrSchema = (
  validation: Validation,
): Yup.ArraySchema<Yup.AnyObjectSchema> =>
  Yup.array().of(getValidationSchema(validation));

export const getDefaultFormValues = <TFieldValues extends FieldValues>(
  validation: Validation,
  defaultValues?: DefaultValues<TFieldValues>,
) =>
  mapObject(
    validation,
    (key, value) => defaultValues?.[key] ?? getFieldDefaultValue(key, value),
  ) as DefaultValues<TFieldValues>;

export const useForm = <TFieldValues extends FieldValues = FieldValues>(
  props: UseFormProps<TFieldValues>,
): UseFormReturn<TFieldValues> => {
  const { defaultValues, validation } = props || {};

  return useHookForm<TFieldValues>({
    defaultValues: getDefaultFormValues(validation, defaultValues),
    resolver: yupResolver(getValidationSchema(validation)),
  });
};

export const scrollToError = <TFieldValues extends FieldValues = FieldValues>(
  errors: FieldErrors<TFieldValues>,
  event?: BaseSyntheticEvent,
) => {
  const path = [];
  let error = errors;
  while (!error.message) {
    const [errorKey] = Object.keys(error);
    error = error[errorKey] as FieldErrors<TFieldValues>;
    path.push(errorKey);
  }
  scrollIntoView(event?.target.querySelector(`[name='${path.join('.')}']`));
};
