import React, { ComponentType, MouseEvent, ReactNode, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import SvgIcon from '@mui/material/SvgIcon';
import { SxProps } from '@mui/material/styles';
import ArrowDownIcon from 'src/assets/icons/arrow-down-icon.svg?react';
import ArrowUpIcon from 'src/assets/icons/arrow-up-icon.svg?react';
import CopyIcon from 'src/assets/icons/copy-icon.svg?react';
import MailIcon from 'src/assets/icons/mail-icon.svg?react';
import ExitIcon from 'src/assets/icons/exit-icon.svg?react';
import { SUPPORT_EMAIL } from 'src/constants';
import { useCopyToClipboard, useLayoutBreakpoints } from 'src/hooks';
import { navigateToExternalUrl, stopPropagation } from 'src/utils';
import { getRouteMeta, SETTINGS as ROUTE_SETTINGS } from 'src/router';
import { useLogoutMutation } from 'src/entities/auth';
import CardButton from '../CardButton';

type MenuItemProps = {
  key: string;
  label: ReactNode;
  description?: ReactNode;
  icon: ComponentType;
  onClick?: () => void;
  sx?: SxProps;
  divider?: boolean;
  btnIcon?: ComponentType;
  onBtnClick?: () => void;
};

type Props = {
  email: string;
  userName: string;
};

const menuStyle = {
  width: '27rem',
};

function HeaderMenu({ email, userName }: Props) {
  const { isMobile } = useLayoutBreakpoints();
  const navigate = useNavigate();
  const copyValue = useCopyToClipboard();
  const [logout] = useLogoutMutation();
  const { icon, title } = getRouteMeta(ROUTE_SETTINGS);
  const [anchorEl, setAnchorEl] = useState<null | Element>(null);
  const isOpen = Boolean(anchorEl);

  const handleOpenMenu = (event: MouseEvent) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const menuItems: MenuItemProps[] = [
    {
      key: ROUTE_SETTINGS,
      label: title,
      icon: icon as ComponentType,
      onClick: () => navigate(ROUTE_SETTINGS),
    },
    {
      key: 'help',
      label: 'Contact support',
      description: SUPPORT_EMAIL,
      icon: MailIcon,
      btnIcon: CopyIcon,
      divider: true,
      onClick: () => navigateToExternalUrl(`mailto:${SUPPORT_EMAIL}`),
      onBtnClick: () => copyValue(SUPPORT_EMAIL),
    },
    {
      key: 'exit',
      label: 'Logout',
      icon: ExitIcon,
      onClick: logout,
      sx: { color: 'error.main' },
    },
  ];

  return (
    <>
      {isMobile ? (
        <IconButton
          data-testid="header-menu-mobile-btn"
          onClick={handleOpenMenu}
          sx={{ p: 0 }}
        >
          <Avatar />
        </IconButton>
      ) : (
        <CardButton
          data-testid="header-menu-btn"
          onClick={handleOpenMenu}
          title={userName}
          description={email}
          startIcon={<Avatar />}
          endIcon={<SvgIcon component={isOpen ? ArrowUpIcon : ArrowDownIcon} />}
          sx={menuStyle}
        />
      )}
      <Menu
        data-testid="header-menu"
        anchorEl={anchorEl}
        open={isOpen}
        onClick={handleCloseMenu}
        onClose={handleCloseMenu}
        MenuListProps={{ sx: menuStyle }}
      >
        {menuItems.map(
          ({ icon, label, description, btnIcon, onBtnClick, ...rest }) => (
            <MenuItem data-testid={`header-menu-action-${rest.key}`} {...rest}>
              <ListItemIcon>
                <SvgIcon component={icon} />
              </ListItemIcon>
              <ListItemText primary={label} secondary={description} />
              {btnIcon && (
                <IconButton
                  onClick={stopPropagation<MouseEvent>(onBtnClick)}
                  size="small"
                >
                  <SvgIcon component={btnIcon} />
                </IconButton>
              )}
            </MenuItem>
          ),
        )}
      </Menu>
    </>
  );
}

export default HeaderMenu;
