import React, { ForwardedRef, forwardRef, ReactNode, useCallback } from 'react';
import {
  DatePicker as MuiDatePicker,
  DatePickerProps,
} from '@mui/x-date-pickers/DatePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { useLayoutElementProps } from 'src/hooks';
import { dateToString, stringToDate } from './utils';

type Props = Omit<
  DatePickerProps<Date | null>,
  'renderInput' | 'onChange' | 'value'
> & {
  id?: string;
  name?: string;
  label?: ReactNode;
  error?: boolean;
  helperText?: ReactNode;
  onChange?: (value: string) => void;
  value?: string | null;
  required?: boolean;
  placeholder?: string;
};

function DateTimePicker(
  {
    id,
    name,
    value,
    onChange,
    error,
    helperText,
    placeholder,
    required,
    ...props
  }: Props,
  ref: ForwardedRef<HTMLInputElement>,
) {
  const [elementProps, rest] = useLayoutElementProps(props);
  const handleChange = useCallback(
    (value: Date | null) => onChange && onChange(dateToString(value)),
    [onChange],
  );

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <MuiDatePicker
        value={stringToDate(value)}
        onChange={handleChange}
        ref={ref}
        slotProps={{
          textField: {
            ...elementProps,
            id,
            name,
            error,
            helperText,
            placeholder,
            required,
          },
        }}
        {...rest}
      />
    </LocalizationProvider>
  );
}

export default forwardRef(DateTimePicker);
