import React from 'react';
import MuiTab, { TabProps } from '@mui/material/Tab';
import SvgIcon from '@mui/material/SvgIcon';
import LockIcon from 'src/assets/icons/lock-icon.svg?react';

function Tab({ disabled, ...rest }: TabProps) {
  return (
    <MuiTab
      disabled={disabled}
      icon={disabled ? <SvgIcon component={LockIcon} /> : undefined}
      {...rest}
    />
  );
}

export default Tab;
