import React, { MouseEvent, useContext } from 'react';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import DownloadCloudIcon from 'src/assets/icons/download-cloud.svg?react';
import CreateNewFolderIcon from 'src/assets/icons/create-new-folder.svg?react';
import UploadFileIcon from 'src/assets/icons/upload-file.svg?react';
import UploadFolderIcon from 'src/assets/icons/upload-folder.svg?react';
import { DataRoomItem } from 'src/entities/dataRoom/types';
import { FilesDataRoomContext } from './FilesDataRoomContext';

type Props = {
  open: boolean;
  closeMenu: () => void;
  currentDataRoomItem: DataRoomItem | null;
  anchorComponent: null | HTMLElement | undefined;
  canUpdate: boolean;
};

function FilesDataRoomMenu({
  open,
  closeMenu,
  currentDataRoomItem,
  anchorComponent,
  canUpdate,
}: Props) {
  const {
    showDownloadFilesModal,
    showCreateFolderModal,
    showUploadFilesModal,
    showUploadFolderModal,
  } = useContext(FilesDataRoomContext);

  const handleDownload = (event: MouseEvent<HTMLLIElement>) => {
    event.stopPropagation();
    if (currentDataRoomItem) {
      showDownloadFilesModal(currentDataRoomItem.id);
    }
    closeMenu();
  };

  const handleCreateFolder = (event: React.MouseEvent<HTMLLIElement>) => {
    event.stopPropagation();
    if (currentDataRoomItem) {
      showCreateFolderModal(currentDataRoomItem.id);
    }
    closeMenu();
  };

  const handleUploadFiles = (event: React.MouseEvent<HTMLLIElement>) => {
    event.stopPropagation();
    if (currentDataRoomItem) {
      showUploadFilesModal(currentDataRoomItem.id);
    }
    closeMenu();
  };

  const handleUploadFolder = (event: React.MouseEvent<HTMLLIElement>) => {
    event.stopPropagation();
    if (currentDataRoomItem) {
      showUploadFolderModal(currentDataRoomItem.id);
    }
    closeMenu();
  };

  return (
    <Menu
      id="basic-menu"
      anchorEl={anchorComponent}
      open={open}
      onClose={closeMenu}
      MenuListProps={{
        'aria-labelledby': 'basic-button',
      }}
    >
      {canUpdate && (
        <MenuItem onClick={handleCreateFolder}>
          <ListItemIcon>
            <CreateNewFolderIcon />
          </ListItemIcon>
          <ListItemText>Create new folder</ListItemText>
        </MenuItem>
      )}
      <MenuItem onClick={handleDownload}>
        <ListItemIcon>
          <DownloadCloudIcon />
        </ListItemIcon>
        <ListItemText>Download folder (zip)</ListItemText>
      </MenuItem>
      {canUpdate && (
        <MenuItem onClick={handleUploadFolder}>
          <ListItemIcon>
            <UploadFolderIcon />
          </ListItemIcon>
          <ListItemText>Upload folder</ListItemText>
        </MenuItem>
      )}
      {canUpdate && (
        <MenuItem onClick={handleUploadFiles}>
          <ListItemIcon>
            <UploadFileIcon />
          </ListItemIcon>
          <ListItemText>Upload files</ListItemText>
        </MenuItem>
      )}
    </Menu>
  );
}

export default FilesDataRoomMenu;
