import React from 'react';
import { useForm, getDefaultFormValues } from 'src/helpers';
import FormArray from 'src/components/FormArray';
import { StepComponentProps } from 'src/components/Stepper';
import { StepperFormRowAddBtn } from 'src/components/StepperForm';
import {
  CreditorAppFormContactPersons,
  CreditorAppFormField,
  useCreditorAppState,
} from 'src/entities/creditorApp';
import CreditorAppContactPersonRow from './CreditorAppContactPersonRow';
import { contactPersonsValidation, contactPersonValidation } from './formData';
import CreditorAppFormWrapper from './CreditorAppFormWrapper';

function CreditorAppContactPersons(props: StepComponentProps) {
  const formProps = useForm<CreditorAppFormContactPersons>({
    defaultValues: useCreditorAppState(),
    validation: contactPersonsValidation,
  });

  return (
    <CreditorAppFormWrapper stepId="contact-persons" {...props} {...formProps}>
      <FormArray
        name={CreditorAppFormField.ContactPersons}
        rowDefaultValues={getDefaultFormValues(contactPersonValidation)}
        rowComponent={CreditorAppContactPersonRow}
        addRowComponent={StepperFormRowAddBtn}
        addRowProps={{ label: 'Additional contact persons' }}
      />
    </CreditorAppFormWrapper>
  );
}

export default CreditorAppContactPersons;
