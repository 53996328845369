import { createSlice, isAnyOf, PayloadAction } from '@reduxjs/toolkit';
import { loadDataFromStorage } from 'src/services';
import { useAppSelector } from 'src/store/hooks';
import { customerApi } from '../customer';
import { authApi } from './api';
import { logoutAction, setRedirectPath } from './actions';
import { AuthState, AuthUserInfo } from './types';
import { AUTH_STORE_KEY } from './constants';

const initialState: AuthState = loadDataFromStorage(AUTH_STORE_KEY) || {
  isAuthenticated: null,
  redirectPath: null,
  user: null,
};

const reducers = {
  login: (state: AuthState) => ({
    ...state,
    isAuthenticated: true,
  }),
  logout: () => ({
    isAuthenticated: false,
    user: null,
  }),
  setRedirectPath: (
    state: AuthState,
    { payload }: PayloadAction<string | null>,
  ) => ({
    ...state,
    redirectPath: payload ?? null,
  }),
  setUser: (state: AuthState, { payload }: PayloadAction<AuthUserInfo>) => ({
    ...state,
    user: payload,
  }),
};

export const authSlice = createSlice({
  name: AUTH_STORE_KEY,
  initialState: loadDataFromStorage(AUTH_STORE_KEY) || initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(setRedirectPath, reducers.setRedirectPath);
    builder.addMatcher(authApi.endpoints.login.matchFulfilled, reducers.login);
    builder.addMatcher(
      isAnyOf(authApi.endpoints.logout.matchFulfilled, logoutAction),
      reducers.logout,
    );
    builder.addMatcher(
      customerApi.endpoints.userDetails.matchFulfilled,
      reducers.setUser,
    );
  },
});

export const useAuthState = () => useAppSelector(({ auth }) => auth);
