import { UserRole } from '../dicts';

export enum PartyType {
  LegalEntity = 'LEGAL_ENTITY',
  Person = 'PERSON',
}

export enum AccountPurposeType {
  CustomerCurrent = 'CUSTOMER_CURRENT',
  CustomerLoanInterest = 'CUSTOMER_LOAN_INTEREST',
  CustomerLoanPrincipal = 'CUSTOMER_LOAN_PRINCIPAL',
  CustomerLoanCollateral = 'CUSTOMER_LOAN_COLLATERAL',
  CustomerLoanPenalties = 'CUSTOMER_LOAN_PENALTIES',
  MonivoltOwn = 'MONIVOLT_OWN',
  MonivoltCustomer = 'MONIVOLT_CUSTOMER',
  MonivoltInterestIncome = 'MONIVOLT_INTEREST_INCOME',
  MonivoltInterestExpense = 'MONIVOLT_INTEREST_EXPENSE',
  MonivoltOriginationFee = 'MONIVOLT_ORIGINATION_FEE',
  MonivoltTransactionFee = 'MONIVOLT_TRANSACTION_EXPENSE',
  CreditorLoanInterest = 'CREDITOR_LOAN_INTEREST',
  CreditorLoanPrincipal = 'CREDITOR_LOAN_PRINCIPAL',
}

export type AccountInfo = {
  id: number;
  label: string;
  partyType: PartyType;
};

export type AccountsData = {
  list: Record<number, string>;
  options: AccountInfo[];
};

export type UserInfo = {
  id: number;
  email: string;
  firstName: string;
  lastName: string;
  userLogin: string;
  roles: UserRole[];
};
