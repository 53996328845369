import * as Yup from 'yup';
import { AuthFormField } from 'src/entities/auth';

export const changePassValidation = {
  [AuthFormField.OldPassword]: Yup.string().required('Please enter a password'),
  [AuthFormField.NewPassword]: Yup.string()
    .required('Password enter a password')
    .min(8, 'Password should be minimum 8 characters')
    .matches(
      /(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*\W)/,
      'Password should contain at least one number, lowercase, uppercase and special characters',
    ),
  [AuthFormField.ConfirmPassword]: Yup.string()
    .required('Password confirm password')
    .oneOf([Yup.ref(AuthFormField.NewPassword)], 'Passwords do not match'),
};
