import React from 'react';
import Button, { ButtonProps } from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';

type Props = ButtonProps & {
  loading?: boolean;
  loaderSize?: string;
};

function LoadingButton({
  loaderSize = '2.3rem',
  loading,
  disabled,
  startIcon,
  ...props
}: Props) {
  return (
    <Button
      data-testid="loading-btn"
      startIcon={loading ? <CircularProgress size={loaderSize} /> : startIcon}
      disabled={loading || disabled}
      {...props}
    />
  );
}

export default LoadingButton;
