import { alpha } from '@mui/material/styles';
import baseTheme from '../baseTheme';

const { palette, spacing } = baseTheme;

export default {
  MuiButton: {
    defaultProps: {
      disableElevation: true,
      color: 'primary',
      variant: 'contained',
    },
    styleOverrides: {
      root: {
        textTransform: 'none',
        borderRadius: spacing(0.8),
        gap: spacing(1),
      },
      containedPrimary: {
        '&.Mui-disabled': {
          color: palette.primary.dark,
          backgroundColor: alpha(palette.primary.main, 0.12),
        },
      },
      containedError: {
        '&.Mui-disabled': {
          color: palette.error.dark,
          backgroundColor: alpha(palette.error.main, 0.12),
        },
      },
      containedWarning: {
        color: palette.common.white,
        '&.Mui-disabled': {
          color: palette.warning.dark,
          backgroundColor: alpha(palette.warning.main, 0.12),
        },
      },
      sizeSmall: {
        minHeight: spacing(3.2),
        minWidth: spacing(3.2),
        paddingInline: spacing(2),
      },
      sizeMedium: {
        minHeight: spacing(4),
        minWidth: spacing(4),
      },
      endIcon: {
        marginInline: spacing(-0.4),
        '>*:nth-of-type(1)': {
          fontSize: spacing(1.3),
        },
      },
      startIcon: {
        marginInline: spacing(-0.4),
        '>*:nth-of-type(1)': {
          fontSize: spacing(1.3),
        },
      },
    },
  },
  MuiFab: {
    defaultProps: {
      size: 'small',
    },
    styleOverrides: {
      root: {
        boxShadow: 'none',
      },
      default: {
        backgroundColor: palette.background.paper,
      },
    },
  },
  MuiToggleButton: {
    styleOverrides: {
      root: {
        borderColor: alpha(palette.common.black, 0.23),
        borderRadius: spacing(0.8),
        textTransform: 'none',
        '&.Mui-selected': {
          borderColor: 'currentColor',
        },
      },
      sizeMedium: {
        padding: spacing(0.9),
      },
    },
  },
  MuiToggleButtonGroup: {
    defaultProps: {
      color: 'primary',
      fullWidth: true,
      exclusive: true,
      size: 'medium',
    },
  },
};
