import { EventHandler, SyntheticEvent } from 'react';

export const stopPropagation =
  <T extends SyntheticEvent>(callback?: EventHandler<T>) =>
  (event: T) => {
    event.stopPropagation();
    if (callback) {
      callback(event);
    }
  };
